import React, { useState, useContext, useEffect } from "react";
import RadioButton from "../../CommonComponent/RadioButton";
import { DeveloperDataContext } from "../../../../common/utils/appContext";
import { getDateFilterTypeList } from "../../../constants/api";
import useWindowResize from "../../../../hooks/useWindowResize";

const DateTypeFilter = ({
  label,
  labelKey,
  options,
  setOptions,
  dateTypeFor,
  globalFilterFor,
}) => {
  const Context = useContext(DeveloperDataContext);
  const [windowWidth] = useWindowResize();
  useEffect(() => {
    let active = true;
    const callMasterApi = async callingApi => {
      if (dateTypeFor?.id != undefined) {
        const response = await callingApi(dateTypeFor?.id);
        if (active && response) {
          let tempArr1 = [];
          response?.map((el, i) => {
            tempArr1.push({
              id:
                el["userId"] !== null && el["userId"] !== undefined
                  ? el["userId"]
                  : el["id"] !== null && el["id"] !== undefined
                  ? el["id"]
                  : el[labelKey],
            });
            tempArr1[i][labelKey] = el[labelKey];
          });
          setOptions(tempArr1);
        }
      }
    };
    if (Array.isArray(options) && options?.length === 0) {
      callMasterApi(getDateFilterTypeList);
    }
    return () => {
      active = false;
    };
  }, []);

  const handleBlur = () => {};
  const handleChange = each => {
    Context?.setAppData({
      ...Context.appData,
      [`appliedGlobalFilter${globalFilterFor}`]: {
        ...Context.appData[`appliedGlobalFilter${globalFilterFor}`],
        ...{ DateFilterType: each.name },
      },
    });
  };

  const isChecked = name => {
    if (
      Context?.appData?.[`appliedGlobalFilter${globalFilterFor}`] &&
      Context?.appData?.[
        `appliedGlobalFilter${globalFilterFor}`
      ].hasOwnProperty("DateFilterType")
    ) {
      return (
        Context?.appData?.[`appliedGlobalFilter${globalFilterFor}`][
          "DateFilterType"
        ] === name
      );
    }
    return false;
  };

  return (
    <div className="w-full">
      <p
        className="font-sans font-normal text-sm text-black-400"
        style={{
          position: "absolute",
          left: windowWidth > 767 ? "24px" : "8px",
          top: windowWidth > 767 ? "24px" : "8px",
        }}
      >
        {label}
      </p>
      <div className="w-full flex flex-col md:flex-row md:flex-wrap">
        {options.map((each, i) => (
          <div
            className="w-full md:w-1/2 mt-3 flex justify-start items-center"
            key={i}
            onClick={() => handleChange(each)}
          >
            <RadioButton
              name="dateFilterType"
              value={isChecked(each.name)}
              checked={isChecked(each.name)}
              onChange={() => handleChange(each)}
              onBlur={handleBlur}
              checkColor={true}
            />
            <label
              className={`ml-2 font-sans text-sm text-black-400 cursor-pointer break-all ${
                isChecked(each.name) ? "font-bold" : "font-normal"
              }`}
            >
              {each.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
export default DateTypeFilter;
