import React from "react";
import Toast from "../../common/components/Toast/Toast";
import {
  webSocketUrl,
  socketTopic,
  notificationWhatsAppTopic,
  notificationCount,
  notificationLead,
} from "gatsby-env-variables";
import notification from "../../Audio/notification/notification_call.mp3";
import { isAliveWebSocket } from "./WebSocket";
import DescriptionTwoToneIcon from "@material-ui/icons/DescriptionTwoTone";
import { setSocketMessage } from "../../pages/app";
import { navigate } from "gatsby";
const redirectCustomer = accountId => {
  navigate(`/app/accounts/profile/${accountId}`);
};
const redirectOpp = oppId => {
  navigate(`/app/opportunity/details/${oppId}`);
};
export const actionOnMessage = data => {
  let message = null;

  try {
    message = JSON.parse(data?.body || {});
  } catch {
    message = "";
  }
  if (!message) return;

  if (message?.messageType !== "unread_notification_count") {
    const audio = new Audio(notification);
    audio.play();
  }

  if (typeof setSocketMessage == "function") {
    setSocketMessage(message);
  }
  if (message.messageType === "applicant_whatsapp_notification") {
    Toast(
      "info",
      <div className="block px-2 py-1 ">
        <div className="">
          <p className="pb-1  font-extrabold text-lg ">
            {message?.message || "You have a new notification!"}
          </p>
          {`${message.whatsappMessage || ""}`}

          <div className="w-full">
            {message.isDocumentSent && (
              <div className="w-full">
                <p className="pb-1  font-normal text-basic ">
                  You recieved a new document!
                </p>
                <DescriptionTwoToneIcon className="h-32 w-full" />
              </div>
            )}
          </div>
        </div>
      </div>,
      20000
    );
    return;
  } else if (message.messageType == "applicant_calling_notification") {
    return Toast(
      "info",
      <div
        className="block px-2 py-1 cursor-pointer"
        onClick={() => redirectCustomer(message?.accountId)}
      >
        <p className="pb-1  font-extrabold text-lg ">
          {message?.message || "You have a new notification!"}
        </p>
        <p className="pb-2 text-sm capitalize font-semibold">
          {message?.messageType
            ? "Client Calling Notification"
            : "it seems something wrong from our end."}
        </p>
      </div>,
      20000
    );
  } else if (message?.messageType == "opp_created") {
    return Toast(
      "info",
      <div
        className="block px-2 py-1 cursor-pointer"
        onClick={() => redirectOpp(message?.opportunityId)}
      >
        <p className="pb-1  font-extrabold text-lg ">
          {message?.message || "You have a new notification!"}
        </p>
        <p className="pb-2 text-sm capitalize font-semibold">
          {message?.messageType
            ? "New Opportunity Notification"
            : "it seems something wrong from our end."}
        </p>
      </div>,
      20000
    );
  }
};

export const socketData = ({
  token,
  socketInstance,
  onConnect,
  onDisconnect,
  onError,
}) => {
  return {
    url: webSocketUrl,
    topic: socketTopic,
    notificationWhatsAppTopic: notificationWhatsAppTopic,
    notificationCount: notificationCount,
    notificationLead: notificationLead,
    token: token,
    onConnect: data => {},
    onDisconnect: (data, isDisconnected) => {
      try {
        if (socketInstance.reconection <= 2 && !isAliveWebSocket()) {
          socketInstance.reConnect();
        }
      } catch {}

      typeof onDisconnect == "function" && onDisconnect(data, isDisconnected);
    },
    onError: data => {
      typeof onError == "function" && onError(data);
    },
    onMessage: actionOnMessage,
  };
};
