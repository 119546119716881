import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  getIsApproved,
  getUserType,
  isLoggedIn,
} from "../../utils/cookie-utils";
import {
  getNotificationCount,
  getConsumerProfileDetails,
} from "../../constants/api";
import {
  getUserDetailsInfo,
  setUserDetailsInfo,
} from "../../utils/userDetailsInfo";
import Badge from "@material-ui/core/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-light-svg-icons";
import WhatsAppNotification from "../WhatsAppNotification/WhatsAppNotification";
import NotificationDataContext from "../../../common/Context/NotificationDataContext";
import ArrowLeftIcon from "@material-ui/icons/ArrowBack";
import { getCurrentUserDetails } from "../../constants/api";
import { DeveloperDataContext } from "../../../common/utils/appContext";
import profileAv from "../../../images/icons/profileAv2x.png";
import "./Header.css";
import NewSpinner from "../NewSpinner/NewSpinner";

export default function MobileHeader({
  noAccountPls,
  showOtherUtilies,
  mobileHeading,
}) {
  const isLogged = isLoggedIn();
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetailsInfo")) || ""
  );
  const [notification, setNotification] = useState(false);
  const [countData, setCountData] = useState(0);
  const messages = useContext(NotificationDataContext);
  const [profileData, setProfileData] = useState(null);
  const [imgLoader, setImgLoader] = useState(true);
  const context = useContext(DeveloperDataContext);
  const userType = getUserType();
  const [isApproved, setIsApproved] = useState(getIsApproved());

  useEffect(() => {
    async function call() {
      if (!noAccountPls && isApproved) {
        setUserDetails(await getUserDetailsInfo());
      }
    }
    call();
  }, []);

  useEffect(() => {
    if (!noAccountPls) {
      isApproved &&
        getNotificationCount().then(res => {
          if (res == 0) return;
          setCountData(`${res}`);
        });
    }
  }, []);

  useEffect(() => {
    setCountData(messages?.count_notification?.count || 0);
  }, [messages?.count_notification?.count]);

  useEffect(() => {
    if (userType == 0) {
      setImgLoader(true);
      isApproved &&
        getCurrentUserDetails().then(res => {
          setProfileData(res);
          setImgLoader(false);
        });
    }
    if (userType == 3) {
      setImgLoader(true);
      getConsumerProfileDetails().then(res => {
        setProfileData(res);
        setImgLoader(false);
      });
    }
    setImgLoader(false);
  }, [context?.appData?.updateUserProfile]);

  return (
    <>
      <div className={"bg-transparent flex flex-row justify-between w-full"}>
        <div
          className="flex flex-row items-center space-x-3 "
          style={{ width: "max-content" }}
        >
          {mobileHeading === "Dashboard" &&
          (userType == 0 || userType == 1 || userType == 3) ? (
            <Fragment>
              <div className="flex justify-center items-center pl-4">
                {!imgLoader ? (
                  <img
                    className="w-10 h-10 rounded-full"
                    src={
                      profileData?.croppedProfilePictureUrl
                        ? profileData?.croppedProfilePictureUrl
                        : profileAv
                    }
                    alt="logo"
                  />
                ) : (
                  <div>
                    <NewSpinner />
                  </div>
                )}
              </div>
              <div className="">
                <p className="text-lg ml-2">
                  Hello,
                  {userDetails
                    ? ` ${userDetails?.name?.split(" ")[0] || "NA"}`
                    : "NA"}
                </p>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div
                onClick={() => {
                  typeof history !== "undefined" && history.go(-1);
                  context.setAppData({...context?.appData, headerTitle: null})
                }}
                className="cursor-pointer"
              >
                <ArrowLeftIcon
                  style={{
                    color: "#545454",
                    paddingLeft: "5px",
                    width: "40px",
                  }}
                />
              </div>
              <p className="text-black-700 text-2xl whitespace-normal">{context?.appData?.headerTitle ?? mobileHeading}</p>
            </Fragment>
          )}
        </div>

        <WhatsAppNotification
          notification={notification}
          setNotification={setNotification}
        />
        {/* mobile icons */}
        {showOtherUtilies && (
          <React.Fragment>
            <div className="inline-flex items-center justify-center">
              <div className="block h-12 w-12 ml-3 mt-4">
                <img
                  className="block object-cover"
                  src={notification}
                  alt="menu"
                />
              </div>
            </div>
          </React.Fragment>
        )}

        {isLogged && !noAccountPls ? (
          <React.Fragment>
            <div>
              <Badge
                badgeContent={countData}
                color="error"
                style={{ marginRight: "28px", marginTop: "10px" }}
                max={999}
              >
                <FontAwesomeIcon
                  className={countData > 0 ? "bellIcon" : ""}
                  icon={faBell}
                  size="2x"
                  style={{
                    color: "gray",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setNotification(!notification);
                  }}
                />
              </Badge>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </>
  );
}
