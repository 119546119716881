import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  START_DATE,
  END_DATE,
  ICON_AFTER_POSITION,
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION,
} from "react-dates/constants";
import { DateRangePicker, DayPickerRangeController } from "react-dates";
import "./DashboardDateFilter.css";

var stringToHTML = function (str) {
  appendElement.current.innerHTML = str;
};

const DashboardDateFilter = ({
  keepOpenOnDateSelect,
  setFlexTimeLine,
  setToggleDateOptions,
  vertical,
  focusedInputProp,
  withPortal,
  setSelectedSubOption,
  fromDate = moment().startOf("month").format("YYYY-MM-DD"),
  toDate = moment().format("YYYY-MM-DD"),
  setFromDate,
  setToDate,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [focusedInput, setFocusedInput] = useState(null);

  const handleWindowResize = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      <DateRangePicker
        withPortal={withPortal}
        startDate={moment(fromDate)} // momentPropTypes.momentObj or null,moment(startDate).format('YYYY-MM-DD')
        startDateId={START_DATE} // PropTypes.string.isRequired,
        endDate={moment(toDate)} // momentPropTypes.momentObj or null,
        endDateId={END_DATE} // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) => {
          startDate
            ? setFromDate(startDate.format("YYYY-MM-DD"))
            : setFromDate(moment().startOf("month").format("YYYY-MM-DD"));
          if (endDate) {
            setToDate(endDate.format("YYYY-MM-DD"));
          } else {
            setToDate(moment().format("YYYY-MM-DD"));
            setSelectedSubOption && setSelectedSubOption("");
          }
          if ((startDate || endDate) && setSelectedSubOption)
            setSelectedSubOption("Custom");
        }} // PropTypes.func.isRequired,
        focused={false}
        minimumNights={0}
        displayFormat="DD-MM-YYYY"
        orientation={
          vertical
            ? VERTICAL_ORIENTATION
            : width < 768
            ? VERTICAL_ORIENTATION
            : HORIZONTAL_ORIENTATION
        }
        showDefaultInputIcon={true}
        showClearDates={true}
        inputIconPosition={ICON_AFTER_POSITION}
        isOutsideRange={() => false}
        hideKeyboardShortcutsPanel={true}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        startDatePlaceholderText={fromDate ? fromDate : "Start date"}
        endDatePlaceholderText={toDate ? toDate : "End date"}
      />
    </>
  );
};
export default DashboardDateFilter;
