import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation } from "@reach/router";
import { Link, navigate } from "gatsby";
import { enableSettings } from "gatsby-env-variables";
import {
  getIsApproved,
  getUserNumber,
  getUserType,
} from "../../utils/cookie-utils";
import { getUserDetailsInfo } from "../../utils/userDetailsInfo";
import { getAcl } from "../../../common/utils/acl-utils";
import menu from "../../../images/Settings/menu.svg";
import Logout from "../Logout/Logout";
import DropLogo from "../../../images/icons/arrow.svg";
import close from "../../../images/icons/close.svg";
import logOut from "../../../images/icons/logOut.svg";
import lead_icon from "../../../images/icons/addLead.svg";
import finLogo from "../../../images/Settings/Fincity_Logo_1.svg";
import profileAv from "../../../images/icons/profileAv2x.png";
import oppr from "../../../images/Settings/icon10.svg";
import opprBlack from "../../../images/Settings/icon10_black.svg";
import iconWhiteDash from "../../../images/Settings/icon6.svg";
import iconBlackDash from "../../../images/Settings/icon6_black.svg";
import account from "../../../images/Settings/icon1.svg";
import accountBlack from "../../../images/Settings/icon1_black.svg";
import partnerSide from "../../../images/Settings/icon9.svg";
import partnerSideBlack from "../../../images/Settings/icon9_black.svg";
import iconPerformance from "../../../images/Settings/icon7.svg";
import iconPerformanceBlack from "../../../images/Settings/icon7_black.svg";
import iconWalkIn from "../../../images/Settings/icon2.svg";
import iconWalkInBlack from "../../../images/Settings/icon2_black.svg";
import settingIcon from "../../../images/Settings/icon5.svg";
import settingIconBlack from "../../../images/Settings/icon5_black.svg";
import bucketIcon from "../../../images/Settings/icon3.svg";
import bucketIconBlack from "../../../images/Settings/icon3_black.svg";
import contentIcon from "../../../images/Settings/icon8.svg";
import contentIconBlack from "../../../images/Settings/icon8_black.svg";
import "./Sidebar.css";
import Badge from "@material-ui/core/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-solid-svg-icons";
import { getNotificationCount } from "../../constants/api";
import WhatsAppNotification from "../WhatsAppNotification/WhatsAppNotification";
import NotificationDataContext from "../../../common/Context/NotificationDataContext";
import marketing from "../../../images/Settings/icon4.svg";
import marketingBlack from "../../../images/Settings/icon4_black.svg";
import whatsAppIcon from "../../../images/whatsapp_logo.svg";
import AccountCircleIcon from "../../../images/consumer/account_circle_sidebar.svg";
import AccountCircleIconBlack from "../../../images/consumer/account_circle_sidebarBlack.svg";
import ApartmentIcon from "../../../images/consumer/apartment_white_sidebar.svg";
import ApartmentIconBlack from "../../../images/consumer/apartment_white_sidebarBlack.svg";
import MyPropertyIcon from "../../../images/consumer/MyPropertySidebar.svg";
import MyPropertyIconBlack from "../../../images/consumer/MyPropertySidebarBlack.svg";
import MoreIcon from "../../../images/consumer/moreIcon.svg";
import AnnouncementIcon from "../../../images/consumer/announcement-sidebar.svg";
import AnnouncementIconBlack from "../../../images/consumer/announcement-sidebarBlack.svg";

const Sidebar = () => {
  const [showDropdown, setShowDropDown] = useState(false);
  const [showLabels, setShowLabels] = useState(true);
  const [rendered, setRendered] = useState(false);
  const [topElement, setTopElement] = useState(null);
  const [showMobileDropDown, setShowMobileDropDown] = useState(false);
  const [closeOptimiser, setCloseOptimiser] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768);
  const [activeDropDownOption, setActiveDropDownOptions] = useState(null);
  const [notification, setNotification] = useState(false);
  const [countData, setCountData] = useState(0);
  const messages = useContext(NotificationDataContext);
  const [isApproved, setIsApproved] = useState(getIsApproved());
  const myRefs = useRef([]);
  const dropLinkRefs = useRef([]);
  const menuRef = useRef(null);
  const linkRef = useRef([]);
  const appendElement = useRef(null);
  const navRef = useRef(null);
  const arrowRefs = useRef([]);
  const location = useLocation();

  useEffect(() => {
    isApproved &&
      getNotificationCount().then(res => {
        if (res == 0) return;
        setCountData(`${res}`);
      });
  }, []);

  useEffect(() => {
    setCountData(messages?.count_notification?.count || 0);
  }, [messages?.count_notification?.count]);

  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetailsInfo")) || ""
  );
  /**
   * Convert a template string into HTML DOM nodes
   * @param  {String} str The template string
   * @return {Node}       The template HTML
   */
  var stringToHTML = function (str) {
    if (!showMobileDropDown) appendElement.current.innerHTML = str;
    else appendElement.current.innerHTML = "";
  };

  useEffect(() => {
    showActiveLinkForMobile();
    if (isSmallScreen() && appendElement.current) {
      stringToHTML(topElement);
    }
    dropLinkRefs.current.map((el, i) => {
      if (el?.classList?.contains("dropActiveBar")) {
        setActiveDropDownOptions(Math.floor(i / 10 - 1));
        setRendered(true);
        setShowDropDown(true);
        el.parentElement.parentElement.parentElement.style.display = "flex";
      }
    });
    // re-render on resize to small screen
    window.addEventListener("resize", () => {
      window.innerWidth < 768
        ? setIsMobileScreen(true)
        : setIsMobileScreen(false);
    });

    //It is important to remove EventListener attached on window.
    window.removeEventListener("resize", () => {
      window.innerWidth < 768
        ? setIsMobileScreen(true)
        : setIsMobileScreen(false);
    });
  }, [showMobileDropDown, topElement, isMobileScreen]);
  const aclInfo = getAcl();

  const navItemsForkyc = () => {
    const userType = getUserType();
    let menuArr = [];
    userType == 0
      ? (menuArr = [
          {
            icons: iconWhiteDash,
            link: "/app/dashboard",
            label: "Dashboard",
            blackIcon: iconBlackDash,
          },
          {
            icons: oppr,
            link: "/app/opportunity",
            label: "Opportunities",
            blackIcon: opprBlack,
          },
          {
            icons: account,
            link: "/app/accounts",
            label: "Accounts",
            blackIcon: accountBlack,
          },
          ...(aclInfo?.can_view_business_partner
            ? [
                {
                  icons: partnerSide,
                  link: "/app/bp",
                  label: "Business Partners",
                  blackIcon: partnerSideBlack,
                },
              ]
            : []),
          ...(aclInfo?.can_view_ifa
            ? [
                {
                  icons: partnerSide,
                  link: "/app/ifa",
                  label: "IFAs",
                  blackIcon: partnerSideBlack,
                },
              ]
            : []),
          {
            icons: iconPerformance,
            link: "/app/reports",
            label: "Reports",
            blackIcon: iconPerformanceBlack,
          },
          {
            icons: iconWalkIn,
            link: "/app/walkin",
            label: "Walk-In",
            blackIcon: iconWalkInBlack,
          },
          {
            icons: contentIcon,
            link: "/app/inventory-module",
            label: "Inventory",
            blackIcon: contentIconBlack,
          },
          ...(aclInfo?.can_add_lead_bucket
            ? [
                {
                  icons: bucketIcon,
                  link: "/app/bucket",
                  label: "Buckets",
                  blackIcon: bucketIconBlack,
                },
              ]
            : []),
          ...(aclInfo?.can_access_marketing_setting
            ? [
                {
                  icons: marketing,
                  link: "/",
                  label: "Marketing",
                  blackIcon: marketingBlack,
                  dropdown: [
                    {
                      icons: whatsAppIcon,
                      link: "/app/marketing-template",
                      label: "WhatsApp",
                    },
                    {
                      icons: whatsAppIcon,
                      link: "/app/history",
                      label: "History",
                    },
                    {
                      icons: whatsAppIcon,
                      link: "/app/campaign/insights",
                      label: "Campaign Insights",
                    },
                  ],
                },
              ]
            : []),
          ...(aclInfo?.can_access_lead_flow_config && enableSettings
            ? [
                {
                  icons: settingIcon,
                  link: "/app/settings",
                  label: "Settings",
                  blackIcon: settingIconBlack,
                },
              ]
            : []),
        ])
      : userType == 1
      ? (menuArr = [
          {
            icons: iconWhiteDash,
            link: "/app/dashboard/bp",
            label: "Dashboard",
            blackIcon: iconBlackDash,
          },
          {
            icons: oppr,
            link: "/app/opportunity",
            label: "Opportunities",
            blackIcon: opprBlack,
          },
          {
            icons: account,
            link: "/app/content-share",
            label: "Share Content",
            blackIcon: accountBlack,
          },
          {
            icons: iconPerformance,
            link: "/app/reports",
            label: "Reports",
            blackIcon: iconPerformanceBlack,
          },
        ])
      : userType == 2
      ? (menuArr = [
          {
            icons: iconWhiteDash,
            link: "/app/dashboard",
            label: "Dashboard",
            blackIcon: iconBlackDash,
          },
          {
            icons: oppr,
            link: "/app/opportunity",
            label: "Opportunities",
            blackIcon: opprBlack,
          },
          {
            icons: iconPerformance,
            link: "/app/reports",
            label: "Reports",
            blackIcon: iconPerformanceBlack,
          },
        ])
      : (menuArr = [
          {
            icons: AccountCircleIcon,
            link: "/app/Consumer/Profile",
            label: "Profile",
            blackIcon: AccountCircleIconBlack,
          },
          {
            icons: ApartmentIcon,
            link: "/app/Consumer/Properties",
            label: "Properties",
            blackIcon: ApartmentIconBlack,
          },
          {
            icons: MyPropertyIcon,
            link: "/app/Consumer/MyProperties",
            label: "My Properties",
            blackIcon: MyPropertyIconBlack,
          },
          {
            icons: MoreIcon,
            link: "/app/Consumer/More",
            label: "More",
            blackIcon: MoreIcon,
            dropdown: [
              {
                icons: AnnouncementIcon,
                link: "/app/dashboard/announcements",
                label: "Announcements",
                blackIcon: AnnouncementIconBlack,
              },
              {
                icons: settingIcon,
                link: "/app/changepassword",
                label: "Settings",
                blackIcon: settingIconBlack,
              },
              {
                icons: settingIcon,
                link: "/app/Consumer/AboutUs",
                label: "About Us",
                blackIcon: settingIconBlack,
              },
              {
                icons: settingIcon,
                link: "/app/Consumer/ContactUs",
                label: "Contact Us",
                bucketIcon: settingIconBlack,
              },
            ],
          },
        ]);
    return menuArr;
  };

  const DropIcon = (i, index) => {
    return (
      <div
        key={`${i + 1}${index}`}
        className={"barActive w-3 h-3 rounded-full "}
      ></div>
    );
  };
  useEffect(() => {
    let res = getUserDetailsInfo();
    setUserDetails(res);
  }, []);
  const NavItems = navItemsForkyc();

  const topElementForAddLead = () =>
    `<div class="flex flex-row justify-between w-full  ">
        <div
          class= 'w-1/5 ml-8 mt-1 '}
        >
          <img class="mr-0 block w-10 h-7" src=${lead_icon} alt="*" />
        </div>
        <div class='w-3/4 text-left mt-1 md:mt-0'>
          <span class=" text-xl  font-roboto font-light text-left">
            Add Lead
          </span>
        </div>
      </div>
    `;

  const isSmallScreen = () => {
    if (window.innerWidth < 768) return true;
    else return false;
  };

  const menuOnclickHandler = () => {
    setTimeout(() => {
      setCloseOptimiser(true);
    }, 300);
    myRefs.current.forEach(element => {
      element.classList.add("hidden");
      element.style.display = "none";
    });
    setShowDropDown(false);
    setShowLabels(false);
    menuRef.current.classList.remove("md:w-64");
    menuRef.current.classList.add("md:w-20");
  };

  const onCloseClickHandler = () => {
    setCloseOptimiser(false);
    setTimeout(() => {
      setShowLabels(true);
    }, 100);
    menuRef.current.classList.remove("md:w-20");
    menuRef.current.classList.add("md:w-64");
    // }
  };

  const onMobileCloseClickHandler = () => {
    menuRef.current.classList.remove("h-full");
    menuRef.current.classList.add("h-17");
    setTimeout(() => {
      setShowMobileDropDown(false);
    }, 350);
  };

  const mobileMenuOnclickHandler = () => {
    setShowMobileDropDown(true);
    menuRef.current.classList.remove("h-17");
    menuRef.current.classList.remove("fixed");
    menuRef.current.classList.add("h-full");
    menuRef.current.classList.add("absolute");
  };

  const toggleDropDown = id => {
    setRendered(true);
    setActiveDropDownOptions(id);

    // rotating the DropDown Arrow
    if (arrowRefs.current)
      arrowRefs.current[id].classList.toggle(["rotate-180"]);

    //flexing the dropdown div
    myRefs.current.map((el, i) => {
      if (i === id) {
        if (myRefs.current[i].style.display === "flex")
          myRefs.current[i].style.display = "none";
        else {
          if (showLabels) myRefs.current[i].style.display = "flex";
        }
      } else {
        myRefs.current[i].style.display = "none";
      }
    });
  };

  const showActiveLinkForMobile = () => {
    linkRef?.current?.forEach(element => {
      if (element?.parentElement?.classList?.contains("activeBar"))
        setTopElement(element?.innerHTML);
    });
    dropLinkRefs?.current?.forEach(element => {
      if (element?.classList?.contains("dropActiveBar")) {
        setTopElement(
          '<div class="ml-12 inline-flex w-full"><div class="w-1/5 "><div class="bg-tealCol-100 w-3 h-3 mt-1 rounded-full"></div></div><div class="w-4/5"><div class=" mx-auto text-tealCol-100 "></div>' +
            element?.firstChild?.lastChild?.innerHTML +
            "</div></div>"
        );
      }
    });
    if (window.location.pathname === "/app/addlead") {
      setTopElement(topElementForAddLead);
    }
  };

  return (
    <React.Fragment>
      <WhatsAppNotification
        notification={notification}
        setNotification={setNotification}
      />
      <div
        id="sideBar"
        ref={menuRef}
        className={`${
          showMobileDropDown
            ? " overflow-y-auto h-full absolute "
            : " overflow-hidden h-17 fixed "
        } sideBar md:h-auto md:min-h-screen  inset-x-0 top-0   md:relative block md:flex-shrink-0  md:overflow-auto w-full  md:w-64 md:pb-0 md:pt-2 pr-0 md:overflow-y-auto ${
          !isApproved ? ` pointer-events-none ` : ``
        }`}
      >
        <div className=" flex flex-row justify-between md:hidden outline-none">
          <div
            className={
              (showMobileDropDown ? " hidden " : " flex flex-1 ") +
              " md:hidden  cursor-pointer h-19 pb-3 text-white w-4/5  md:w-full   justify-between items-center"
            }
            ref={appendElement}
          ></div>

          <div
            className={
              showMobileDropDown
                ? "flex flex-row justify-start mt-4 md:hidden ml-8 w-4/5 outline-none truncate "
                : "hidden"
            }
          >
            <div className="w-10 h-10 ">
              <img src={profileAv} alt="img" />
            </div>
            <div>
              <p className="font-roboto font-normal text-xl  ml-4 mt-2 text-white  truncate">
                {window.innerWidth > 330
                  ? userDetails
                    ? `${userDetails?.name?.substring(0, 20)}${
                        userDetails?.name?.length > 20 ? "..." : ""
                      }`
                    : `${getUserNumber()?.substring(0, 20)}${
                        getUserNumber()?.length > 20 ? "..." : ""
                      }`
                  : userDetails
                  ? `${userDetails?.name?.substring(0, 10)}${
                      userDetails?.name?.length > 10 ? "..." : ""
                    }`
                  : `${getUserNumber()?.substring(0, 10)}${
                      getUserNumber()?.length > 10 ? "..." : ""
                    }`}
              </p>
            </div>
          </div>

          {!showMobileDropDown && (
            <div className="flex items-center mr-4">
              <Badge badgeContent={countData} color="error" max={999}>
                <FontAwesomeIcon
                  icon={faBell}
                  size="2x"
                  style={{
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setNotification(!notification);
                  }}
                />
              </Badge>
            </div>
          )}

          {!showMobileDropDown ? (
            <div
              className="w-1/5 mt-7 cursor-pointer outline-none bg-transparent appearance-none"
              onClick={mobileMenuOnclickHandler}
            >
              <img
                className="block outline-none bg-transparent"
                src={menu}
                alt="_-"
              />
            </div>
          ) : (
            <div
              className="w-1/5 mt-7 -ml-4 cursor-pointer outline-none bg-transparent appearance-none "
              onClick={onMobileCloseClickHandler}
            >
              <img
                className="outline-none bg-transparent "
                src={close}
                alt="close"
              />
            </div>
          )}
        </div>

        <hr className="md:hidden w-5/6 mx-auto mt-7 h-0.5 text-grey-600" />

        {/* md menuBar */}
        <div
          id="headerBar"
          className={
            "  inline-flex h-28 " +
            (showLabels
              ? "md:inline-flex w-full justify-around"
              : " md:invisible absolute  ")
          }
        >
          <div
            className="hidden md:block mt-5 text-white"
            onClick={() => navigate("/")}
          >
            <div
              style={{
                fontSize: "22px",
                lineHeight: "24px",
                fontWeight: "700",
              }}
            >
              Developers CRM
            </div>
            <div className="flex items-center gap-1">
              <p
                style={{
                  fontWeight: "600",
                }}
              >
                by
              </p>
              <img
                className=" h-4 w-14 relative"
                src={
                  userDetails?.tenant?.logoUrl
                    ? userDetails.tenant.logoUrl
                    : finLogo
                }
                alt="fincityLogo"
              />
            </div>
          </div>
          <div
            className="hidden md:block absolute right-0 -mr-3 md:mt-12 block cursor-pointer"
            onClick={menuOnclickHandler}
            id="side_bar_collapse"
          >
            <img src={menu} alt="_-" />
          </div>
        </div>

        {/* close icons */}
        <div
          id="side_bar_open"
          className={
            !showLabels && closeOptimiser
              ? "mt-12 cursor-pointer absolute right-0 -mr-3"
              : "hidden"
          }
          onClick={onCloseClickHandler}
        >
          <img
            style={{
              transform: "rotate(180deg)",
            }}
            src={menu}
            alt="close"
          />
        </div>

        {/* <nav className="flex-1">
      <ul className="w-full font-roboto text-base md:text-sm font-light md:font-regular list-reset flex flex-col text-center md:text-left"> */}
        <nav
          style={{
            overflow: "auto",
            height: `calc(100% - ${
              document.getElementById("headerBar")?.offsetHeight
            }px)`,
            top: `${document.getElementById("headerBar")?.offsetHeight}px`,
          }}
          ref={navRef}
          className={`  w-full left-0 md:absolute content-start md:text-left justify-between`}
        >
          <ul className="w-full font-roboto text-base  md:font-regular list-reset flex flex-col py-0 md:py-3  text-center align_left md:text-left">
            {NavItems.map(({ icons, link, label, dropdown, blackIcon }, i) => (
              <li
                key={link}
                className={
                  (activeDropDownOption === i && dropdown
                    ? "border-l-10 border-tealCol-100 bg-black-900 "
                    : "") + "  w-full my-px"
                }
              >
                {dropdown ? (
                  <button
                    type="button"
                    className="font-roboto h-16  font-normal block  cursor-pointer  text-center text-white  w-full  flex flex-1 justify-between items-center"
                    onClick={() => {
                      toggleDropDown(i);
                      setShowDropDown(!showDropdown);
                    }}
                  >
                    <div
                      ref={el => (linkRef.current[i] = el)}
                      className={
                        (activeDropDownOption === i && dropdown
                          ? "-ml-2.5"
                          : "") + " inline-flex w-full items-center "
                      }
                    >
                      <div
                        className={
                          (showLabels ? "w-1/5 ml-8" : "w-full ml-7") +
                          "  mt-2 "
                        }
                      >
                        <img className="mr-0 block" src={icons} alt="*" />
                      </div>
                      <div
                        className={
                          (activeDropDownOption === i && dropdown
                            ? "ml-0.5"
                            : "") +
                          (showLabels ? " w-3/4 text-left" : " hidden")
                        }
                      >
                        <span className="w-full block text-lg text-white font-roboto font-light text-left">
                          {label}
                        </span>
                      </div>
                    </div>
                    {dropdown && dropdown.length && showLabels ? (
                      <img
                        id="dropDown"
                        ref={el => (arrowRefs.current[i] = el)}
                        className={
                          " h-2 absolute block right-0 transform  text-white w-3 mr-10 md:mr-4 "
                        }
                        src={DropLogo}
                        alt="dropDown"
                      />
                    ) : null}
                  </button>
                ) : (
                  <Link
                    to={link}
                    activeClassName=" activeBar "
                    onClick={() => {
                      onMobileCloseClickHandler();
                    }}
                    getProps={({ isCurrent }) => {
                      if (location.pathname.includes(link)) {
                        return {
                          className:
                            " cursor-pointer h-16 md:py-3 text-white  w-full  flex flex-1 justify-between items-center activeBar",
                        };
                      }
                      return {
                        className:
                          " cursor-pointer h-16 md:py-3 text-white  w-full  flex flex-1 justify-between items-center",
                      };
                    }}
                  >
                    <div
                      className=" inline-flex w-full  "
                      ref={el => (linkRef.current[i] = el)}
                    >
                      <div
                        className={
                          (showLabels ? "w-1/5 ml-8" : "w-full ml-7 ") +
                          "  mt-0.5 "
                        }
                      >
                        <img
                          className="mr-0 block"
                          src={
                            location.pathname.includes(link) ? blackIcon : icons
                          }
                          alt="*"
                        />
                      </div>
                      <div
                        className={
                          showLabels ? "w-3/4 text-left  md:mt-0" : "hidden"
                        }
                      >
                        <span className=" text-lg  font-roboto text-left">
                          {label}
                        </span>
                      </div>
                    </div>
                  </Link>
                )}
                {dropdown && dropdown.length ? (
                  <ul
                    style={{ display: "none " }}
                    className={
                      showLabels
                        ? " flex flex-col justify-center md:w-full "
                        : " hidden "
                    }
                    ref={el => (myRefs.current[i] = el)}
                  >
                    {dropdown.map(({ link, label }, index) => (
                      <div key={index} className="inline-flex md:flex ">
                        <div className="w-1/5 block md:hidden"></div>
                        <li
                          key={link}
                          className="flex flex-col justify-center  md:ml-0 align_left md:text-left  my-px"
                        >
                          <Link
                            className="relative text-white align_left md:text-left flex items-center py-2"
                            to={link}
                            activeClassName=" dropActiveBar "
                            onClick={() => {
                              onMobileCloseClickHandler();
                            }}
                            ref={el =>
                              (dropLinkRefs.current[`${i + 1}${index}`] = el)
                            }
                            /* getProps={({ isCurrent }) => {
                          if (
                            location.search &&
                            location.search.split('?')[1] ===
                              link.split('?')[1]
                          ) {
                            return {
                              className:
                                'relative text-white align_left md:text-left dropActive',
                            };
                          } else if (isCurrent)
                            return {
                              className:
                                'relative text-white align_left md:text-left dropActive',
                            };
                        }}*/
                          >
                            <div className="inline-flex w-full ">
                              <div
                                className="w-7
                              ml-8 md:ml-12 mt-0.5 pt-1 "
                              >
                                {rendered ? DropIcon(i, index, link) : null}
                              </div>
                              <div className="">
                                <span className=" text-sm font-roboto font-light text-left ">
                                  {label}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                      </div>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
          {showLabels && false ? (
            <div className="hidden  w-full md:block p-2 mx-auto relative">
              <div className="separator mb-4 text-xs">Powered by</div>
              <img
                className="block h-6 w-28 relative m-auto"
                src={finLogo}
                alt="fincityLogo"
              />
            </div>
          ) : null}
        </nav>
        <div className=" mx-auto w-40 md:hidden border-2 border-grey-600 h-9 mt-20 mb-6 rounded-lg">
          <div
            className="flex flex-row md:hidden justify-center items-center mx-auto text-center cursor-pointer"
            onClick={() =>
              getUserType() == 1
                ? Logout({ hardReload: false, routeTo: "/" })
                : Logout({ hardReload: false })
            }
          >
            <div>
              <img src={logOut} alt="profile" />
            </div>
            <div>
              <p className="text-grey-998 ml-3 font-roboto font-normal text-base mt-1 mb-0.5">
                Logout
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Sidebar;
