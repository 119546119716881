/* eslint-disable no-use-before-define */
import React from "react";
import * as _ from "lodash";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import "./DateFilter.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckboxesTags = ({
  options,
  value,
  filterType,
  selectedSubOption,
  setSelectedSubOption,
  endDateClone,
  setStartDateClone,
  setEndDateClone,
  startDateClone,
}) => {
  const onDateChange = value => {
    setSelectedSubOption(() => value);
    if (value === null) return null;
    const dateLiteral = {
      Today: () => {
        setStartDateClone(() => moment().format("YYYY-MM-DD"));
        setEndDateClone(() => moment().format("YYYY-MM-DD"));
      },
      Yesterday: () => {
        setStartDateClone(() => moment().subtract(1, "d").format("YYYY-MM-DD"));
        setEndDateClone(() => moment().subtract(1, "d").format("YYYY-MM-DD"));
      },
      Tommorow: () => {
        setStartDateClone(() => moment().add(1, "d").format("YYYY-MM-DD"));
        setEndDateClone(() => moment().add(1, "d").format("YYYY-MM-DD"));
      },
      "This Week": () => {
        setStartDateClone(() =>
          moment().startOf("week").add(1, "d").format("YYYY-MM-DD")
        );
        setEndDateClone(() =>
          moment().endOf("week").add(1, "d").format("YYYY-MM-DD")
        );
      },
      "This month": () => {
        setStartDateClone(() => moment().startOf("month").format("YYYY-MM-DD"));
        setEndDateClone(() => moment().format("YYYY-MM-DD"));
      },
      "This Weekend": () => {
        setStartDateClone(() => moment().day(6).format("YYYY-MM-DD"));
        setEndDateClone(() => moment().day(7).format("YYYY-MM-DD"));
      },
      "Last Weekend": () => {
        setStartDateClone(() => moment().day(-1).format("YYYY-MM-DD"));
        setEndDateClone(() => moment().day(0).format("YYYY-MM-DD"));
      },
      "Next Weekend": () => {
        setStartDateClone(() => moment().day(13).format("YYYY-MM-DD"));
        setEndDateClone(() => moment().day(14).format("YYYY-MM-DD"));
      },
      Custom: () => {
        setStartDateClone(() => moment().startOf("month").format("YYYY-MM-DD"));
        setEndDateClone(() => moment().format("YYYY-MM-DD"));
      },
      "1 week": () => {
        setStartDateClone(() => moment().subtract(6, "d").format("YYYY-MM-DD"));
        setEndDateClone(() => moment().format("YYYY-MM-DD"));
      },
      "1 month": () => {
        setStartDateClone(() =>
          moment().subtract(1, "months").format("YYYY-MM-DD")
        );
        setEndDateClone(() => moment().format("YYYY-MM-DD"));
      },
      "6 months": () => {
        setStartDateClone(() =>
          moment().subtract(6, "months").format("YYYY-MM-DD")
        );
        setEndDateClone(() => moment().format("YYYY-MM-DD"));
      },
    };
    return dateLiteral[value]();
  };
  return (
    <React.Fragment>
      <Autocomplete
        options={options}
        defaultValue={() =>
          selectedSubOption?.length > 0 ? selectedSubOption : []
        }
        onChange={(event, value) => {
          try {
            onDateChange(value);
          } catch (err) {}
        }}
        getOptionLabel={option => (option.length > 0 ? option : "")}
        renderOption={(option, { selected }) => {
          return (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </React.Fragment>
          );
        }}
        style={{ width: "100%", zIndex: 12000, maxWidth: "600px" }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label="Select Date"
              placeholder={startDateClone}
            />
          );
        }}
      />
    </React.Fragment>
  );
};
export default React.memo(CheckboxesTags);

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
