import React, { Fragment, useState, useRef, useEffect, memo } from "react";
import FilterImg from "../../images/icons/Filter.svg";
import GlobalFilter from "../../common/components/GlobalFilter/GlobalFilter";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { removeFiltersInfo } from "../../common/utils/FiltersInfo";

const Filter = ({
  newDashboard,
  addClosebutton = false,
  globalFilter,
  allGlobalFilters,
  globalFilterFor,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  defaultStartDate,
  defaultEndDate,
  defaultFilterDate,
  propertyTypeSelectedOptions,
  setPropertyTypeSelectedOptions,
  projectNames,
  setProjectNames,
  setSourceId,
  filterSource,
  filterStatus,
  filterSubStatus,
  filterTag,
  filterSubSource,
  filterCampaign,
  setFilterCampaign,
  filterQualifyLead,
  setFilterQualifyLead,
  filterLostReason,
  setFilterLostReason,
  setFilterSubSource,
  setFilterSource,
  setFilterStatus,
  setFilterSubStatus,
  setFilterTag,
  rmSelected,
  setRmSelected,
  cpSelected,
  setCPSelected,
  labelChanged,
  setlabelChanged,
  dateFilterType,
  setDateFilterType,
  applyFilter,
  setApplyFilter,
  dateFilterLabels,
  appliedFilterLabels,
  setAppliedFilterLabels,
  selectedSubOptionDate,
  setSelectedSubOptionDate,
  onChangeVisibilityFilterModal,
}) => {
  const [show, setShow] = useState(false);
  const [rmList, setRmList] = useState([]);
  const [cpList, setCPList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [subSourceList, setSubSourceList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [properyTypeList, setProperyTypeList] = useState([
    {
      title: "Residential",
      id: "RESIDENTIAL",
    },
    {
      title: "Commercial",
      id: "COMMERCIAL",
    },
  ]);
  const [qualifyLeadList, setQualifyLeadList] = useState([]);
  const [lostReasonList, setLostReasonList] = useState([]);
  const [projectNamesList, setProjectNamesList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);

  useEffect(() => {
    if (onChangeVisibilityFilterModal) {
      onChangeVisibilityFilterModal(show);
    }
  }, [show]);

  const myref = useRef(null);
  return (
    <Fragment>
      {newDashboard ? (
        <div
          style={{
            color: "#ffffff",
            backgroundColor: "#387DF2",
            borderRadius: "10px",
            width: addClosebutton ? "140px" : "130px",
            height: "21px",
            fontSize: "10px",
            WebkitTapHighlightColor: "transparent",
          }}
          className={`cursor-pointer flex ${
            addClosebutton ? "justify-start" : "justify-center px-2 md:px-0"
          } items-center`}
        >
          {startDate === null && endDate === null ? (
            <Fragment>
              <p className="text-center w-35" onClick={() => setShow(!show)}>
                Life time data
              </p>
            </Fragment>
          ) : (
            <Fragment>
              <div className="w-35 md:w-auto flex">
                <div
                  className={
                    "cursor-pointer flex justify-center items-center px-2"
                  }
                  onClick={() => setShow(!show)}
                >
                  <p className="flex justify-center">
                    {moment(startDate).format("DD MMM, YY")}
                  </p>

                  <p
                    style={{
                      marginLeft: "2px",
                      marginRight: "2px",
                    }}
                  >
                    -
                  </p>
                  <p className="flex justify-center">
                    {moment(endDate).format("DD MMM, YY")}
                  </p>
                </div>
                <div>
                  {addClosebutton && (
                    <button
                      className="text-white ml-2"
                      onClick={() => {
                        setStartDate && setStartDate(defaultStartDate);
                        setEndDate && setEndDate(defaultEndDate);
                        setSelectedSubOptionDate &&
                          setSelectedSubOptionDate("");
                        removeFiltersInfo(globalFilterFor);
                        setApplyFilter(!applyFilter);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} size="lg" />
                    </button>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      ) : (
        <div
          ref={myref}
          id="dcrm_filterBtn"
          className=" bg-white font-roboto font-normal flex flex-col justify-start relative rounded cursor-pointer border border-grey-200"
          style={{
            paddingTop: "7px",
            paddingBottom: "7px",
            width: "138px",
            height: "38px",
          }}
          onClick={() => setShow(!show)}
        >
          <div className="flex flex-row justify-end w-24 md:w-33 flex-row-reverse pl-3">
            <p className="text- text-sm ml-2">Filter</p>
            <img src={FilterImg} alt="Y" />
          </div>
        </div>
      )}
      {globalFilter && (
        <GlobalFilter
          globalFilterFor={globalFilterFor}
          show={show}
          setShow={setShow}
          rmList={rmList}
          setRmList={setRmList}
          cpList={cpList}
          setCPList={setCPList}
          globalFilter={globalFilter}
          allGlobalFilters={allGlobalFilters}
          dateFilterLabels={dateFilterLabels}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          defaultFilterDate={defaultFilterDate}
          sourceSelectedOptions={filterSource}
          setSourceSelectedOptions={setFilterSource}
          statusSelectedOptions={filterStatus}
          setStatusSelectedOptions={setFilterStatus}
          subStatusSelectedOptions={filterSubStatus}
          setSubStatusSelectedOptions={setFilterSubStatus}
          subSourceSelectedOptions={filterSubSource}
          setSubSourceSelectedOptions={setFilterSubSource}
          tagSelectedOptions={filterTag}
          setTagSelectedOptions={setFilterTag}
          propertyTypeSelectedOptions={propertyTypeSelectedOptions}
          setPropertyTypeSelectedOptions={setPropertyTypeSelectedOptions}
          projectNames={projectNames}
          setProjectNames={setProjectNames}
          projectNamesList={projectNamesList}
          setProjectNamesList={setProjectNamesList}
          filterCampaign={filterCampaign}
          setFilterCampaign={setFilterCampaign}
          qualifyLeadSelectedOptions={filterQualifyLead}
          setQualifyLeadSelectedOptions={setFilterQualifyLead}
          lostReasonSelectedOptions={filterLostReason}
          setLostReasonSelectedOptions={setFilterLostReason}
          setFilterSubSource={setFilterSubSource}
          dateFilterTypeOpp={dateFilterType}
          setDateFilterTypeOpp={setDateFilterType}
          rmsSelectedOptions={rmSelected}
          setRmsSelectedOptions={setRmSelected}
          cpSelectedOptions={cpSelected}
          setCPSelectedOptions={setCPSelected}
          setSourceId={setSourceId}
          statusList={statusList}
          setStatusList={setStatusList}
          subStatusList={subStatusList}
          setSubStatusList={setSubStatusList}
          sourceList={sourceList}
          setSourceList={setSourceList}
          tagList={tagList}
          setTagList={setTagList}
          properyTypeList={properyTypeList}
          setProperyTypeList={setProperyTypeList}
          campaignList={campaignList}
          setCampaignList={setCampaignList}
          qualifyLeadList={qualifyLeadList}
          setQualifyLeadList={setQualifyLeadList}
          lostReasonList={lostReasonList}
          setLostReasonList={setLostReasonList}
          subSourceList={subSourceList}
          setSubSourceList={setSubSourceList}
          labelChanged={labelChanged}
          setlabelChanged={setlabelChanged}
          applyFilter={applyFilter}
          setApplyFilter={setApplyFilter}
          appliedFilterLabels={appliedFilterLabels}
          setAppliedFilterLabels={setAppliedFilterLabels}
          selectedSubOptionDate={selectedSubOptionDate}
          setSelectedSubOptionDate={setSelectedSubOptionDate}
        />
      )}
    </Fragment>
  );
};

export default Filter;
