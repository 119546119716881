import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import { red } from "@material-ui/core/colors";
import Tabs from "@material-ui/core/Tabs";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SubSelectedFilter from "./SubSelectedFilter/SubSelectedFilter";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const CommonAppliedFilters = ({
  groupName,
  array,
  setArray,
  id,
  dateFilterLabels,
  updateAppliedFilter,
  setUpdateAppliedFilter,
  timeUpdaterAppliedFilter,
  setTimeUpdaterAppliedFilter,
  setDateSubOptions,
  globalFilterFor,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  defaultStartDate,
  defaultEndDate,
  defaultFilterDate,
  selectedSubOptionDate,
  setSelectedSubOptionDate,
  clearIndvFilter,
  setClearIndvFilter,
}) => {
  const [isMouseHover, setIsMouseOver] = useState(false);
  const classes = useStyles();
  const handleIndvClear = groupName => {
    setClearIndvFilter(() => groupName);
  };
  return (
    <div
      className="flex flex-row flex-no-wrap items-center content-center rounded-3xl my-1 mx-0.5 w-full max-w-full py-1 px-1"
      style={{
        width: "max-content",
        height: "max-content",
        backgroundColor: "#E0E0E0",
      }}
    >
      <div
        className=" rounded-full p-2 text-black-100 mr-0.5 "
        style={{
          width: "fit-content",
          height: "35px",
          backgroundColor: "#BDBDBD",
        }}
      >
        <div className="flex flex-row cursor-pointer whitespace-no-wrap ">
          {groupName}
          {groupName !== "Date" && groupName !== "DateType" && (
            <div
              className="ml-0.5 -mt-0.5"
              onMouseOver={() => setIsMouseOver(true)}
              onMouseOut={() => setIsMouseOver(false)}
              onMouseDown={() => setIsMouseOver(null)}
              onClick={() => handleIndvClear(groupName)}
            >
              <CancelRoundedIcon
                classKey="bg-white"
                classNames="bg-white text-white"
                style={{ color: isMouseHover === null ? red[700] : null }}
                color={
                  isMouseHover === null
                    ? "secondary"
                    : isMouseHover
                    ? ""
                    : "action"
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row flex-wrap  max-w-full w-full h-auto overflow-x-hidden ">
        <Tabs
          value={0}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto "
        >
          <SubSelectedFilter
            id={id}
            key={id}
            dateFilterLabels={dateFilterLabels}
            a11yProps={a11yProps}
            globalFilterFor={globalFilterFor}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
            defaultFilterDate={defaultFilterDate}
            updateAppliedFilter={updateAppliedFilter}
            setUpdateAppliedFilter={setUpdateAppliedFilter}
            timeUpdaterAppliedFilter={timeUpdaterAppliedFilter}
            setTimeUpdaterAppliedFilter={setTimeUpdaterAppliedFilter}
            array={array}
            setArray={setArray}
            setDateSubOptions={setDateSubOptions}
            selectedSubOptionDate={selectedSubOptionDate}
            setSelectedSubOptionDate={setSelectedSubOptionDate}
          />
        </Tabs>
      </div>
    </div>
  );
};

export default CommonAppliedFilters;
