import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import React, { Fragment, memo, useMemo } from "react";
import styles from "./styles/checkBox.module.css";

const CommonAppliedFilter = ({
  allAppliedFilters = {},
  setShowSelectedFilter,
  selectionData,
  onYourLeft,
}) => {
  const { scrollBar } = styles;

  return (
    <div
      className={`w-full flex space-x-2 items-center overflow-x-auto ${
        onYourLeft ? `ml-24` : `mr-24`
      } ${scrollBar}`}
    >
      {Object?.entries(allAppliedFilters)?.map(([key, value], index) => (
        <Fragment key={index}>
          {value?.length > 0 && (
            <div
              className="rounded-2xl border shadow-sm pl-4 pr-3 p-1 text-xs cursor-pointer"
              style={{ borderColor: "#387DF2", backgroundColor: "#387DF2" }}
            >
              <div
                className="w-full flex items-center"
                onClick={() => {
                  if (key === "fromDate" || key === "toDate")
                    setShowSelectedFilter(selectionData["Date"]);
                  else setShowSelectedFilter(selectionData[key]);
                }}
              >
                <p
                  className="mr-1"
                  style={{
                    textTransform: "capitalize",
                    color: "#FFFFFF",
                  }}
                >
                  {key == "toDate"
                    ? "to"
                    : key == "fromDate"
                    ? "from"
                    : key == "rm"
                    ? "RM"
                    : key == "projectNames"
                    ? "Projects"
                    : key}
                </p>
                <div
                  className={`flex items-center border-white mr-3 ml-1 ${
                    !(
                      key === "fromDate" ||
                      key === "toDate" ||
                      key === "dateFilterType"
                    ) && "rounded-full border w-4 h-4"
                  }`}
                >
                  <div
                    className={`w-full text-white whitespace-no-wrap text-center`}
                    style={{
                      fontSize:
                        !(
                          key === "fromDate" ||
                          key === "toDate" ||
                          key === "dateFilterType"
                        ) && "10px",
                    }}
                  >{`${
                    key === "fromDate" ||
                    key === "toDate" ||
                    key === "dateFilterType"
                      ? value
                      : value.length
                  }`}</div>
                </div>
                <KeyboardArrowDownOutlined className="h-4 w-4 text-white" />
              </div>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default memo(CommonAppliedFilter);
