import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import DateAndTimePickers from "../../common/components/DateTimePicker/DateTimePicker";
import Ratings from "../../common/components/RatingComponent/Ratings";
import _ from "lodash";
import {
  addFeedback,
  getTagList,
  getFeedback,
} from "../../common/constants/api";
import moment from "moment";
import CommonCheckBoxIcon from "../../common/components/CommonIconCheckbox/CommonCheckboxIcon";
import { getSelectedgivenFeed } from "./WalkInFormColumn";

const CustomerFeedbackForm = ({
  setPopUpShow,
  setPopUpText,
  setPopUpType,
  setAutoClose,
  oppId,
  setShowFeedback,
  formId,
  showFeedback,
  setSubmitedFeedback,
}) => {
  const [followUpTime, setFollowUpTime] = useState(null);
  const [reviseDate, setRevisedDate] = useState(null);
  const [tagList, setTagList] = useState([{ label: "", value: "" }]);
  const [isDateValid, setIsDateValid] = useState(true);
  const [dateErrorText, setDateErrorText] = useState("");
  const [formData, setFormData] = useState();
  let feedbackthing = getSelectedgivenFeed();

  const ratingOptions = [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
    {
      label: 4,
      value: 4,
    },
    {
      label: 5,
      value: 5,
    },
  ];
  return (
    <Formik
      initialValues={{
        comments: "",
        product: "",
        location: "",
        price: "",
        inventory: "",
        possession: "",
        easePayment: "",
        fincityBrand: "",
        walkInStatus: "",
      }}
      onSubmit={(values, actions) => {}}
    >
      {({
        values,
        handleChange,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        setFieldTouched,
      }) => {
        let dateObjectReviseDate = new Date(reviseDate);
        let dateObjectfollowUpDate = new Date(followUpTime);
        if (
          reviseDate &&
          followUpTime &&
          !(dateObjectReviseDate > dateObjectfollowUpDate)
        ) {
          setIsDateValid(dateObjectReviseDate > dateObjectfollowUpDate);
          setDateErrorText(
            "Revised date should be greater than follow Up date"
          );
        } else {
          setIsDateValid(true);
        }

        useEffect(() => {
          if (feedbackthing) {
            setPopUpType("loading");
            setPopUpText("loading...");
            setPopUpShow(true);
            setShowFeedback(false);
            getFeedback(oppId, formId)
              .then(res => {
                setFormData(res);
                setFieldValue("product", res?.feedback[1] || "");
                setFieldValue("location", res?.feedback[2] || "");
                setFieldValue("price", res?.feedback[3] || "");
                setFieldValue("inventory", res?.feedback[4] || "");
                setFieldValue("possession", res?.feedback[5] || "");
                setFieldValue("easePayment", res?.feedback[6] || "");
                setFieldValue("fincityBrand", res?.feedback[7] || "");
                setFieldValue("comments", res?.comment || "");
                setPopUpShow(false);
                if (showFeedback) {
                  setShowFeedback(true);
                }
              })
              .catch(err => {
                setPopUpShow(false);
              });
          } else {
            setFormData();
            setFieldValue("comments", "");
            setFieldValue("product", "");
            setFieldValue("location", "");
            setFieldValue("price", "");
            setFieldValue("inventory", "");
            setFieldValue("possession", "");
            setFieldValue("easePayment", "");
            setFieldValue("fincityBrand", "");
            setFieldValue("walkInStatus", "");
            setFollowUpTime(null);
            setRevisedDate(null);
          }
        }, [feedbackthing, oppId]);

        //get tags
        useEffect(() => {
          let tagArray = [];
          getTagList().then(data => {
            data?.map(each =>
              tagArray?.push({
                label: each?.name || "Choose Tags",
                value: each?.id || 0,
              })
            );
            setTagList(tagArray);
          });
        }, []);

        const saveFeedback = () => {
          setPopUpType("loading");
          setPopUpText("saving feedback, please wait...");
          setPopUpShow(true);
          let body = {
            opportunityId: oppId,
            formId: formId,
            feedback: {
              1: values?.product,
              2: values?.location,
              3: values?.price,
              4: values?.inventory,
              5: values?.possession,
              6: values?.easePayment,
              7: values?.fincityBrand,
            },
            followUpDate: followUpTime
              ? moment(followUpTime).format("YYYY-MM-DD HH:mm")
              : null,
            visitDate: reviseDate
              ? moment(reviseDate).format("YYYY-MM-DD HH:mm")
              : null,
            tagId: values?.walkInStatus,
            comment: values?.comments,
          };
          addFeedback(oppId, body)
            .then(() => {
              setShowFeedback(false);
              setPopUpType("success");
              setPopUpText("Feedback saved successfully!");
              setPopUpShow(true);
              setAutoClose(true);
              setSubmitedFeedback(true);
            })
            .catch(error => {
              setPopUpShow(false);
            });
        };

        return (
          <Form>
            <p className="text-black-1011 text-base font-medium font-roboto pb-2 px-4">
              Add Feedback
            </p>
            <div className="lg:flex w-full pt-4 pb-2">
              <div className="lg:w-4/12 lg:mr-2 ml-4 text-grey-970 ">
                Product
              </div>
              <div className="lg:w-8/12 ">
                <div className="ml-auto flex">
                  {ratingOptions &&
                    ratingOptions.map((each, index) => {
                      return (
                        <div key={each?.label} className={`w-2/12 pr-1 `}>
                          <div className="flex block w-full">
                            <Ratings
                              label={each?.label}
                              checked={values?.product === each?.value}
                              onSetValue={() => {
                                setFieldValue("product", each?.value);
                              }}
                              disabled={formData?.opportunityId}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="lg:flex w-full py-2">
              <div className="lg:w-4/12 lg:mr-2 ml-4 text-grey-970 ">
                Location
              </div>
              <div className="lg:w-8/12  ">
                <div className="ml-auto flex">
                  {ratingOptions &&
                    ratingOptions.map((each, index) => {
                      return (
                        <div key={each?.label} className={`w-2/12 pr-1 `}>
                          <div className="flex block w-full">
                            <Ratings
                              label={each?.label}
                              checked={values?.location === each?.value}
                              onSetValue={() => {
                                setFieldValue("location", each?.value);
                              }}
                              disabled={formData?.opportunityId}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="lg:flex w-full py-2">
              <div className="lg:w-4/12 lg:mr-2 ml-4 text-grey-970 ">Price</div>
              <div className="lg:w-8/12  ">
                <div className="ml-auto flex">
                  {ratingOptions &&
                    ratingOptions.map((each, index) => {
                      return (
                        <div key={each?.label} className={`w-2/12 pr-1 `}>
                          <div className="flex block w-full">
                            <Ratings
                              label={each?.label}
                              checked={values?.price === each?.value}
                              onSetValue={() => {
                                setFieldValue("price", each?.value);
                              }}
                              disabled={formData?.opportunityId}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="lg:flex w-full py-2">
              <div className="lg:w-4/12 lg:mr-2 ml-4 text-grey-970 ">
                Choice of Inventory
              </div>
              <div className="lg:w-8/12  ">
                <div className="ml-auto flex">
                  {ratingOptions &&
                    ratingOptions.map((each, index) => {
                      return (
                        <div key={each?.label} className={`w-2/12 pr-1 `}>
                          <div className="flex block w-full">
                            <Ratings
                              label={each?.label}
                              checked={values?.inventory === each?.value}
                              onSetValue={() => {
                                setFieldValue("inventory", each?.value);
                              }}
                              disabled={formData?.opportunityId}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="lg:flex w-full py-2">
              <div className="lg:w-4/12 lg:mr-2 ml-4 text-grey-970">
                Possession timeline
              </div>
              <div className="lg:w-8/12  ">
                <div className="ml-auto flex">
                  {ratingOptions &&
                    ratingOptions.map((each, index) => {
                      return (
                        <div key={each?.label} className={`w-2/12 pr-1 `}>
                          <div className="flex block w-full">
                            <Ratings
                              label={each?.label}
                              checked={values?.possession === each?.value}
                              onSetValue={() => {
                                setFieldValue("possession", each?.value);
                              }}
                              disabled={formData?.opportunityId}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="lg:flex w-full py-2">
              <div className="lg:w-4/12 lg:mr-2 ml-4 text-grey-970">
                Ease of Payment
              </div>
              <div className="lg:w-8/12  ">
                <div className="ml-auto flex">
                  {ratingOptions &&
                    ratingOptions.map((each, index) => {
                      return (
                        <div key={each?.label} className={`w-2/12 pr-1 `}>
                          <div className="flex block w-full">
                            <Ratings
                              label={each?.label}
                              checked={values?.easePayment === each?.value}
                              onSetValue={() => {
                                setFieldValue("easePayment", each?.value);
                              }}
                              disabled={formData?.opportunityId}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="lg:flex w-full py-2">
              <div className="lg:w-4/12 lg:mr-2 ml-4 text-grey-970">
                Fincity Brand
              </div>
              <div className="lg:w-8/12">
                <div className="ml-auto flex">
                  {ratingOptions &&
                    ratingOptions.map((each, index) => {
                      return (
                        <div key={each?.label} className={`w-2/12 pr-1 `}>
                          <div className="flex block w-full">
                            <Ratings
                              label={each?.label}
                              checked={values?.fincityBrand === each?.value}
                              onSetValue={() => {
                                setFieldValue("fincityBrand", each?.value);
                              }}
                              disabled={formData?.opportunityId}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            {!formData?.opportunityId && (
              <React.Fragment>
                <div className="lg:flex w-full py-2">
                  <div className="lg:w-4/12 lg:-mr-3 ml-4 text-grey-970">
                    Walkin status
                  </div>
                  <div className="lg:w-8/12 ">
                    <div className="lg:flex">
                      {tagList &&
                        tagList.map(each => {
                          return (
                            <div
                              key={each?.value}
                              className="flex lg:my-2 my-2 block pr-2"
                            >
                              <CommonCheckBoxIcon
                                label={each?.label}
                                checked={values?.walkInStatus == each?.value}
                                iconprops={false}
                                topRightCheck={true}
                                rightCheck={false}
                                onSetValue={() => {
                                  setFieldValue("walkInStatus", each?.value);
                                }}
                                containerStyle={"lg:w-19 h-12"}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <header
                  style={{
                    borderBottom: "1px solid #DCE9F5",
                    bottom: "15px",
                  }}
                  className="flex ml-auto relative pb-3 my-1"
                ></header>

                <p className="text-black-1011 text-base font-medium font-roboto pb-2 px-4">
                  Follow up date
                </p>
                <div className="lg:flex w-full ">
                  <div className="lg:w-full lg:mr-2 lg:ml-4">
                    <DateAndTimePickers
                      showTimeSelect={true}
                      startDate={followUpTime}
                      placeholderText="Follow up date"
                      legendName="Follow up date"
                      timeIntervals={15}
                      dateFormat={"MMM d, yyyy h:mm aa"}
                      setStartDate={setFollowUpTime}
                      showCalenderIcon={true}
                      // classStyle={"lg:w-60 w-96"}
                      inputStyle={{ height: "48px", color: "#545454" }}
                      labelStyle={{
                        display: "block",
                        paddingBottom: "5px",
                        color: "#545454",
                      }}
                      outerClassStyle={"lg:w-56 w-96"}
                    />
                  </div>
                </div>
                <p className="text-black-1011 text-base font-medium font-roboto py-2 mt-2 px-4">
                  Revisit date
                </p>
                <div className="lg:flex w-full ">
                  <div className="lg:full lg:mr-4 lg:ml-4">
                    <DateAndTimePickers
                      showTimeSelect={true}
                      startDate={reviseDate}
                      placeholderText="Revisiting date"
                      legendName="Revisiting date"
                      timeIntervals={15}
                      dateFormat={"MMM d, yyyy h:mm aa"}
                      setStartDate={setRevisedDate}
                      showCalenderIcon={true}
                      inputStyle={{ height: "48px", color: "#545454" }}
                      labelStyle={{
                        display: "block",
                        paddingBottom: "5px",
                      }}
                      outerClassStyle={"lg:w-56 w-96"}
                    />
                  </div>
                </div>
                {!isDateValid && (
                  <div className="text-red-100 text-left lg:ml-4 mt-1">
                    {dateErrorText}
                  </div>
                )}
              </React.Fragment>
            )}
            <div className="">
              <p className="text-black-1011 text-base  font-roboto pb-2 px-4 pt-6">
                Write a Comment
              </p>
              <textarea
                name="comments"
                id="comments"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.comments}
                maxLength="200"
                style={{
                  height: "100px",
                  minWidth: "100%",
                  borderBottom: "1px solid #DCE9F5",
                  padding: "10px",
                  color: "#212B34",
                  marginLeft: "10px",
                }}
                disabled={formData?.opportunityId}
              />
            </div>
            {!formData?.opportunityId && (
              <div className="w-full mx-auto mb-8">
                <button
                  onClick={saveFeedback}
                  disabled={!_.isEmpty(errors) || values?.comments == ""}
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "400",
                  }}
                  className="submitButton font-semibold lg:w-45 w-56"
                  type="button"
                  id="submit"
                >
                  Add Feedback
                </button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
export default CustomerFeedbackForm;
