import React, { useState, useEffect, useContext, Fragment } from "react";
import "./NewDateFilter.css";
import * as _ from "lodash";
import moment from "moment";
import { DeveloperDataContext } from "../../../../common/utils/appContext";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import CommonSelect from "../../CommonComponent/CommonSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";
import useWindowResize from "../../../../hooks/useWindowResize";

const NewDateFilter = ({
  label,
  globalFilterFor,
  showLifeTimeData,
  minStartDate,
  maxEndDate,
}) => {
  const Context = useContext(DeveloperDataContext);
  const [windowWidth] = useWindowResize();
  const customDateOptions = [
    { value: 1, label: "Today" },
    { value: 2, label: "Yesterday" },
    { value: 3, label: "Tommorow" },
    { value: 4, label: "This Week" },
    { value: 5, label: "Last Week" },

    ...(globalFilterFor === "weekendVisibilityReport"
      ? [
          { value: 6, label: "This Weekend" },
          { value: 7, label: "Last Weekend" },
          { value: 8, label: "Next Weekend" },
        ]
      : []),
    { value: 9, label: "1 week" },
    { value: 10, label: "This month" },
    { value: 11, label: "Last month" },
    { value: 12, label: "Custom" },
    { value: 13, label: "1 month" },
    { value: 14, label: "6 months" },
  ];
  const dateLiteral = {
    Today: {
      fromDate: moment().format("YYYY-MM-DD"),

      toDate: moment().format("YYYY-MM-DD"),
    },
    Yesterday: {
      fromDate: moment().subtract(1, "d").format("YYYY-MM-DD"),

      toDate: moment().subtract(1, "d").format("YYYY-MM-DD"),
    },
    Tommorow: {
      fromDate: moment().add(1, "d").format("YYYY-MM-DD"),

      toDate: moment().add(1, "d").format("YYYY-MM-DD"),
    },
    "This Week": {
      fromDate: moment().startOf("week").add(1, "d").format("YYYY-MM-DD"),

      toDate: moment().endOf("week").add(1, "d").format("YYYY-MM-DD"),
    },
    "Last Week": {
      fromDate: moment()
        .startOf("week")
        .subtract(1, "week")
        .add(1, "d")
        .format("YYYY-MM-DD"),

      toDate: moment().startOf("week").format("YYYY-MM-DD"),
    },
    "This month": {
      fromDate: moment().startOf("month").format("YYYY-MM-DD"),

      toDate: moment().format("YYYY-MM-DD"),
    },
    "Last month": {
      fromDate: moment()
        .startOf("month")
        .subtract(1, "month")
        .format("YYYY-MM-DD"),

      toDate: moment().startOf("month").subtract(1, "d").format("YYYY-MM-DD"),
    },
    "This Weekend": {
      fromDate: moment().day(6).format("YYYY-MM-DD"),

      toDate: moment().day(7).format("YYYY-MM-DD"),
    },
    "Last Weekend": {
      fromDate: moment().day(-1).format("YYYY-MM-DD"),

      toDate: moment().day(0).format("YYYY-MM-DD"),
    },
    "Next Weekend": {
      fromDate: moment().day(13).format("YYYY-MM-DD"),

      toDate: moment().day(14).format("YYYY-MM-DD"),
    },
    Custom: {
      fromDate: moment().startOf("month").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    },
    "1 week": {
      fromDate: moment().subtract(6, "d").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    },
    "1 month": {
      fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),

      toDate: moment().format("YYYY-MM-DD"),
    },
    "6 months": {
      fromDate: moment().subtract(6, "months").format("YYYY-MM-DD"),

      toDate: moment().format("YYYY-MM-DD"),
    },
  };

  const [startDateClone, setStartDateClone] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [endDateClone, setEndDateClone] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [subOptionDateClone, setSubOptionDateClone] = useState({
    value: 11,
    label: "1 month",
  });
  const [updateDate, setUpdateDate] = useState(false);

  useEffect(() => {
    if (
      Context?.appData[`appliedGlobalFilter${globalFilterFor}`] &&
      Context?.appData[`appliedGlobalFilter${globalFilterFor}`]?.hasOwnProperty(
        "Date"
      )
    ) {
      let date =
        Context?.appData[`appliedGlobalFilter${globalFilterFor}`]["Date"];

      if (showLifeTimeData) {
        date.hasOwnProperty("fromDate") &&
          setStartDateClone(
            date["fromDate"] ||
              moment().subtract(1, "months").format("YYYY-MM-DD")
          );
        date.hasOwnProperty("toDate") &&
          setEndDateClone(date["toDate"] || moment().format("YYYY-MM-DD"));
        setSubOptionDateClone({ value: 11, label: "1 month" });
      } else {
        date.hasOwnProperty("fromDate") && setStartDateClone(date["fromDate"]);
        date.hasOwnProperty("toDate") && setEndDateClone(date["toDate"]);
      }
    }
    if (
      Context?.appData[`appliedGlobalFilter${globalFilterFor}`] &&
      Context?.appData[`appliedGlobalFilter${globalFilterFor}`]?.hasOwnProperty(
        "DateLabel"
      )
    ) {
      setSubOptionDateClone(
        Context?.appData[`appliedGlobalFilter${globalFilterFor}`]["DateLabel"]
      );
    }
  }, [updateDate]);

  const handleChange = value => {
    try {
      let date = dateLiteral[value.label];

      Context?.setAppData({
        ...Context.appData,
        [`appliedGlobalFilter${globalFilterFor}`]: {
          ...Context?.appData[`appliedGlobalFilter${globalFilterFor}`],
          Date: { ...date },
          DateLabel: value,
        },
      });
      setUpdateDate(!updateDate);
    } catch (error) {}
  };
  const handleStartDate = fromDate => {
    Context?.setAppData({
      ...Context.appData,
      [`appliedGlobalFilter${globalFilterFor}`]: {
        ...Context?.appData[`appliedGlobalFilter${globalFilterFor}`],
        Date: {
          fromDate: moment(fromDate).format("YYYY-MM-DD"),
          toDate: moment(endDateClone).format("YYYY-MM-DD"),
        },
        DateLabel: { value: 10, label: "Custom" },
      },
    });
    setUpdateDate(!updateDate);
  };

  const handleEndDate = toDate => {
    Context?.setAppData({
      ...Context.appData,
      [`appliedGlobalFilter${globalFilterFor}`]: {
        ...Context?.appData[`appliedGlobalFilter${globalFilterFor}`],
        Date: {
          fromDate: moment(startDateClone).format("YYYY-MM-DD"),
          toDate: moment(toDate).format("YYYY-MM-DD"),
        },
        DateLabel: { value: 10, label: "Custom" },
      },
    });
    setUpdateDate(!updateDate);
  };

  return (
    <div className="w-full">
      <p
        className="font-sans font-normal text-sm text-black-400"
        style={{
          position: "absolute",
          left: windowWidth > 767 ? "24px" : "8px",
          top: windowWidth > 767 ? "24px" : "8px",
        }}
      >
        {label}
      </p>
      <div className="w-full mt-2 px-1 md:px-0">
        <CommonSelect
          name="dateFilterType"
          dateFilter={true}
          value={subOptionDateClone}
          onChange={e => {
            handleChange(e);
          }}
          options={customDateOptions}
          isHoverEffect
          stylesContainer={{
            marginBottom: "0px",
            fontSize: "40px",
            height: "52px",
            marginBottom: "20px",
            paddingLeft: "8px",
            borderColor: "#C4C4C4",
          }}
          dropdownStyle={{
            top: "52px",
            color: "rgba(0,0,0,0.87)",
            fontSize: "16px",
            backgroundColor: "#fff",
            alignItems: "center",
            fontFamily: "Roboto",
            fontWeight: 400,
          }}
          styleText={{
            fontSize: "14px",
            padding: "0 6px",
            backgroundColor: "white",
          }}
        />
      </div>
      {subOptionDateClone.value != "" && (
        <div className="w-full flex flex-col items-center mt-4 md:flex-row md:justify-between md:items-center">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="w-full px-1">
              <DatePicker
                autoOk
                id="from-date"
                label="From"
                format="yyyy-MM-dd"
                value={startDateClone}
                maxDate={endDateClone}
                minDate={minStartDate}
                onChange={e => handleStartDate(e)}
                inputVariant="outlined"
                variant="inline"
              />
            </div>
          </MuiPickersUtilsProvider>
          <div className="mr-6 my-2 md:my-0">
            <FontAwesomeIcon
              icon={faLongArrowRight}
              className="text-grey-50 text-3xl"
            />
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="w-full px-1">
              <DatePicker
                autoOk
                id="to-date"
                label="To"
                format="yyyy-MM-dd"
                value={endDateClone}
                minDate={startDateClone}
                maxDate={maxEndDate}
                onChange={e => handleEndDate(e)}
                inputVariant="outlined"
                variant="inline"
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
      )}
    </div>
  );
};
export default NewDateFilter;
