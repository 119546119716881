import React, { useState, useEffect, useRef, memo, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import CommonInput from "../../common/components/CommonComponent/CommonInput";
import SearchIcon from "../../images/icons/searchIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterOptionsInPrinciple from "../../components/Table/FilterInPrinciple/FilterInPrinciple";
import {
  getFiltersInfo,
  setFiltersInfo,
  removeFiltersInfo,
} from "../../common/utils/FiltersInfo";
import CommonAppliedFilter from "../../common/components/CommonAppliedFilterGroupings/CommonAppliedFilterGroupings";
import Filter from "../../containers/PerformanceReport/Filter";
import "./ResponsiveTable.css";

const SearchBarFocused = (searchContainer, searchBarplaceHolder) => {
  let searchinput =
    searchContainer.current.children[0].children[0].children[1].children[0];
  searchinput.placeholder = searchBarplaceHolder;
  searchinput.style.height = "32px";
};

const TableTopHeader = ({
  newDashboard,
  addClosebutton,
  showAppliedFilter,
  globalFilterFor,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  defaultStartDate = moment().subtract(1, "months").format("YYYY-MM-DD"),
  defaultEndDate = moment().format("YYYY-MM-DD"),
  defaultFilterDate = startDate === defaultStartDate &&
    endDate === defaultEndDate,
  showDefaultDate,
  showDefaultDateType,
  labelChanged,
  setlabelChanged,
  dontShowAutoFilter,
  globalFilter,
  allGlobalFilters,
  startDateClone,
  setStartDateClone,
  endDateClone,
  setEndDateClone,
  groupNames,
  filterArrays,
  setFilterArrays,
  onApply,
  setOnApply,
  onSearch,
  searchText,
  total_data,
  dontShowSearchBar,
  searchBarPlaceHolder,
  filterSource,
  filterStatus,
  filterSubStatus,
  filterTag,
  filterSubSource,
  setFilterSubSource,
  setSourceId,
  setFilterTag,
  propertyTypeSelected,
  setPropertyTypeSelected,
  projectNames,
  setProjectNames,
  dateFilterType,
  setDateFilterType,
  rmSelected,
  setRmSelected,
  cpSelected,
  setCPSelected,
  filterCampaign,
  setFilterCampaign,
  filterQualifyLead,
  setFilterQualifyLead,
  filterLostReason,
  setFilterLostReason,
  setFilterSource,
  setFilterStatus,
  setFilterSubStatus,
  tableFor,
  filterComponent,
  rm,
  customHeaderComponent,
  applyFilter,
  setApplyFilter,
  dateFilterLabels,
  appliedFilterLabels,
  setAppliedFilterLabels,
  updateAppliedFilter,
  setUpdateAppliedFilter,
  onChangeVisibilityFilterModal,
  InputPlaceholder,
  bucketName,
}) => {
  const searchContainer = useRef(null);
  const [timeUpdaterAppliedFilter, setTimeUpdaterAppliedFilter] = useState(
    false
  );
  const [dateSubOption, setDateSubOptions] = useState(false);
  const [clearAll, setClearAll] = useState(false);
  const [clearIndvFilter, setClearIndvFilter] = useState("");
  const [searchbar, setSearchBar] = useState("");
  const [selectedSubOptionDate, setSelectedSubOptionDate] = useState(
    getFiltersInfo()[globalFilterFor]?.dateLabel || ""
  );

  // clearAll Filter
  useEffect(() => {
    if (clearAll) {
      setFilterSource && setFilterSource([]);
      setFilterStatus && setFilterStatus([]);
      setFilterSubStatus && setFilterSubStatus([]);
      setRmSelected && setRmSelected([]);
      setCPSelected && setCPSelected([]);
      setFilterSubSource && setFilterSubSource([]);
      setFilterTag && setFilterTag([]);
      setPropertyTypeSelected && setPropertyTypeSelected([]);
      setProjectNames && setProjectNames([]);
      setFilterCampaign && setFilterCampaign([]);
      setFilterQualifyLead && setFilterQualifyLead([]);
      setFilterLostReason && setFilterLostReason([]);
      setDateFilterType &&
        setDateFilterType(dateFilterLabels[0]?.toUpperCase() + "_DATE");
      setRmSelected && setRmSelected([]);
      setStartDate && setStartDate(defaultStartDate);
      setEndDate && setEndDate(defaultEndDate);
      setSelectedSubOptionDate("");
      setTimeout(() => {
        setUpdateAppliedFilter && setUpdateAppliedFilter(!updateAppliedFilter);
      }, 500);
      removeFiltersInfo(globalFilterFor);
    }
    setClearAll(false);
  }, [clearAll]);

  // handling Individual Clear
  const IndvStorageSetter = key => {
    let tempFilterKey = getFiltersInfo() || {};
    if (globalFilterFor) {
      tempFilterKey[globalFilterFor] = {
        ...tempFilterKey[globalFilterFor],
      };
      (tempFilterKey[globalFilterFor][key] = []), setFiltersInfo(tempFilterKey);
    }
  };

  const handlerInvclear = () => {
    if (clearIndvFilter) {
      switch (clearIndvFilter) {
        case "RM":
          setRmSelected && setRmSelected([]);
          IndvStorageSetter("rm");
          break;
        case "Status":
          setFilterStatus && setFilterStatus([]);
          IndvStorageSetter("status");
          break;
        case "Substatus":
          setFilterSubStatus && setFilterSubStatus([]);
          IndvStorageSetter("subStatus");
          break;
        case "Source":
          setFilterSource && setFilterSource([]);
          IndvStorageSetter("source");
          break;
        case "Subsource":
          setFilterSubSource && setFilterSubSource([]);
          IndvStorageSetter("subSource");
          break;
        case "Tag":
          setFilterTag && setFilterTag([]);
          IndvStorageSetter("tagFilter");
          break;
        case "Property Type":
          setPropertyTypeSelected && setPropertyTypeSelected([]);
          IndvStorageSetter("propertyType");
          break;
        case "Property Name":
          setProjectNames && setProjectNames([]);
          IndvStorageSetter("projectNames");
          break;
        case "Campaign":
          setFilterCampaign && setFilterCampaign([]);
          IndvStorageSetter("campaign");
          break;
        case "Chanel Partner":
          setCPSelected && setCPSelected([]);
          IndvStorageSetter("cp");
          break;
        case "Qualify Lead":
          setFilterQualifyLead && setFilterQualifyLead([]);
          IndvStorageSetter("qualifyLead");
          break;
        case "Lost Reason":
          setFilterLostReason && setFilterLostReason([]);
          IndvStorageSetter("lostReason");
          break;
        default:
          return null;
      }
      return (
        setUpdateAppliedFilter && setUpdateAppliedFilter(!updateAppliedFilter)
      );
    }
  };
  useEffect(() => {
    return handlerInvclear();
  }, [clearIndvFilter]);

  useEffect(() => {
    let tempFilterKey = getFiltersInfo() || {};
    if (globalFilterFor) {
      tempFilterKey[globalFilterFor] = {
        ...tempFilterKey[globalFilterFor],
        ...(dateSubOption && {
          dateLabel: "",
        }),
        fromDate: startDate,
        toDate: endDate,
        status: filterStatus,
        subStatus: filterSubStatus,
        tagFilter: filterTag,
        campaign: filterCampaign,
        propertyType: propertyTypeSelected,
        projectNames: projectNames,
        dateFilterType: dateFilterType,
        source: filterSource,
        subSource: filterSubSource,
        rm: rmSelected,
        cp: cpSelected,
        qualifyLead: filterQualifyLead,
        lostReason: filterLostReason,
      };
      setFiltersInfo(tempFilterKey);
      setDateSubOptions(false);
    }
  }, [updateAppliedFilter, timeUpdaterAppliedFilter, dateSubOption]);
  const CommonFilterMemo = useMemo(
    () => (
      <CommonAppliedFilter
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        defaultFilterDate={defaultFilterDate}
        globalFilterFor={globalFilterFor}
        showDefaultDate={showDefaultDate}
        showDefaultDateType={showDefaultDateType}
        dateSubOption={selectedSubOptionDate}
        updateAppliedFilter={updateAppliedFilter}
        setUpdateAppliedFilter={setUpdateAppliedFilter}
        timeUpdaterAppliedFilter={timeUpdaterAppliedFilter}
        setTimeUpdaterAppliedFilter={setTimeUpdaterAppliedFilter}
        setDateSubOptions={setDateSubOptions}
        dateFilterLabels={dateFilterLabels}
        groupNames={
          groupNames
            ? groupNames
            : globalFilterFor === "opportunity"
            ? [
                "Date",
                "DateType",
                "RM",
                "Status",
                "Substatus",
                "Source",
                "Subsource",
                "Tag",
                "Property Type",
                "Property Name",
                "Campaign",
                "Chanel Partner",
                "Qualify Lead",
                "Lost Reason",
              ]
            : ["Date", "DateType", "RM", "Property Name"]
        }
        filterArrays={
          filterArrays
            ? [timeUpdaterAppliedFilter, ...filterArrays]
            : globalFilterFor === "opportunity"
            ? [
                timeUpdaterAppliedFilter,
                dateFilterType,
                rmSelected,
                filterStatus,
                filterSubStatus,
                filterSource,
                filterSubSource,
                filterTag,
                propertyTypeSelected,
                projectNames,
                filterCampaign,
                cpSelected,
                filterQualifyLead,
                filterLostReason,
              ]
            : [
                timeUpdaterAppliedFilter,
                dateFilterType,
                rmSelected,
                projectNames,
              ]
        }
        setFilterArrays={
          setFilterArrays
            ? [setTimeUpdaterAppliedFilter, ...setFilterArrays]
            : globalFilterFor === "opportunity"
            ? [
                setTimeUpdaterAppliedFilter,
                setDateFilterType,
                setRmSelected,
                setFilterStatus,
                setFilterSubStatus,
                setFilterSource,
                setFilterSubSource,
                setFilterTag,
                setPropertyTypeSelected,
                setProjectNames,
                setFilterCampaign,
                setCPSelected,
                setFilterQualifyLead,
                setFilterLostReason,
              ]
            : [
                setTimeUpdaterAppliedFilter,
                setDateFilterType,
                setRmSelected,
                setProjectNames,
              ]
        }
        selectedSubOptionDate={selectedSubOptionDate}
        setSelectedSubOptionDate={setSelectedSubOptionDate}
        clearAll={clearAll}
        setClearAll={setClearAll}
        clearIndvFilter={clearIndvFilter}
        setClearIndvFilter={setClearIndvFilter}
      />
    ),
    [applyFilter, updateAppliedFilter]
  );
  return (
    <div className="w-full max-w-full">
      {customHeaderComponent ? (
        customHeaderComponent()
      ) : (
        <div
          className={
            (rm
              ? "flex  md:flex-row md:flex-wrap  justify-between "
              : "lg:flex  left-0  items-center block mx-auto  ") +
            " w-full  h-full   lg:py-0 items-center "
          }
          onLoad={() =>
            !dontShowSearchBar &&
            SearchBarFocused(searchContainer, searchBarPlaceHolder)
          }
        >
          <div
            className={
              (rm ? "md:w-auto" : "mx-auto ") + " w-28  flex  items-center my-3"
            }
          >
            {filterComponent ? (
              <div
                className={
                  newDashboard ? `w-6/12 md:w-48 flex ` : `w-6/12 lg:w-48 flex `
                }
              >
                {globalFilter && (
                  <Filter
                    newDashboard={newDashboard}
                    addClosebutton={addClosebutton}
                    onChangeVisibilityFilterModal={
                      onChangeVisibilityFilterModal
                    }
                    globalFilter={globalFilter}
                    globalFilterFor={globalFilterFor}
                    allGlobalFilters={allGlobalFilters}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    defaultStartDate={defaultStartDate}
                    defaultEndDate={defaultEndDate}
                    defaultFilterDate={defaultFilterDate}
                    startDateClone={startDateClone}
                    setStartDateClone={setStartDateClone}
                    endDateClone={endDateClone}
                    setEndDateClone={setEndDateClone}
                    onApply={onApply}
                    setOnApply={setOnApply}
                    setSourceId={setSourceId}
                    filterSource={filterSource}
                    filterStatus={filterStatus}
                    filterSubStatus={filterSubStatus}
                    filterTag={filterTag}
                    filterSubSource={filterSubSource}
                    filterCampaign={filterCampaign}
                    setFilterCampaign={setFilterCampaign}
                    filterQualifyLead={filterQualifyLead}
                    setFilterQualifyLead={setFilterQualifyLead}
                    filterLostReason={filterLostReason}
                    setFilterLostReason={setFilterLostReason}
                    setFilterSubSource={setFilterSubSource}
                    setFilterTag={setFilterTag}
                    setFilterSource={setFilterSource}
                    setFilterStatus={setFilterStatus}
                    setFilterSubStatus={setFilterSubStatus}
                    propertyTypeSelectedOptions={propertyTypeSelected}
                    setPropertyTypeSelectedOptions={setPropertyTypeSelected}
                    projectNames={projectNames}
                    setProjectNames={setProjectNames}
                    rmSelected={rmSelected}
                    setRmSelected={setRmSelected}
                    cpSelected={cpSelected}
                    setCPSelected={setCPSelected}
                    labelChanged={labelChanged}
                    setlabelChanged={setlabelChanged}
                    dateFilterType={dateFilterType}
                    setDateFilterType={setDateFilterType}
                    applyFilter={applyFilter}
                    setApplyFilter={setApplyFilter}
                    dateFilterLabels={dateFilterLabels}
                    appliedFilterLabels={appliedFilterLabels}
                    setAppliedFilterLabels={setAppliedFilterLabels}
                    selectedSubOptionDate={selectedSubOptionDate}
                    setSelectedSubOptionDate={setSelectedSubOptionDate}
                  />
                )}
              </div>
            ) : null}
            <div className="flex space-x-10">
              {tableFor && (
                <p className="hidden md:block font-roboto font-normal text-xs text-black-500 ml-3 lg:-ml-4">
                  List of {total_data} {tableFor}
                </p>
              )}
              {bucketName && (
                <p className="hidden md:block font-roboto font-semibold text-xs text-green-900 ml-3 lg:-ml-4">
                  Showing opportunity for {bucketName} Bucket
                </p>
              )}
            </div>
          </div>
          {rm && !dontShowAutoFilter ? (
            <div className="mx-2 my-2">
              <AutoCompleteFilterBar setRmId={setRmId} rm={rmId} />
            </div>
          ) : null}
          {!dontShowSearchBar && (
            <div
              className={
                (!rm
                  ? "lg:absolute my-4 lg:my-0  mr-3 mx-5 mx-auto ml-auto mt-6 lg:w-5/12 "
                  : "   lg:w-4/12") + " right-0 w-11/12   flex   "
              }
              ref={searchContainer}
            >
              <CommonInput
                name="searchbar"
                id="searchbar"
                onChange={event => {
                  if (event.target.value == "") {
                    onSearch(event.target.value);
                    setSearchBar(event.target.value);
                  }
                }}
                onBlur={event => {
                  onSearch(event.target.value);
                  setSearchBar(event.target.value);
                }}
                onKeyDown={event => {
                  if (event.keyCode === 13) {
                    onSearch(event.target.value);
                    setSearchBar(event.target.value);
                  }
                }}
                //value={searchbar}
                stylesContainer={{
                  height: "40px",
                  maxWidth: "100%",
                  marginBottom: "0px",
                  fontSize: "40px",
                  color: "rgba(0,0,0,0.87)",
                  outline: "none",
                }}
                dropdownStyle={{
                  top: "40px",
                  color: "rgba(0,0,0,0.87)",
                  fontSize: "16px",
                  backgroundColor: "#fff",
                  alignItems: "center",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                }}
                styleText={{
                  fontSize: "16px",
                  padding: "0 25px",
                }}
                inputStyle={{
                  height: "38px",
                }}
                placeholder={InputPlaceholder}
              />
              <div
                style={{
                  margin: "1px 0",
                  position: "relative",
                  left: "-2px",
                }}
                className="flext align-center justify-center text-center bg-green-900 text-white w-20 -ml-12 z-20 pt-2 rounded-sm cursor-pointer"
                onClick={() => {
                  onSearch(searchbar);
                }}
              >
                Enter
              </div>
            </div>
          )}
        </div>
      )}
      {showAppliedFilter && CommonFilterMemo}
    </div>
  );
};

export default TableTopHeader;
