import React, { useState, useEffect, useRef, Fragment } from "react";
import styles from "./CustomerFeedback.module.css";
import FeedbackForm from "./CustomerFeedbackForm";
import { getOppDetailsofFeedback } from "../../common/constants/api";
import PopUp from "../../common/components/PopUp/PopUp";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";

const CustomerFeedback = ({
  open,
  setOpen,
  oppId,
  tableData,
  formId,
  setSubmitedFeedback,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [popUpShow, setPopUpShow] = useState(false);
  const [autoClose, setAutoClose] = useState(true);
  const [popUpType, setPopUpType] = useState("fail");
  const [popUpText, setPopUpText] = useState(
    "Something went wrong. Please try again"
  );
  const [getDetails, setGetDetails] = useState({});

  useEffect(() => {
    setShowFeedback(open);
  }, [open]);

  useEffect(() => {
    if (oppId) {
      getOppDetailsofFeedback(oppId)
        .then(res => {
          setGetDetails(res);
          setPopUpShow(false);
        })
        .catch(err => {
          setPopUpShow(false);
        });
    }
  }, [oppId]);

  return (
    <Fragment>
      <div className="overflow-y-auto h-screen">
        <div className="w-full pt-4 px-4">
          <table className="  text-sm font-robot font-normal text-left ">
            <tbody>
              <tr className="leading-8">
                <td className="w-4/12 sm:3/12 md:w-3/12 lg:w-4/12 text-grey-970">
                  Opp ID <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right  sm:w-11/12 md:w-11/12 lg:w-11/12 whitespace-normal break-all">
                  {getDetails?.opportunityId || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12 text-grey-970">
                  Client Name <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all ">
                  {getDetails?.name || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12 text-grey-970">
                  Email id <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all">
                  {getDetails?.email || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12 text-grey-970">
                  Phone number <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all">
                  {getDetails?.phone || "--"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <header
          style={{
            borderBottom: "1px solid #DCE9F5",
            bottom: "15px",
          }}
          className="flex ml-auto relative pb-3 my-1"
        ></header>
        <div className="w-full px-4">
          <table className=" text-sm font-robot font-normal text-left ">
            <tbody>
              <tr className="leading-8">
                <td className="w-4/12 sm:3/12 md:w-3/12 lg:w-4/12  text-grey-970">
                  Config <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right  sm:w-11/12 md:w-11/12 lg:w-11/12 whitespace-normal break-all">
                  {getDetails?.config || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12  text-grey-970">
                  Project Name <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all ">
                  {getDetails?.projectName || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12  text-grey-970">
                  Project type <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all">
                  {getDetails?.projectType || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12  text-grey-970">
                  Budget <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all">
                  {getDetails?.budget || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12  text-grey-970">
                  Source <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all">
                  {getDetails?.source || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12  text-grey-970">
                  Sub Source <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all">
                  {getDetails?.subSource || "--"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <header
          style={{
            borderBottom: "1px solid #DCE9F5",
            bottom: "15px",
          }}
          className="flex ml-auto relative pb-3 my-1"
        ></header>
        <div className="w-full px-4">
          <table className="  text-sm font-robot font-normal text-left ">
            <tbody>
              <tr className="leading-8">
                <td className="w-4/12 sm:3/12 md:w-3/12 lg:w-4/12 text-grey-970 ">
                  Occupation <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right  sm:w-11/12 md:w-11/12 lg:w-11/12 whitespace-normal break-all">
                  {getDetails?.occupation || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12 text-grey-970 ">
                  Office Name <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all ">
                  {getDetails?.company || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12 text-grey-970 ">
                  Designation <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all">
                  {getDetails?.designation || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12 text-grey-970 ">
                  Resident Address <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all">
                  {getDetails?.residentAddress || "--"}
                </td>
              </tr>
              <tr className="leading-8">
                <td className="w-4/12 sm:w-3/12 md:w-3/12 lg:w-4/12 text-grey-970 ">
                  Office Address <span className="float-right">:</span>
                </td>
                <td className="lg:w-6/12 w-8/12 float-right   sm:w-11/12 md:w-11/12 lg:w-11/12 break-all">
                  {getDetails?.officeAddress || "--"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <header
          style={{
            borderBottom: "1px solid #DCE9F5",
            bottom: "15px",
          }}
          className="flex ml-auto relative pb-3 my-1"
        ></header>
        <FeedbackForm
          setPopUpShow={setPopUpShow}
          setPopUpText={setPopUpText}
          setPopUpType={setPopUpType}
          setAutoClose={setAutoClose}
          oppId={oppId}
          showFeedback={open}
          setShowFeedback={setOpen}
          tableData={tableData}
          formId={formId}
          setSubmitedFeedback={setSubmitedFeedback}
        />
      </div>
      <PopUp
        type={popUpType}
        text={popUpText}
        show={popUpShow}
        setShow={setPopUpShow}
        autoClose={autoClose}
      />
    </Fragment>
  );
};
export default CustomerFeedback;
