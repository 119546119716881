import React, { Fragment, useContext, useEffect, useState } from "react";
import moment from "moment";
import FilterImg from "../../../images/icons/Filter.svg";
import { DeveloperDataContext } from "../../utils/appContext";
import NewGlobalFilter from "../NewGlobalFilter/NewGlobalFilter";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  removeFiltersInfo,
  setFiltersInfo,
  getFiltersInfo,
} from "../../../common/utils/FiltersInfo";

const GlobalFilter = ({
  nullProject,
  addClosebutton = false,
  newDashboard,
  FilterLabel,
  globalFilter,
  globalFilterFor,
  showLifeTimeData,
  dateTypeFor,
  defaultStartDate,
  defaultEndDate,
  defaultDateType,
  defaultAction,
  statusList,
  setStatusList,
  subStatusList,
  setSubStatusList,
  source,
  setSource,
  subSource,
  setSubSource,
  tag,
  setTag,
  rm,
  setRm,
  sm,
  setSm,
  bpm,
  setBpm,
  propertyType,
  setPropertyType,
  propertyName,
  setPropertyName,
  campaign,
  setCampaign,
  channelPartner,
  setChannelPartner,
  qualifyLead,
  setQualifyLead,
  lostReason,
  setLostReason,
  nonContactableReason,
  setNonContactableReason,
  formType,
  setFormType,
  isActive,
  setIsActive,
  dateTypeList,
  setDateTypeList,
  applyFilter,
  setApplyFilter,
  showFilters,
  setAppliedFilters,
  showSelectedFilter,
  setShowSelectedFilter,
  updateAppliedFilter,
  setUpdateAppliedFilter,
  showSearch,
  defaultSourceId,
  sourceBlock,
  noDate,
  filterStyle,
  minStartDate,
  maxEndDate,
}) => {
  const [show, setShow] = useState(false);
  const context = useContext(DeveloperDataContext);
  const [startDate, setStartDate] = useState(
    showLifeTimeData
      ? null
      : context.appData[`appliedGlobalFilter${globalFilterFor}`]?.Date?.fromDate
  );
  const [endDate, setEndDate] = useState(
    showLifeTimeData
      ? null
      : context.appData[`appliedGlobalFilter${globalFilterFor}`]?.Date?.toDate
  );

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (showSelectedFilter) setShow(!show);
  }, [showSelectedFilter]);

  useEffect(() => {
    setStartDate(
      context.appData[`appliedGlobalFilter${globalFilterFor}`]?.Date?.fromDate
    );
    setEndDate(
      context.appData[`appliedGlobalFilter${globalFilterFor}`]?.Date?.toDate
    );
  }, [context.appData[`appliedGlobalFilter${globalFilterFor}`]]);

  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
  }, [update]);

  const handleClear = async () => {
    context?.setAppData({
      ...context?.appData,
      [`appliedGlobalFilter${globalFilterFor}`]: {
        ...[`appliedGlobalFilter${globalFilterFor}`],
        ...(!noDate && {
          Date: {
            fromDate: defaultStartDate,
            toDate: defaultEndDate,
          },
        }),
      },
    });
    await setApplyFilter(!applyFilter);
    await setUpdateAppliedFilter();
  };

  return (
    <Fragment>
      {newDashboard ? (
        <div
          className="flex rounded-full justify-center cursor-pointer text-white whitespace-no-wrap"
          style={{
            backgroundColor: "#387DF2",
            fontSize: "10.5px",
            padding: "3px 12px 2px 12px",
            ...filterStyle,
          }}
          onClick={() => {
            setShow(!show);
            setShowSelectedFilter();
          }}
        >
          {!startDate && !endDate ? (
            showLifeTimeData ? (
              <Fragment>
                <p className="text-center w-35" onClick={() => setShow(!show)}>
                  Life time data
                </p>
              </Fragment>
            ) : (
              <div className="flex">
                <div>{moment(defaultStartDate).format("DD MMM, YY")}</div>
                <span className="mx-0.5">-</span>
                <div>{moment(defaultEndDate).format("DD MMM, YY")}</div>
                <div>
                  {addClosebutton && (
                    <button
                      className="text-white ml-2"
                      onClick={e => {
                        removeFiltersInfo(globalFilterFor);
                        setUpdate(!update);
                        handleClear();
                        e.stopPropagation();
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} size="lg" />
                    </button>
                  )}
                </div>
              </div>
            )
          ) : (
            <div className="flex items-center">
              <div>{moment(startDate).format("DD MMM, YY")}</div>
              <span className="mx-0.5">-</span>
              <div>{moment(endDate).format("DD MMM, YY")}</div>
              <div>
                {addClosebutton && (
                  <button
                    className="text-white ml-2"
                    onClick={e => {
                      removeFiltersInfo(globalFilterFor);
                      setUpdate(!update);
                      handleClear();
                      e.stopPropagation();
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="absolute bg-white font-roboto flex rounded cursor-pointer border border-grey-200 my-2"
          style={{
            paddingTop: "7px",
            paddingBottom: "7px",
            width: FilterLabel.width,
            height: FilterLabel.height,
          }}
          onClick={() => {
            setShow(!show);
            setShowSelectedFilter();
          }}
        >
          <div className="flex justify-center items-center text-sm font-medium text-black-400 w-full">
            {/* <img src={FilterImg} alt="Y" /> */}
            <p className="ml-2">Filters</p>
          </div>
        </div>
      )}
      {globalFilter && (
        <NewGlobalFilter
          nullProject={nullProject}
          globalFilterFor={globalFilterFor}
          showLifeTimeData={showLifeTimeData}
          dateTypeFor={dateTypeFor}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          defaultDateType={defaultDateType}
          defaultAction={defaultAction}
          show={show}
          setShow={setShow}
          statusList={statusList}
          setStatusList={setStatusList}
          subStatusList={subStatusList}
          setSubStatusList={setSubStatusList}
          source={source}
          setSource={setSource}
          subSource={subSource}
          setSubSource={setSubSource}
          tag={tag}
          setTag={setTag}
          rm={rm}
          setRm={setRm}
          sm={sm}
          setSm={setSm}
          bpm={bpm}
          setBpm={setBpm}
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          propertyName={propertyName}
          setPropertyName={setPropertyName}
          campaign={campaign}
          setCampaign={setCampaign}
          channelPartner={channelPartner}
          setChannelPartner={setChannelPartner}
          qualifyLead={qualifyLead}
          setQualifyLead={setQualifyLead}
          lostReason={lostReason}
          setLostReason={setLostReason}
          nonContactableReason={nonContactableReason}
          setNonContactableReason={setNonContactableReason}
          formType={formType}
          setFormType={setFormType}
          isActive={isActive}
          setIsActive={setIsActive}
          dateTypeList={dateTypeList}
          setDateTypeList={setDateTypeList}
          applyFilter={applyFilter}
          setApplyFilter={setApplyFilter}
          showFilters={showFilters}
          setAppliedFilters={setAppliedFilters}
          showSearch={showSearch}
          showSelectedFilter={showSelectedFilter}
          setShowSelectedFilter={setShowSelectedFilter}
          updateAppliedFilter={updateAppliedFilter}
          setUpdateAppliedFilter={setUpdateAppliedFilter}
          defaultSourceId={defaultSourceId}
          sourceBlock={sourceBlock}
          noDate={noDate}
          minStartDate={minStartDate}
          maxEndDate={maxEndDate}
        />
      )}
    </Fragment>
  );
};

export default GlobalFilter;
