import React, { useState, useEffect, useMemo, useContext } from "react";
import moment from "moment";
import GlobalFilter from "./GlobalFilter";
import CommonAppliedFilters from "./CommonAppliedFilter";
import { DeveloperDataContext } from "../../../common/utils/appContext";
import { setFiltersInfo, getFiltersInfo } from "../../utils/FiltersInfo";
import { presistantFilter } from "./components/PresistantFilter";
import useWindowResize from "../../../hooks/useWindowResize";

const Filter = ({
  nullProject,
  noDate,
  newDashboard,
  showAppliedFilter,
  globalFilterFor,
  globalFilter,
  showLifeTimeData,
  addClosebutton,
  defaultStartDate = moment().subtract(1, "months").format("YYYY-MM-DD"),
  defaultEndDate = moment().format("YYYY-MM-DD"),
  defaultDateType = "",
  defaultAction,
  allAppliedFilters, //list of all applied filter
  setAllAppliedFilters, // set all applied filter
  showFilters, // list of filter names we want to show
  onYourLeft, // position of filter left or right
  dateTypeFor, // send {id:"",name:""} object
  showSearch, // to show search bar to search filter options
  desktop,
  updateAppliedFilter,
  setUpdateAppliedFilter,
  filterContextDataUpdate,
  defaultSourceId,
  sourceBlock,
  newFilterStyle,
  filterStyle,
  filterLabelClass,
  overLapStyle,
  minStartDate,
  maxEndDate,
}) => {
  const [windowsWidth, windowsHeight] = useWindowResize();
  const Context = useContext(DeveloperDataContext);
  const [applyFilter, setApplyFilter] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [source, setSource] = useState([]);
  const [subSource, setSubSource] = useState([]);
  const [tag, setTag] = useState([]);
  const [rm, setRm] = useState([]);
  const [sm, setSm] = useState([]);
  const [bpm, setBpm] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [propertyName, setPropertyName] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [channelPartner, setChannelPartner] = useState([]);
  const [qualifyLead, setQualifyLead] = useState([]);
  const [lostReason, setLostReason] = useState([]);
  const [nonContactableReason, setNonContactableReason] = useState([]);
  const [formType, setFormType] = useState([]);
  const [isActive, setIsActive] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [dateTypeList, setDateTypeList] = useState([]);
  const [showSelectedFilter, setShowSelectedFilter] = useState();
  const selectionData = {
    status: "Status",
    subStatus: "Activity",
    source: "Source",
    subSource: "Sub-source",
    tagFilter: "Tag",
    rm: "RM",
    sm: "SM",
    bpm: "BPM",
    dateFilterType: "Date Type",
    Date: "Date",
    projectNames: "Property Name",
    campaign: "Campaign",
    qualifyLead: "Qualify Lead",
    cp: "Chanel Partner",
    lostReason: "Lost Reason",
    nonContactableReason: "Non Contactable Reason",
    formType: "Form Type",
    isActive: "Action",
  };
  const FilterLabel = {
    width: "90px",
    height: "35px",
  };

  useEffect(() => {
    const data =
      presistantFilter != undefined && presistantFilter(globalFilterFor);
    Context?.setAppData({
      ...Context?.appData,
      [`appliedGlobalFilter${globalFilterFor}`]: {
        ...Context.appData[`appliedGlobalFilter${globalFilterFor}`],
        ...data,
      },
    });
  }, [filterContextDataUpdate]);

  const CommonFilterMemo = useMemo(
    () =>
      !newDashboard && (
        <CommonAppliedFilters
          allAppliedFilters={allAppliedFilters}
          setShowSelectedFilter={setShowSelectedFilter}
          showSelectedFilter={showSelectedFilter}
          selectionData={selectionData}
          onYourLeft={onYourLeft}
          desktop
        />
      ),
    [allAppliedFilters]
  );

  useEffect(() => {
    let contextData = Context.appData[`appliedGlobalFilter${globalFilterFor}`];
    let tempFilterKey = getFiltersInfo() || {};
    if (globalFilterFor && contextData) {
      tempFilterKey[globalFilterFor] = {
        ...tempFilterKey[globalFilterFor],
        ...(contextData?.DateLabel && { dateLabel: contextData?.DateLabel }),
        ...(contextData?.Date?.fromDate && {
          fromDate: contextData?.Date?.fromDate,
        }),
        ...(contextData?.Date?.toDate && { toDate: contextData?.Date?.toDate }),
        ...(contextData?.Status && { status: contextData?.Status }),
        ...(contextData?.Activity && { subStatus: contextData?.Activity }),
        ...(contextData?.Tag && { tagFilter: contextData?.Tag }),
        ...(contextData?.Campaign && { campaign: contextData?.Campaign }),
        ...(contextData?.PropertyType && {
          propertyType: contextData?.PropertyType,
        }),
        ...(contextData?.PropertyName && {
          projectNames: contextData?.PropertyName,
        }),
        ...(contextData?.DateFilterType && {
          dateFilterType: contextData?.DateFilterType,
        }),
        ...(contextData?.Source && { source: contextData?.Source }),
        ...(contextData?.SubSource && { subSource: contextData?.SubSource }),
        ...(contextData?.RM && { rm: contextData?.RM }),
        ...(contextData?.SM && { sm: contextData?.SM }),
        ...(contextData?.BPM && { bpm: contextData?.BPM }),
        ...(contextData?.ChannelPartner && { cp: contextData?.ChannelPartner }),
        ...(contextData?.QualifyLead && {
          qualifyLead: contextData?.QualifyLead,
        }),
        ...(contextData?.LostReason && { lostReason: contextData?.LostReason }),
        ...(contextData?.NonContactableReason && {
          nonContactableReason: contextData?.NonContactableReason,
        }),
        ...(contextData?.FormType && { formType: contextData?.FormType }),
        ...(contextData?.IsActive && { isActive: contextData?.IsActive }),
      };
      setAllAppliedFilters(tempFilterKey[globalFilterFor]);
      setFiltersInfo(tempFilterKey);
    }
  }, [applyFilter]);

  return (
    <div
      className={
        filterLabelClass ??
        `${
          desktop
            ? windowsWidth > 1000
              ? "w-2/3 "
              : "w-1/2"
            : "w-full pl-2 md:px-2 px-0"
        } flex ${
          onYourLeft ? `justify-start` : `justify-end`
        } relative items-center my-1`
      }
      style={{
        WebkitTapHighlightColor: "transparent",
        height: "40px",
        ...overLapStyle,
      }}
    >
      {showAppliedFilter && CommonFilterMemo}
      {globalFilter && (
        <GlobalFilter
          filterStyle={filterStyle}
          nullProject={nullProject}
          newDashboard={newDashboard}
          globalFilter
          globalFilterFor={globalFilterFor}
          showLifeTimeData={showLifeTimeData}
          addClosebutton={addClosebutton}
          updateAppliedFilter={updateAppliedFilter}
          setUpdateAppliedFilter={setUpdateAppliedFilter}
          dateTypeFor={dateTypeFor}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          defaultDateType={defaultDateType}
          defaultAction={defaultAction}
          defaultSourceId={defaultSourceId}
          FilterLabel={FilterLabel}
          statusList={statusList}
          setStatusList={setStatusList}
          subStatusList={subStatusList}
          setSubStatusList={setSubStatusList}
          source={source}
          setSource={setSource}
          subSource={subSource}
          setSubSource={setSubSource}
          tag={tag}
          setTag={setTag}
          rm={rm}
          setRm={setRm}
          sm={sm}
          setSm={setSm}
          bpm={bpm}
          setBpm={setBpm}
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          propertyName={propertyName}
          setPropertyName={setPropertyName}
          campaign={campaign}
          setCampaign={setCampaign}
          channelPartner={channelPartner}
          setChannelPartner={setChannelPartner}
          qualifyLead={qualifyLead}
          setQualifyLead={setQualifyLead}
          lostReason={lostReason}
          setLostReason={setLostReason}
          nonContactableReason={nonContactableReason}
          setNonContactableReason={setNonContactableReason}
          formType={formType}
          setFormType={setFormType}
          isActive={isActive}
          setIsActive={setIsActive}
          dateTypeList={dateTypeList}
          setDateTypeList={setDateTypeList}
          applyFilter={applyFilter}
          setApplyFilter={setApplyFilter}
          showSelectedFilter={showSelectedFilter}
          setShowSelectedFilter={setShowSelectedFilter}
          showFilters={showFilters}
          showSearch={showSearch}
          setAllAppliedFilters={setAllAppliedFilters}
          sourceBlock={sourceBlock}
          noDate={noDate}
          newFilterStyle={newFilterStyle}
          minStartDate={minStartDate}
          maxEndDate={maxEndDate}
        />
      )}
    </div>
  );
};

export default Filter;
