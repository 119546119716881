import React, { useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const SubSelectedFilter = ({
  array,
  setArray,
  globalFilterFor,
  updateAppliedFilter,
  setUpdateAppliedFilter,
  timeUpdaterAppliedFilter,
  setTimeUpdaterAppliedFilter,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  defaultStartDate,
  defaultEndDate,
  defaultFilterDate,
  id,
  setDateSubOptions,
  a11yProps,
  selectedSubOptionDate,
  setSelectedSubOptionDate,
  dateFilterLabels,
}) => {
  const [max, setMax] = useState(3);
  const classes = useStyles();

  const handleDelete = (data, i) => {
    if (i === 0) {
      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
      setSelectedSubOptionDate("");
      setDateSubOptions(true);
    }
    if (i === 1) {
      if (array !== dateFilterLabels[0]?.toUpperCase() + "_DATE") {
        setArray(dateFilterLabels[0]?.toUpperCase() + "_DATE");
        setSelectedSubOptionDate("");
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setDateSubOptions(true);
      }
    } else if (i !== 0 && i !== 1)
      setArray(chips => chips.filter(chip => chip.id !== data.id));
    setTimeUpdaterAppliedFilter(!timeUpdaterAppliedFilter);
    setUpdateAppliedFilter(!updateAppliedFilter);
  };

  return (
    <div
      key={id}
      className={classes.root + " cursor-pointer"}
      onClick={() => {
        if (id !== 0 && id !== 1) {
          setMax(array?.length);
          // setUpdateAppliedFilter(!updateAppliedFilter);
        }
      }}
    >
      <AvatarGroup {...a11yProps(id)} max={max}>
        {/* avatar */}
        {id === 0 ? (
          defaultFilterDate ? (
            <Chip
              key={id}
              label={
                id === 0
                  ? `${moment(startDate).format("DD/MM/YYYY")}-${moment(
                      endDate
                    ).format("DD/MM/YYYY")}`
                  : array
              }
              color="primary"
            />
          ) : (
            <Chip
              key={id}
              onDelete={() => handleDelete(null, id)}
              label={
                id === 0
                  ? `${moment(startDate).format("DD/MM/YYYY")}-${moment(
                      endDate
                    ).format("DD/MM/YYYY")}`
                  : array
              }
              color="primary"
            />
          )
        ) : id === 1 ? (
          dateFilterLabels[0] !== "" &&
          array !== dateFilterLabels[0]?.toUpperCase() + "_DATE" ? (
            <Chip
              label={
                id === 0
                  ? `${moment(startDate).format("DD/MM/YYYY")}-${moment(
                      endDate
                    ).format("DD/MM/YYYY")}`
                  : array
              }
              onDelete={() => handleDelete(null, id)}
              color="primary"
            />
          ) : (
            <Chip
              label={
                id === 0
                  ? `${moment(startDate).format("DD/MM/YYYY")}-${moment(
                      endDate
                    ).format("DD/MM/YYYY")}`
                  : array
              }
              color="primary"
            />
          )
        ) : (
          array.map((data, i) => (
            <Chip
              label={
                id === 0
                  ? `${moment(startDate).format("DD/MM/YYYY")}-${moment(
                      endDate
                    ).format("DD/MM/YYYY")}`
                  : data?.title
              }
              onDelete={() => handleDelete(data, id)}
              color="primary"
            />
          ))
        )}
      </AvatarGroup>
    </div>
  );
};
export default SubSelectedFilter;
