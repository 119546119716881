import React, { useState, useEffect, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Modal from "../../../common/components/Modal/modal";
import MbBottomModal from "../../../common/components/MbBottomModal/MbBottomModal";
import { getSubSource } from "../../../common/constants/api";
import {
  removeFiltersInfo,
  setFiltersInfo,
  getFiltersInfo,
} from "../../../common/utils/FiltersInfo";
import { getUserType, getRole } from "../../../common/utils/cookie-utils";
import { getAcl } from "../../../common/utils/acl-utils";
import DashboardDateFilter from "../../../common/components/DashboardDateFilter/DashboardDateFilter";
import MultiSelectCheckbox from "../../../common/components/MultiSelectCheckbox/MultiSelectCheckbox";
import DateFilter from "./DateFilter/DateFilter";
import activityImg from "../../../images/globalFilter/globalActivity.svg";
import dateImg from "../../../images/globalFilter/globalDate.svg";
import rmImg from "../../../images/globalFilter/globalRealEstate.svg";
import statusImg from "../../../images/globalFilter/globalStatus.svg";
import tagImg from "../../../images/globalFilter/globalTag.svg";
import sourceImg from "../../../images/globalFilter/globalSource.svg";
import qualifyLeadImg from "../../../images/globalFilter/leadType.svg";
const getRMFilterAccess = () => {
  return [
    "Admin",
    "Inside Sales Vertical Head",
    "CP Vertical Head",
    "Sales Vertical Head",
    "Inside Sales Cluster Head",
    "CP Cluster Head",
    "Sales Cluster Head",
    "Inside Sales Team Lead",
    "CP Team Lead",
    "Sales Team Lead",
  ].includes(JSON.parse(getRole()).name);
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={2}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
    key: index,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ScrollableTabsButtonAuto = memo(
  ({
    globalFilterFor,
    selectedDateFilter,
    setSelectedDateFilter,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    defaultStartDate,
    defaultEndDate,
    selectedSubOptionDate,
    setSelectedSubOptionDate,
    setDateFilterType,
    dateFilterType,
    customDateOptions = [
      "Today",
      "Yesterday",
      "Tommorow",
      "This Week",
      "1 week",
      "This month",
      "Custom",
      "1 month",
      "6 months",
    ],
  }) => {
    globalFilterFor === "weekendVisibilityReport" &&
      customDateOptions.splice(
        4,
        0,
        "This Weekend",
        "Last Weekend",
        "Next Weekend"
      );
    const classes = useStyles();
    const [value, setValue] = useState(selectedDateFilter);
    const [startDateClone, setStartDateClone] = useState(startDate);
    const [subOptionDateClone, setSubOptionDateClone] = useState(
      selectedSubOptionDate
    );
    const [endDateClone, setEndDateClone] = useState(endDate);

    useEffect(() => {
      setSelectedSubOptionDate(() => subOptionDateClone);
      setStartDate(() => startDateClone);
      setEndDate(() => endDateClone);
    }, [startDateClone, endDateClone, subOptionDateClone]);
    const handleChange = (event, newValue) => {
      if (value !== newValue) {
        setEndDateClone(defaultEndDate);
        setStartDateClone(defaultStartDate);
        setValue(newValue);
        setSelectedDateFilter(newValue);
      }
    };
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {dateFilterType?.map((el, i) => (
              <Tab label={`${el} date`} {...a11yProps(i)} />
            ))}
          </Tabs>
        </AppBar>
        {dateFilterType?.map((el, i) => (
          <div key={i} className="space-y-2">
            <TabPanel value={value} index={i}>
              <React.Fragment>
                <DateFilter
                  value={value}
                  filterType={el}
                  selectedSubOption={subOptionDateClone}
                  setSelectedSubOption={setSubOptionDateClone}
                  options={customDateOptions}
                  endDateClone={endDateClone}
                  startDateClone={startDateClone}
                  setStartDateClone={setStartDateClone}
                  setEndDateClone={setEndDateClone}
                />
              </React.Fragment>
              <div className="mt-5 block relative">
                {subOptionDateClone && (
                  <DashboardDateFilter
                    withPortal
                    keepOpenOnDateSelect
                    setSelectedSubOption={setSelectedSubOptionDate}
                    setFromDate={setStartDateClone}
                    setToDate={setEndDateClone}
                    fromDate={startDateClone}
                    toDate={endDateClone}
                  />
                )}
              </div>
            </TabPanel>
          </div>
        ))}
      </div>
    );
  },
  []
);

const CommonFilter = ({
  selectedOptions,
  setSelectedOptions,
  multiStyle,
  labelKey,
  options,
  setOptions,
  label,
  placeholder,
  resetToggler,
}) => {
  const [selectedOptionsClone, setSelectedOptionsClone] = useState(
    selectedOptions
  );

  useEffect(() => {
    resetToggler && setSelectedOptionsClone([]);
  }, [resetToggler]);
  useEffect(() => {
    setSelectedOptions(selectedOptionsClone);
  }, [selectedOptionsClone]);

  return (
    <MultiSelectCheckbox
      options={options}
      setOptions={setOptions}
      labelKey={labelKey}
      label={label}
      placeholder={placeholder}
      selectedOptionsClone={selectedOptionsClone}
      setSelectedOptionsClone={setSelectedOptionsClone}
      style={multiStyle}
    />
  );
};

const GlobalFilter = ({
  show,
  setShow,
  globalFilterFor,
  defaultStartDate,
  defaultEndDate,
  defaultFilterDate,
  rmsSelectedOptions,
  setRmsSelectedOptions,
  cpSelectedOptions,
  setCPSelectedOptions,
  sourceSelectedOptions,
  setSourceSelectedOptions,
  subSourceSelectedOptions,
  setSubSourceSelectedOptions,
  statusSelectedOptions,
  setStatusSelectedOptions,
  subStatusSelectedOptions,
  setSubStatusSelectedOptions,
  tagSelectedOptions,
  setTagSelectedOptions,
  propertyTypeSelectedOptions,
  setPropertyTypeSelectedOptions,
  projectNames,
  setProjectNames,
  filterCampaign,
  setFilterCampaign,
  qualifyLeadSelectedOptions,
  setQualifyLeadSelectedOptions,
  lostReasonSelectedOptions,
  setLostReasonSelectedOptions,
  dateFilterTypeOpp,
  setDateFilterTypeOpp,
  allGlobalFilters,
  dateFilterLabels,
  showActivity = allGlobalFilters,
  showDate = allGlobalFilters || dateFilterLabels,
  showRm = () =>
    getRMFilterAccess() ? allGlobalFilters || rmsSelectedOptions : false,
  showCP = () =>
    getAcl()?.can_add_business_partner
      ? allGlobalFilters || cpSelectedOptions
      : false,
  showStatus = allGlobalFilters || statusSelectedOptions,
  showTag = allGlobalFilters || tagSelectedOptions,
  showSubStatus = allGlobalFilters || subStatusSelectedOptions,
  showSource = allGlobalFilters || sourceSelectedOptions,
  showSubSource = allGlobalFilters || subSourceSelectedOptions,
  showProperty = allGlobalFilters || propertyTypeSelectedOptions,
  showPropertyName = allGlobalFilters || projectNames,
  showCampaign = getUserType() == 0
    ? allGlobalFilters || filterCampaign
    : false,
  showQualifyLead = allGlobalFilters || qualifyLeadSelectedOptions,
  showLostReason = allGlobalFilters || lostReasonSelectedOptions,
  setDateFilterType,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  labelKeyRm = "name",
  labelKeySource = "name",
  labelKeySubSource = "name",
  labelKeyStatus = "name",
  labelKeySubStatus = "name",
  labelKeyTag = "name",
  labelKeyProperty = "title",
  labelKeyPropertyName = "title",
  labelKeyCampaign = "name",
  labelKeyCP = "name",
  labelKeyQualifyLead = "name",
  labelKeyLostReason = "name",
  rmList,
  setRmList,
  cpList,
  setCPList,
  statusList,
  setStatusList,
  subStatusList,
  setSubStatusList,
  sourceList,
  setSourceList,
  subSourceList,
  setSubSourceList,
  tagList,
  setTagList,
  properyTypeList,
  setProperyTypeList,
  projectNamesList,
  setProjectNamesList,
  campaignList,
  setCampaignList,
  qualifyLeadList,
  setQualifyLeadList,
  lostReasonList,
  setLostReasonList,
  labelChanged,
  setlabelChanged,
  applyFilter,
  setApplyFilter,
  selectedSubOptionDate,
  setSelectedSubOptionDate,
}) => {
  const [intialBlocker, setInItBlocker] = useState(false);
  const dateTypeSetter = () => {
    return Math.max(
      dateFilterLabels.findIndex(el => {
        let strToArr = dateFilterTypeOpp?.toString()?.split("_") || false;
        if (strToArr)
          return (
            el.toUpperCase() ===
            strToArr?.filter((el, i) => i < strToArr.length - 1).join("_")
          );
        else return 0;
      }),
      0
    );
  };
  const [activeDiv, setActiveDiv] = useState([0, "Date"]);

  const [selectedDateFilter, setSelectedDateFilter] = useState(
    dateTypeSetter()
  );
  const [resetToggler, setResetToggler] = useState(false);
  const [apiCallCount, setApiCallCount] = useState(0);
  //making filter to be in effect with localStorage
  useEffect(() => {
    if (intialBlocker) {
      let tempFilterKey = getFiltersInfo() || {};
      if (globalFilterFor)
        tempFilterKey[globalFilterFor] = {
          ...tempFilterKey[globalFilterFor],
          status: statusSelectedOptions,
          subStatus: subStatusSelectedOptions,
          fromDate: startDate,
          toDate: endDate,
          dateLabel: selectedSubOptionDate,
          tagFilter: tagSelectedOptions,
          propertyType: propertyTypeSelectedOptions,
          projectNames: projectNames,
          campaign: filterCampaign,
          dateFilterType: dateFilterTypeOpp,
          source: sourceSelectedOptions,
          subSource: subSourceSelectedOptions,
          rm: rmsSelectedOptions,
          cp: cpSelectedOptions,
          qualifyLead: qualifyLeadSelectedOptions,
          lostReason: lostReasonSelectedOptions,
        };
      setFiltersInfo(tempFilterKey);
    }
    setInItBlocker(false);
  }, [
    intialBlocker,
    statusSelectedOptions,
    subStatusSelectedOptions,
    selectedSubOptionDate,
    tagSelectedOptions,
    propertyTypeSelectedOptions,
    projectNames,
    filterCampaign,
    dateFilterTypeOpp,
    sourceSelectedOptions,
    subSourceSelectedOptions,
    rmsSelectedOptions,
    cpSelectedOptions,
    qualifyLeadSelectedOptions,
    lostReasonSelectedOptions,
  ]);
  useEffect(() => {
    if (setSubSourceSelectedOptions && sourceSelectedOptions) {
      let soubSourceData = sourceSelectedOptions.filter(
        x => x.title === "Social Media" || x.title === "Portal Leads"
      );
      if (Array.isArray(soubSourceData) && soubSourceData.length === 0) {
        setSubSourceSelectedOptions([]);
        setSubSourceList([]);
        setApiCallCount(0);
      } else {
        if (
          Array.isArray(subSourceList) &&
          soubSourceData.length > apiCallCount
        ) {
          getSubSource(soubSourceData[soubSourceData.length - 1].id)
            .then(res => {
              let tempArr1 = [];
              res?.map((el, i) => {
                tempArr1.push({
                  id:
                    el["userId"] !== null && el["userId"] !== undefined
                      ? el["userId"]
                      : el["id"] !== null && el["id"] !== undefined
                      ? el["id"]
                      : el[labelKeySubSource],
                });
                tempArr1[i][labelKeySubSource] = el[labelKeySubSource];
              });
              setSubSourceList([...subSourceList, ...tempArr1]);
              setApiCallCount(soubSourceData.length);
            })
            .catch(err => {});
        }
      }
    }
  }, [sourceSelectedOptions]);
  useEffect(() => {
    setDateFilterTypeOpp &&
      setDateFilterTypeOpp(
        dateFilterLabels[selectedDateFilter]?.toUpperCase() + "_DATE"
      );
  }, [selectedDateFilter]);
  const multiStyle = {
    width: "100%",
    zIndex: 12000,
    maxWidth: "90%",
    marginTop: "5px",
  };

  const filterOptions = [
    {
      icons: dateImg,
      label: "Date",
      shouldShow: showDate,
    },
    {
      icons: statusImg,
      label: "Status",
      shouldShow: showStatus,
    },
    {
      icons: activityImg,
      label: "Activity",
      shouldShow: showSubStatus,
    },
    {
      icons: sourceImg,
      label: "Source",
      shouldShow: showSource,
    },
    ...(sourceSelectedOptions?.some(
      x => x.title === "Social Media" || x.title === "Portal Leads"
    )
      ? [
          {
            icons: statusImg,
            label: "Sub-source",
            shouldShow: showSubSource,
          },
        ]
      : []),
    {
      icons: tagImg,
      label: "Tag",
      shouldShow: showTag,
    },
    {
      icons: rmImg,
      label: "RM",
      shouldShow: showRm(),
    },
    {
      icons: sourceImg,
      label: "Property Type",
      shouldShow: showProperty,
    },
    {
      icons: statusImg,
      label: "Property Name",
      shouldShow: showPropertyName,
    },
    {
      icons: tagImg,
      label: "Campaign",
      shouldShow: showCampaign,
    },
    {
      icons: rmImg,
      label: "Chanel Partner",
      shouldShow: showCP(),
    },
    {
      icons: qualifyLeadImg,
      label: "Qualify Lead",
      shouldShow: showQualifyLead,
    },
    {
      icons: statusImg,
      label: "Lost Reason",
      shouldShow: showLostReason,
    },
  ];

  const DateFilterMemo = memo(() => {
    return (
      <ScrollableTabsButtonAuto
        globalFilterFor={globalFilterFor}
        selectedDateFilter={selectedDateFilter}
        setSelectedDateFilter={setSelectedDateFilter}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        endDate={endDate}
        startDate={startDate}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        setDateFilterType={setDateFilterType}
        dateFilterType={dateFilterLabels}
        labelKeyRm={labelKeyRm}
        selectedSubOptionDate={selectedSubOptionDate}
        setSelectedSubOptionDate={setSelectedSubOptionDate}
      />
    );
  }, []);

  const SelectedFilter = memo(({ name }) => {
    var functions = {
      Date: <DateFilterMemo />,
      Status: (
        <CommonFilter
          selectedOptions={statusSelectedOptions}
          setSelectedOptions={setStatusSelectedOptions}
          multiStyle={multiStyle}
          resetToggler={resetToggler}
          label={"Choose Status"}
          placeholder={"+status"}
          labelKey={labelKeyStatus}
          options={statusList}
          setOptions={setStatusList}
        />
      ),
      Activity: (
        <CommonFilter
          selectedOptions={subStatusSelectedOptions}
          setSelectedOptions={setSubStatusSelectedOptions}
          multiStyle={multiStyle}
          resetToggler={resetToggler}
          label={"Choose Activity"}
          placeholder={"+Activity"}
          labelKey={labelKeySubStatus}
          options={subStatusList}
          setOptions={setSubStatusList}
        />
      ),
      Source: (
        <CommonFilter
          selectedOptions={sourceSelectedOptions}
          setSelectedOptions={setSourceSelectedOptions}
          multiStyle={multiStyle}
          resetToggler={resetToggler}
          labelKey={labelKeySource}
          label={"Choose Source"}
          placeholder={"+source"}
          options={sourceList}
          setOptions={setSourceList}
        />
      ),
      "Sub-source": (
        <CommonFilter
          selectedOptions={subSourceSelectedOptions}
          setSelectedOptions={setSubSourceSelectedOptions}
          multiStyle={multiStyle}
          resetToggler={resetToggler}
          label={"Choose Sub-source"}
          placeholder={"+sub-source"}
          labelKey={labelKeySubSource}
          options={subSourceList}
          setOptions={setSubSourceList}
        />
      ),
      Tag: (
        <CommonFilter
          selectedOptions={tagSelectedOptions}
          setSelectedOptions={setTagSelectedOptions}
          multiStyle={multiStyle}
          resetToggler={resetToggler}
          label={"Choose Tag"}
          placeholder={"+tag"}
          labelKey={labelKeyTag}
          options={tagList}
          setOptions={setTagList}
        />
      ),
      RM: (
        <CommonFilter
          selectedOptions={rmsSelectedOptions}
          setSelectedOptions={setRmsSelectedOptions}
          multiStyle={multiStyle}
          labelKey={labelKeyRm}
          resetToggler={resetToggler}
          label={"Choose RM"}
          placeholder={"+RM"}
          options={rmList}
          setOptions={setRmList}
        />
      ),
      "Property Type": (
        <CommonFilter
          selectedOptions={propertyTypeSelectedOptions}
          setSelectedOptions={setPropertyTypeSelectedOptions}
          multiStyle={multiStyle}
          resetToggler={resetToggler}
          labelKey={labelKeyProperty}
          label={"Choose Property Type"}
          placeholder={"+property type"}
          options={properyTypeList}
          setOptions={setProperyTypeList}
        />
      ),
      "Property Name": (
        <CommonFilter
          selectedOptions={projectNames}
          setSelectedOptions={setProjectNames}
          multiStyle={multiStyle}
          resetToggler={resetToggler}
          labelKey={labelKeyPropertyName}
          label={"Choose Property Name"}
          placeholder={"+property name"}
          options={projectNamesList}
          setOptions={setProjectNamesList}
        />
      ),
      Campaign: (
        <CommonFilter
          selectedOptions={filterCampaign}
          setSelectedOptions={setFilterCampaign}
          multiStyle={multiStyle}
          resetToggler={resetToggler}
          labelKey={labelKeyCampaign}
          label={"Choose Campaign"}
          placeholder={"+Campaign"}
          options={campaignList}
          setOptions={setCampaignList}
        />
      ),
      "Chanel Partner": (
        <CommonFilter
          selectedOptions={cpSelectedOptions}
          setSelectedOptions={setCPSelectedOptions}
          multiStyle={multiStyle}
          labelKey={labelKeyCP}
          resetToggler={resetToggler}
          label={"Choose CP"}
          placeholder={"+CP"}
          options={cpList}
          setOptions={setCPList}
        />
      ),
      "Qualify Lead": (
        <CommonFilter
          selectedOptions={qualifyLeadSelectedOptions}
          setSelectedOptions={setQualifyLeadSelectedOptions}
          multiStyle={multiStyle}
          labelKey={labelKeyQualifyLead}
          resetToggler={resetToggler}
          label={"Choose Qualify Lead"}
          placeholder={"+Qualify Lead"}
          options={qualifyLeadList}
          setOptions={setQualifyLeadList}
        />
      ),
      "Lost Reason": (
        <CommonFilter
          selectedOptions={lostReasonSelectedOptions}
          setSelectedOptions={setLostReasonSelectedOptions}
          multiStyle={multiStyle}
          labelKey={labelKeyLostReason}
          resetToggler={resetToggler}
          label={"Choose Lost Reason"}
          placeholder={"+Lost Reason"}
          options={lostReasonList}
          setOptions={setLostReasonList}
        />
      ),
    };
    return functions[name];
  }, []);
  const HorizontalDevider = ({ style, className }) => (
    <div
      style={{
        borderBottom: "2px solid #DCE9F5",
        bottom: "15px",
        ...style,
      }}
      className={className}
    ></div>
  );
  const resetHandler = globalFilterFor => {
    setResetToggler(true);
    setStartDate && setStartDate(defaultStartDate);
    setEndDate && setEndDate(defaultEndDate);
    setStatusSelectedOptions && setStatusSelectedOptions([]);
    setSubStatusSelectedOptions && setSubStatusSelectedOptions([]);
    setSourceSelectedOptions && setSourceSelectedOptions([]);
    setSubSourceSelectedOptions && setSubSourceSelectedOptions([]);
    setTagSelectedOptions && setTagSelectedOptions([]);
    setRmsSelectedOptions && setRmsSelectedOptions([]);
    setCPSelectedOptions && setCPSelectedOptions([]);
    setPropertyTypeSelectedOptions && setPropertyTypeSelectedOptions([]);
    setProjectNames && setProjectNames([]);
    setFilterCampaign && setFilterCampaign([]);
    setQualifyLeadSelectedOptions && setQualifyLeadSelectedOptions([]);
    setLostReasonSelectedOptions && setLostReasonSelectedOptions([]);
    setSelectedDateFilter && setSelectedDateFilter(0);
    setSelectedSubOptionDate && setSelectedSubOptionDate("");
    removeFiltersInfo(globalFilterFor);
    setResetToggler(false);
    setApplyFilter(!applyFilter);
  };

  const applyHandler = async globalFilterFor => {
    await setApplyFilter(!applyFilter);
    await setInItBlocker(true);
    setShow(false);
  };
  const onCloseClickHandler = () => {
    let tempFilterKey = getFiltersInfo() || {};
    if (globalFilterFor) {
      setStartDate(
        tempFilterKey?.[globalFilterFor]?.["fromDate"] || defaultStartDate
      );
      setEndDate(
        tempFilterKey?.[globalFilterFor]?.["toDate"] || defaultEndDate
      );
      setStatusSelectedOptions &&
        setStatusSelectedOptions(
          tempFilterKey?.[globalFilterFor]?.["status"] || []
        );
      setSubStatusSelectedOptions &&
        setSubStatusSelectedOptions(
          tempFilterKey?.[globalFilterFor]?.["subStatus"] || []
        );
      setSourceSelectedOptions &&
        setSourceSelectedOptions(
          tempFilterKey?.[globalFilterFor]?.["source"] || []
        );
      setSubSourceSelectedOptions &&
        setSubSourceSelectedOptions(
          tempFilterKey?.[globalFilterFor]?.["subSource"] || []
        );
      setTagSelectedOptions &&
        setTagSelectedOptions(
          tempFilterKey?.[globalFilterFor]?.["tagFilter"] || []
        );

      setRmsSelectedOptions &&
        setRmsSelectedOptions(tempFilterKey?.[globalFilterFor]?.["rm"] || []);
      setCPSelectedOptions &&
        setCPSelectedOptions(tempFilterKey?.[globalFilterFor]?.["cp"] || []);
      setPropertyTypeSelectedOptions &&
        setPropertyTypeSelectedOptions(
          tempFilterKey?.[globalFilterFor]?.["propertyType"] || []
        );
      setQualifyLeadSelectedOptions &&
        setQualifyLeadSelectedOptions(
          tempFilterKey?.[globalFilterFor]?.["qualifyLead"] || []
        );
      setLostReasonSelectedOptions &&
        setLostReasonSelectedOptions(
          tempFilterKey?.[globalFilterFor]?.["lostReason"] || []
        );
      setSelectedDateFilter &&
        setSelectedDateFilter(() =>
          tempFilterKey?.[globalFilterFor]?.["dateFilterType"]
            ? Math.max(
                dateFilterLabels.findIndex(el => {
                  let strToArr = tempFilterKey[globalFilterFor][
                    "dateFilterType"
                  ]
                    .toString()
                    ?.split("_");

                  return (
                    el ===
                    strToArr?.filter((el, i) => i < strToArr.length).join("_")
                  );
                }),
                0
              )
            : 0
        );

      setSelectedSubOptionDate &&
        setSelectedSubOptionDate(
          tempFilterKey?.[globalFilterFor]?.["dateLabel"] || ""
        );
    }
  };

  return (
    <>
      <div className="hidden md:block">
        <Modal
          show={show}
          setShow={setShow}
          noCloseBtn={true}
          xl={true}
          styleContainer={{
            maxHeight: "95vh",
            minHeight: "60vh",
            maxWidth: "700px",
            paddingTop: "10px",
            paddingBottom: "6px",
          }}
          onCloseClick={onCloseClickHandler}
          title="Filter"
          titleStyle={{ paddingLeft: "5px" }}
          clickOutsideClose={false}
        >
          <HorizontalDevider className="mb-4" />
          <div className="flex flex-col justify-between ">
            <div
              className="flex flex-col md:flex-row h-full w-full shadow"
              style={{
                minHeight: "40vh",
              }}
            >
              <div
                className="flex-shrink-0 overflow-auto"
                style={{ width: "150px", maxHeight: "53vh" }}
              >
                <ul>
                  {filterOptions.map(
                    (el, i) =>
                      el?.shouldShow && (
                        <li
                          key={i}
                          id={i}
                          onClick={() => {
                            setlabelChanged(!labelChanged);
                            setActiveDiv([i, el?.label]);
                          }}
                          className={
                            "w-full h-13 block flex flex-col items-start content-center justify-center pl-4 cursor-pointer" +
                            (activeDiv[0] === i ? " bg-blue-1251 " : "")
                          }
                        >
                          <div className="flex flex-row justify-start content-center items-center space-x-2 text-base">
                            <img
                              className="block"
                              src={el?.icons}
                              alt={`img${i}`}
                            />
                            <div
                              className={
                                activeDiv[0] === i ? " text-blue-1252 " : ""
                              }
                            >
                              {el?.label}
                            </div>
                          </div>
                        </li>
                      )
                  )}
                </ul>
              </div>
              <HorizontalDevider
                className="ml-2 mr-2"
                style={{
                  minHeight: "auto",
                  width: "2px",
                  bottom: "0px",
                  backgroudColor: "red",
                  borderLeft: "2px solid #DCE9F5",
                }}
              />
              {/* r.h.s filterSection */}
              <div className="flex flex-col space-y-2 w-full overflow-scroll">
                {activeDiv[1] !== "Date" && (
                  <h1 className="block">{`${activeDiv[1]} Filter`}</h1>
                )}
                <div className="flex w-full ">
                  <SelectedFilter name={activeDiv[1]} />
                </div>
              </div>
            </div>
            <div className="self-end flex flex-row w-48 ml-auto mr-3 content-end justify-end mt-3">
              <button
                className="block text-sm mx-auto  leading-none py-3 px-4 rounded mt-2  text-white h-10 w-22 bg-red-500"
                onClick={() => resetHandler(globalFilterFor)}
                id="dcrm_globalFilterResetBtn"
              >
                Reset
              </button>

              <button
                id="dcrm_globalFilterApplyBtn"
                onClick={() => applyHandler(globalFilterFor)}
                className="block text-sm mx-auto  leading-none py-3 px-4 rounded mt-2  text-white h-10 w-22 bg-teal "
              >
                Apply
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <div className="md:hidden">
        <MbBottomModal
          show={show}
          setShow={setShow}
          onCloseClick={onCloseClickHandler}
          styleContent={{
            paddingLeft: 0,
            paddingRight: 0,
            overflow: "auto",
            maxHeight: "600px",
          }}
        >
          <div
            style={{
              position: "relative",
              top: "-27px",
            }}
            className="text-black-1011 text-base font-roboto font-medium pl-6 w-32"
          >
            Filter
          </div>
          <div
            style={{
              borderBottom: "1px solid #DCE9F5",
              position: "relative",
              top: "-15px",
            }}
          ></div>
          {/* /////////////////// */}
          <div className="flex flex-col justify-between ">
            <div
              className="flex flex-col md:flex-row h-full w-full px-4"
              style={{
                minHeight: "40vh",
                paddingBottom: "70px",
              }}
            >
              <div
                className="flex-shrink-0"
                style={{
                  width: "100%",
                  maxHeight: "260px",
                  overflow: "auto",
                }}
              >
                <ul>
                  {filterOptions.map(
                    (el, i) =>
                      el?.shouldShow && (
                        <li
                          key={i}
                          id={i}
                          onClick={() => {
                            setlabelChanged(!labelChanged);
                            setActiveDiv([i, el?.label]);
                          }}
                          className={
                            "w-full h-13 block flex flex-col items-start content-center justify-center pl-4 cursor-pointer" +
                            (activeDiv[0] === i ? " bg-blue-1251 " : "")
                          }
                        >
                          <div className="flex flex-row justify-start content-center items-center space-x-2 text-base">
                            <img
                              className="block"
                              src={el?.icons}
                              alt={`img${i}`}
                            />
                            <div
                              className={
                                activeDiv[0] === i ? " text-blue-1252 " : ""
                              }
                            >
                              {el?.label}
                            </div>
                          </div>
                        </li>
                      )
                  )}
                </ul>
              </div>
              <HorizontalDevider
                className="ml-2 mr-2"
                style={{
                  minHeight: "auto",
                  width: "2px",
                  bottom: "0px",
                  backgroudColor: "red",
                  borderLeft: "2px solid #DCE9F5",
                }}
              />
              {/* r.h.s filterSection */}
              <div
                className="flex flex-col space-y-2 w-full overflow-scroll
              bg-grey-950 rounded  px-2 py-5"
              >
                {activeDiv[1] !== "Date" && (
                  <h1 className="block font-roboto font-medium text-base">{`${activeDiv[1]} Filter`}</h1>
                )}
                <div className="flex w-full ">
                  <SelectedFilter name={activeDiv[1]} />
                </div>
              </div>
            </div>
            <div
              style={{
                boxShadow: "0px 0 44px rgb(0 0 0 / 30%)",
                position: "fixed",
                bottom: "28px",
                zIndex: "100000",
              }}
              className="w-full bg-white mt-14 pt-5 pb-4 text-right"
            >
              <div className="self-end flex flex-row w-48 ml-auto mr-3 content-end justify-end">
                <button
                  className="block text-sm mx-auto  leading-none py-3 px-4 rounded mt-2  text-white h-10 w-22 bg-red-500"
                  onClick={() => resetHandler(globalFilterFor)}
                  id="dcrm_globalFilterResetBtn"
                >
                  Reset
                </button>

                <button
                  id="dcrm_globalFilterApplyBtn"
                  onClick={() => applyHandler(globalFilterFor)}
                  className="block text-sm mx-auto  leading-none py-3 px-4 rounded mt-2  text-white h-10 w-22 bg-teal "
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </MbBottomModal>
      </div>
    </>
  );
};

export default React.memo(GlobalFilter);
