import React, { useState, useEffect, Fragment } from "react";
import DataTable from "react-data-table-component";
import _ from "lodash";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import NewSpinner from "../../common/components/NewSpinner/NewSpinner";
import GetColumn from "./WalkInFormColumn";
import { getWalInResponse } from "../../common/constants/api";
import moment from "moment";
import { getApplicantionInfo } from "../../common/utils/applicantionInfo";
import CustomerFeedback from "./CustomerFeedback";
import { getSelectedOppId } from "./WalkInFormColumn";
import CustomMaterialPagination from "../../components/Table/customPagination";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Divider from "@material-ui/core/Divider";
import TableTopHeader from "../../components/Table/TableTopHeader";
import { getFiltersInfo } from "../../common/utils/FiltersInfo";
import Filter from "../../common/components/Filter/Filter";
import StoreFiltersInfo from "../../common/components/StoreFilterInfo/StoreFilterInfo";


const drawerWidth = 420;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },

  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: "100000",
    boxShadow: "0px 9px 33px #7693ab",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));
const WalkInFormResponse = () => {
  const url = new URL(window.location.href);
  const getStartDate = () =>
    url.searchParams.get("fromDate") || moment().format("YYYY-MM-DD");
  const getEndDate = () =>
    url.searchParams.get("toDate") || moment().format("YYYY-MM-DD");
  const getDateFilterType = () =>
    url.searchParams.get("dateFilterType") ||
    getFiltersInfo()?.callReport?.dateFilterType ||
    "";
  const getFormId = () => url.searchParams.get("formId") || "";
  const [rowsPerPage, setRowsPerPage] = useState({ size: 200 });
  const [tableLoader, setTableLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(200);
  const [pageInfo, setPageInfo] = useState("");
  const [Rows, setRows] = useState(200);
  const [sortCol, setSortCol] = useState("responseDate");
  const [sortDirection, setSortDirection] = useState("desc");
  const [startDate, setStartDate] = useState(getStartDate());
  const [endDate, setEndDate] = useState(getEndDate());
  const [walkInId, setWalkInId] = useState(getFormId());
  const [searchText, setSearchText] = useState("");
  const [user, setUser] = useState("");
  const formId = getApplicantionInfo()?.walkInFormId;
  const formName = getApplicantionInfo()?.formName;
  const createdBy = getApplicantionInfo()?.createdBy;
  const createdOn = moment(getApplicantionInfo()?.createdOn).format("lll");
  const totalResponse = getApplicantionInfo()?.totalResponse;
  const formType = getApplicantionInfo()?.formType;
  const projectName = getApplicantionInfo()?.projectName;
  const [showForm, setShowForm] = useState(false);
  const selectedOppId = getSelectedOppId();
  const [oppID, setOppID] = useState();
  const [feed, setFeed] = useState();
  const [submitedFeedback, setSubmitedFeedback] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [labelChanged, setlabelChanged] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [updateAppliedFilter, setUpdateAppliedFilter] = useState(false);
  const [dateFilterLabels, setDateFilterLabels] = useState([""]);
  const [dateFilterType, setDateFilterType] = useState(getDateFilterType());
  const [onApply, setOnApply] = useState(false);
  const [showAppliedFilters, setShowAppliedFilter] = useState(true);

  const [allAppliedFilters, setAllAppliedFilters] = useState(
    getFiltersInfo()["Walk-In Form Response"] || []
  );

  const [filterContextDataUpdate, setFilterContextDataUpdate] = useState(
    false
  );

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const customStyles = {
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  useEffect(() => {
    setTableLoader(true);
    getWalInResponse(
      walkInId,
      sortCol,
      sortDirection,
      pageSize,
      startDate,
      endDate,
      searchText,
      pageNo,
      user
    )
      .then(data => {
        setTableData(data?.data || []);
        setPageInfo(data?.dataPage);
        setTableLoader(false);
      })
      .catch(err => {
        setTableLoader(false);
        setTableData([]);
      });
  }, [
    updateAppliedFilter,
    applyFilter,
    walkInId,
    sortCol,
    sortDirection,
    pageSize,
    searchText,
    pageNo,
    user,
    submitedFeedback,
  ]);

  useEffect(() => {
    StoreFiltersInfo({
      globalFilterFor: "WalkInFormResponse",
      startDate: startDate,
      endDate: endDate,
      selectedSubOptionDate:  dateFilterLabels,
    });
  }, []);

  useEffect(() => {
    setStartDate(allAppliedFilters?.fromDate || moment().subtract(1, "months").format("YYYY-MM-DD"));
    setEndDate(allAppliedFilters?.toDate || moment().format("YYYY-MM-DD"));
    setUpdateAppliedFilter(!updateAppliedFilter);
  }, [allAppliedFilters]);

  const handlePerRowsChange = (e, _) => {
    setPageNo(0);
    setRowsPerPage(e);
    // setPageSize(_);
  };
  const handlePageChange = () => {};
  const handleSort = () => {};
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
      setFilterProductType && setFilterProductType("");
    };

    return (
      <TableTopHeader
        globalFilter
        globalFilterFor={"walkinResponse"}
        labelChanged={labelChanged}
        setlabelChanged={setlabelChanged}
        dateFilterLabels={dateFilterLabels}
        setDateFilterLabels={setDateFilterLabels}
        applyFilter={applyFilter}
        setApplyFilter={setApplyFilter}
        dateFilterType={dateFilterType}
        setDateFilterType={setDateFilterType}
        onApply={onApply}
        setOnApply={setOnApply}
        dontShowSearchBar
        dontShowAutoFilter={true}
        onClear={handleClear}
        getFiltersRawObject
        showDefaultDate
        groupNames={["Date", "DateType"]}
        filterArrays={[dateFilterType]}
        setFilterArrays={[setDateFilterType]}
        total_data={tableData?.length || 0}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        filterCaption={"Filter"}
        filterComponent
        filterImage
        rm
        showAppliedFilter={showAppliedFilters}
        setShowAppliedFilter={setShowAppliedFilter}
        updateAppliedFilter={updateAppliedFilter}
        setUpdateAppliedFilter={setUpdateAppliedFilter}
      />
    );
  }, [
    tableData,
    startDate,
    endDate,
    labelChanged,
    applyFilter,
    updateAppliedFilter,
  ]);

  return (
    <Fragment>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <p className="font-roboto text-xl text-base px-4 ">
            Customer Feedback form
          </p>
          <div className="ml-auto  cursor-pointer" onClick={handleDrawerClose}>
            <HighlightOffIcon style={{ color: "gray" }} />
          </div>
        </div>
        <Divider />
        <CustomerFeedback
          open={open}
          setOpen={setOpen}
          oppId={selectedOppId}
          formId={walkInId}
          tableData={tableData}
          setSubmitedFeedback={setSubmitedFeedback}
        />
      </Drawer>
      <div className="bg-white py-4 w-full min-h-full overflow-y-auto">
        <div className="flex flex-row flex-wrap mx-4  border rounded-md border-grey-1009">
          <div
            className={`flex flex-col xmd:flex-row place-items-center justify-center place-self-center xmd:place-self-start my-1 md:my-0
              md:mx-2`}
            style={{ witdh: "fit-content" }}
          >
            <div className="flex flex-col lg:mx-10 mx-6 my-4">
              <div className="text-grey-1003">Form ID</div>
              <div className="text-sm font-normal text-center text-black-500">
                {formId}
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col xmd:flex-row place-items-center justify-center place-self-center xmd:place-self-start my-1 md:my-0
              md:mx-2`}
            style={{ witdh: "fit-content" }}
          >
            <div className="flex flex-col lg:mx-10 mx-6 my-4">
              <div className="text-grey-1003">Form Name</div>
              <div className="text-sm font-normal text-center xmd:text-left text-black-500">
                {formName}
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col xmd:flex-row place-items-center justify-center place-self-center xmd:place-self-start my-1 md:my-0
              md:mx-2`}
            style={{ witdh: "fit-content" }}
          >
            <div className="flex flex-col lg:mx-10 mx-6 my-4">
              <div className="text-grey-1003">Created by</div>
              <div className="text-sm font-normal text-center xmd:text-left text-black-500">
                {createdBy}
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col xmd:flex-row place-items-center justify-center place-self-center xmd:place-self-start my-1 md:my-0
              md:mx-2`}
            style={{ witdh: "fit-content" }}
          >
            <div className="flex flex-col lg:mx-10 mx-6 my-4">
              <div className="text-grey-1003">Created On</div>
              <div className="text-sm font-normal text-center xmd:text-left text-black-500">
                {createdOn}
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col xmd:flex-row place-items-center justify-center place-self-center xmd:place-self-start my-1 md:my-0
              md:mx-2`}
            style={{ witdh: "fit-content" }}
          >
            <div className="flex flex-col lg:mx-10 mx-6 my-4">
              <div className="text-grey-1003">Total Responses</div>
              <div className="text-sm font-normal text-center  text-black-500">
                {totalResponse}
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col xmd:flex-row place-items-center justify-center place-self-center xmd:place-self-start my-1 md:my-0
              md:mx-2`}
            style={{ witdh: "fit-content" }}
          >
            <div className="flex flex-col lg:mx-10 mx-6 my-4">
              <div className="text-grey-1003">Form type</div>
              <div className="text-sm font-normal text-center xmd:text-left text-black-500">
                {formType?.value}
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col xmd:flex-row place-items-center justify-center place-self-center xmd:place-self-start my-1 md:my-0
              md:mx-2`}
            style={{ witdh: "fit-content" }}
          >
            <div className="flex flex-col lg:mx-10 mx-6 my-4">
              <div className="text-grey-1003">Project Name</div>
              <div className="text-sm font-normal text-center xmd:text-left text-black-500">
                {projectName}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`px-5 mx-auto my-5 border rounded-md border-grey-1009 scrBarFix max-w-full z-0`}
          style={{
            minHeight: "350px",
            height: "fit-content",
          }}
        >
          <Filter
            showAppliedFilter
            globalFilter
            globalFilterFor={"WalkInFormResponse"}
            showFilters={["Date"]}
            showSearch={[]}
            defaultStartDate={moment()
              .subtract(1, "months")
              .format("YYYY-MM-DD")}
            defaultEndDate={moment().format("YYYY-MM-DD")}
            allAppliedFilters={allAppliedFilters}
            setAllAppliedFilters={setAllAppliedFilters}
            updateAppliedFilter={updateAppliedFilter}
            setUpdateAppliedFilter={setUpdateAppliedFilter}
            onYourLeft={true}
            filterContextDataUpdate={filterContextDataUpdate}
          />
          <DataTable
            columns={GetColumn(setOppID, setOpen, setFeed)}
            data={tableData}
            customStyles={customStyles}
            pointerOnHover
            persistTableHead
            highlightOnHover
            ignoreRowClick={false}
            progressPending={tableLoader}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={
              <div className="py-8">
                <NewSpinner />
              </div>
            }
            paginationTotalRows={
              rowsPerPage.totalElements
                ? parseInt(rowsPerPage.totalElements)
                : 0
            }
            pagination
            paginationServer
            paginationComponent={() => (
              <CustomMaterialPagination
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                setRowsPerPage={setRowsPerPage}
                setPage={setPageNo}
                page={pageNo}
                pageInfo={pageInfo}
                setRows={setRows}
                Rows={Rows}
              />
            )}
            onSort={handleSort}
            sortIcon={<ArrowDownwardIcon />}
            fixedHeader
          />
        </div>
      </div>
    </Fragment>
  );
};

export default WalkInFormResponse;
