import React, { useState, memo } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { arraysHasElements } from "../../../common/constants/ArrayUtils";
import CommonAppliedFilters from "./CommonAppliedFilters";
import "./style.css";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: red[900],
    width: "100 %",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const CommonAppliedFilterGroupings = ({
  groupNames,
  filterArrays,
  globalFilterFor,
  setFilterArrays,
  updateAppliedFilter,
  setUpdateAppliedFilter,
  timeUpdaterAppliedFilter,
  setTimeUpdaterAppliedFilter,
  defaultStartDate,
  defaultEndDate,
  defaultFilterDate,
  showDefaultDate,
  showDefaultDateType,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dateSubOption,
  setDateSubOptions,
  dateFilterType,
  dateFilterLabels,
  selectedSubOptionDate,
  setSelectedSubOptionDate,
  clearAll,
  setClearAll,
  clearIndvFilter,
  setClearIndvFilter,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const hasElements = arraysHasElements(
    filterArrays.filter((el, i) => i !== 0 && i !== 1)
  );
  const timer = React.useRef();
  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      setClearAll(true);
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 500);
    }
  };

  const FilterHeader = () => (
    <div className="flex items-center">
      <h1 className="ml-1 text-teal capitalize font-khand font-medium text-lg  mt-1 fitWidth ">
        Applied filters
      </h1>
      <div className={classes.wrapper + " ml-6"}>
        <button type="reset" onClick={handleButtonClick} className="underline">
          Reset All
        </button>
        <div className="ml-14 block ">
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col space-y-2 mr-auto">
      <div className="flex flex-row flex-wrap justify-start items-center content-start mr-auto ml-1">
        {hasElements ||
        !defaultFilterDate ||
        (dateFilterLabels[0] !== "" &&
          filterArrays[1] !== "_DATE" &&
          filterArrays[1] !== dateFilterLabels[0]?.toUpperCase() + "_DATE") ||
        selectedSubOptionDate === "Custom" ? (
          <FilterHeader />
        ) : null}
      </div>
      <AvatarGroup max={4}>
        <div
          className="flex flex-row flex-wrap items-center justify-start w-full bg-transparent "
          style={{ maxWidth: "100%" }}
        >
          {filterArrays.map((el, i) => {
            return (Array.isArray(el) && el?.length > 0) ||
              (!Array.isArray(el) &&
                selectedSubOptionDate === "Custom" &&
                filterArrays[1] !== "_DATE") ||
              (i === 0 &&
                (showDefaultDateType ||
                  !defaultFilterDate ||
                  showDefaultDate)) ||
              (i === 1 && showDefaultDateType && filterArrays[1] !== "_DATE") ||
              (i === 1 &&
                dateFilterLabels[0] !== "" &&
                filterArrays[1] !==
                  dateFilterLabels[0]?.toUpperCase() + "_DATE" &&
                filterArrays[1] !== "_DATE") ||
              (Array.isArray(el) && el?.length > 0) ? (
              <React.Fragment key={i}>
                <CommonAppliedFilters
                  id={i}
                  dateFilterLabels={dateFilterLabels}
                  globalFilterFor={globalFilterFor}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  defaultStartDate={defaultStartDate}
                  defaultEndDate={defaultEndDate}
                  defaultFilterDate={defaultFilterDate}
                  updateAppliedFilter={updateAppliedFilter}
                  setUpdateAppliedFilter={setUpdateAppliedFilter}
                  timeUpdaterAppliedFilter={timeUpdaterAppliedFilter}
                  setTimeUpdaterAppliedFilter={setTimeUpdaterAppliedFilter}
                  groupName={groupNames[i]}
                  array={el}
                  setArray={setFilterArrays[i]}
                  setDateSubOptions={setDateSubOptions}
                  selectedSubOptionDate={selectedSubOptionDate}
                  setSelectedSubOptionDate={setSelectedSubOptionDate}
                  clearIndvFilter={clearIndvFilter}
                  setClearIndvFilter={setClearIndvFilter}
                />
              </React.Fragment>
            ) : null;
          })}
        </div>
      </AvatarGroup>
    </div>
  );
};

export default memo(CommonAppliedFilterGroupings);
