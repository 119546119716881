/* eslint-disable sno-use-before-define */
import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  getSubOrgRMList,
  getSource,
  getMasterSubStatusList,
  getMasterStatusList,
  getTagList,
  getProjectList,
  getCampaignList,
  getCPLists,
  getOpportunityLostReasonList,
  getQualityLeadStatusList,
} from "../../../common/constants/api";
import "./MultiSelectCheckbox.css";
import * as _ from "lodash";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckboxesTags = ({
  options,
  setOptions,
  selectedOptionsClone,
  setSelectedOptionsClone,
  labelKey,
  label,
  placeholder,
  style,
}) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    let active = true;

    const callMasterApi = async callingApi => {
      const response = await callingApi();
      if (active && response) {
        let tempArr1 = [];
        if (label !== "Choose Property Name") {
          response?.map((el, i) => {
            tempArr1.push({
              id:
                el["userId"] !== null && el["userId"] !== undefined
                  ? el["userId"]
                  : el["id"] !== null && el["id"] !== undefined
                  ? el["id"]
                  : el[labelKey],
            });
            tempArr1[i][labelKey] = el[labelKey];
          });
        } else {
          response?.map((el, i) => {
            tempArr1.push({
              id: el.id,
            });
            tempArr1[i][labelKey] = el.name + " - " + el.type;
          });
        }
        setOptions(tempArr1);
      }
    };
    let getMasterLists = {
      "Choose RM": () => callMasterApi(getSubOrgRMList),
      "Choose Source": () => callMasterApi(getSource),
      "Choose Status": () => callMasterApi(getMasterStatusList),
      "Choose Activity": () => callMasterApi(getMasterSubStatusList),
      "Choose Tag": () => callMasterApi(getTagList),
      "Choose Property Name": () => callMasterApi(getProjectList),
      "Choose Campaign": () => callMasterApi(getCampaignList),
      "Choose CP": () => callMasterApi(getCPLists),
      "Choose Qualify Lead": () => callMasterApi(getQualityLeadStatusList),
      "Choose Lost Reason": () => callMasterApi(getOpportunityLostReasonList),
    };
    if (Array.isArray(options) && options?.length === 0) {
      getMasterLists?.[label] && getMasterLists?.[label]();
    }
    return () => {
      active = false;
    };
  }, []);

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      disableCloseOnSelect
      defaultValue={() =>
        selectedOptionsClone?.length > 0
          ? [
              ...options.filter((el, i) =>
                selectedOptionsClone.some(
                  ({ id, _ }, i) =>
                    id ===
                    (el["userId"] !== null && el["userId"] !== undefined
                      ? el["userId"]
                      : el["id"] !== null && el["id"] !== undefined
                      ? el["id"]
                      : el[labelKey])
                )
              ),
            ]
          : []
      }
      onChange={(event, value) => {
        let tempArr1 = [];
        value.map((el, i) => {
          tempArr1.push({
            id:
              el["userId"] !== null && el["userId"] !== undefined
                ? el["userId"]
                : el["id"] !== null && el["id"] !== undefined
                ? el["id"]
                : el[labelKey],
            title: el[labelKey],
          });
        });
        setSelectedOptionsClone(tempArr1);
      }}
      getOptionLabel={option => option[labelKey] || []}
      renderOption={(option, { selected }) => {
        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[labelKey]}
          </React.Fragment>
        );
      }}
      style={
        style ? style : { width: "100%", zIndex: 12000, maxWidth: "600px" }
      }
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="outlined"
            label={label ? label : "Select RMs"}
            placeholder={placeholder ? placeholder : "All RMs"}
          />
        );
      }}
    />
  );
};
export default CheckboxesTags;

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
