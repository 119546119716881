import { setFiltersInfo, getFiltersInfo } from "../../utils/FiltersInfo";

export default function StoreFiltersInfo(props) {
  let tempFilterKey = getFiltersInfo() || {};
  tempFilterKey[props?.globalFilterFor] = {
    ...tempFilterKey[props?.globalFilterFor],
    ...(props?.statusSelectedOptions && {
      status: props?.statusSelectedOptions,
    }),
    ...(props?.subStatusSelectedOptions && {
      subStatus: props?.subStatusSelectedOptions,
    }),
    ...(props?.startDate && {
      fromDate: props?.startDate,
    }),
    ...(props?.endDate && {
      toDate: props?.endDate,
    }),
    ...(props?.selectedSubOptionDate && {
      dateLabel: props?.selectedSubOptionDate,
    }),
    ...(props?.dateType && {
      dateType: props?.dateType,
    }),
    ...(props?.tagSelectedOptions && {
      tagFilter: props?.tagSelectedOptions,
    }),
    ...(props?.propertyTypeSelectedOptions && {
      propertyType: props?.propertyTypeSelectedOptions,
    }),
    ...(props?.projectNames && {
      projectNames: props?.projectNames,
    }),
    ...(props?.filterCampaign && {
      campaign: props?.filterCampaign,
    }),
    ...(props?.dateFilterTypeOpp && {
      dateFilterType: props?.dateFilterTypeOpp,
    }),
    ...(props?.sourceSelectedOptions && {
      source: props?.sourceSelectedOptions,
    }),
    ...(props?.subSourceSelectedOptions && {
      subSource: props?.subSourceSelectedOptions,
    }),
    ...(props?.rmsSelectedOptions && {
      rm: props?.rmsSelectedOptions,
    }),
    ...(props?.rmsSelectedIdOptions && {
      rmId: props?.rmsSelectedIdOptions,
    }),
    ...(props?.cpSelectedOptions && {
      cp: props?.cpSelectedOptions,
    }),
    ...(props?.qualifyLeadSelectedOptions && {
      qualifyLead: props?.qualifyLeadSelectedOptions,
    }),
    ...(props?.lostReasonSelectedOptions && {
      lostReason: props?.lostReasonSelectedOptions,
    }),
    ...(props?.bpmFilter && {
      bpmStatus: props?.bpmFilter,
    }),
    ...(props?.bpmIdFilter && {
      bpmIdStatus: props?.bpmIdFilter,
    }),
    ...(props?.smStatus && {
      smStatus: props?.smStatus,
    }),
    ...(props?.smIdStatus && {
      smIdStatus: props?.smIdStatus,
    }),
    ...(props?.bpStatus && {
      bpStatus: props?.bpStatus,
    }),
    ...(props?.cpList && {
      cpTypeList: props?.cpList,
    }),
    ...(props?.statusList && {
      statusTypeList: props?.statusList,
    }),
    ...(props?.teamFilter && {
      teamFilter: props?.teamFilter,
    }),
    ...(props?.allLeads !== undefined && {
      allLeads: props?.allLeads,
    }),
    ...(props?.bpOpStatusSelected && {
      bpOpStatusSelected: props?.bpOpStatusSelected,
    }),
    ...(props?.formType && {
      formType: props?.formType,
    }),
    ...(props?.isActive && {
      isActive: props?.isActive,
    }),
    ...(props?.bpFilter && {
      bpFilter: props?.bpFilter,
    }),
    ...(props?.bpIdFilter && {
      bpIdFilter: props?.bpIdFilter,
    }),
  };
  setFiltersInfo(tempFilterKey);
}
