import React, { useEffect } from "react";
import styles from "../Modal/styles.module.css";

function MbBottomModal(props) {
  const {
    show,
    setShow,
    noCloseBtn = true,
    clickOutsideClose = true,
    styleContainer = {},
    styleContent = {},
    onCloseClick,
    titleStyle = {},
    title = false,
    buttonStyle={},
    newStyle=false,
  } = props;

  const { modal, mobile_modal, mobile_modal_new, animation, background_transparent } = styles;

  return (
    <div
      className={`${modal} fixed w-full h-full top-0 left-0 flex items-center justify-center md:hidden ${
        !show ? background_transparent : ""
      }`}
      onClick={e => {
        e.stopPropagation();
        if (clickOutsideClose) {
          setShow(false);
        }
      }}
    >
      <div
        className={`modal-container bg-white w-11/12 
         md:max-w-md
         mx-auto rounded shadow-lg  overflow-y-auto ${newStyle ? mobile_modal_new : mobile_modal} ${
          show ? animation : ""
        }`}
        style={styleContainer}
      >
        <div
          className={`modal-content pt-6 pb-8 ${!newStyle && "px-6"}`}
          onClick={e => {
            e.stopPropagation();
          }}
          style={{
            ...styleContent,
          }}
        >
          {/*Title*/}
          {noCloseBtn && (
            <div className={`flex justify-end ${!newStyle ? "pr-6 pb-3" : "px-6"}`}>
              {title ? (
                <span
                  className="mr-auto float-left text-black-1011 font-roboto font-medium text-base"
                  style={titleStyle}
                >
                  {title}
                </span>
              ) : null}
              <div
                className="modal-close cursor-pointer "
                onClick={async e => {
                  onCloseClick && (await onCloseClick());
                  setShow(false);
                }}
              >
                <svg
                  className="fill-current text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                >
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                </svg>
              </div>
            </div>
          )}

          <div className={`${buttonStyle}`}>{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default MbBottomModal;
