import React, { useEffect, useRef, useState } from "react";
import AccountCircle from "../../../images/consumer/account_circle.svg";
import ApartmentIcon from "../../../images/consumer/apartment_FILL.svg";
import MyPropertyIcon from "../../../images/consumer/My_Property.svg";
import MoreHorizontalIcon from "../../../images/consumer/more_horiz.svg";
import opportunity from "../../../images/MenuButton/Opportunity.svg";
import { navigate } from "gatsby-link";
import midBar from "../../../images/MenuButton/buttons-fab_button.svg";
import SvgComponent from "../../../common/components/SvgComponent/SvgComponent";
import { getUserType } from "../../../common/utils/cookie-utils";
import CommonDownloadApp from "../../../containers/Consumer/CommonDownloadApp";
import dashboard from "../../../images/MenuButton/dashboard_new.svg";
import dashboardActive from "../../../images/MenuButton/dashboard-active.svg";
import assets from "../../../images/MenuButton/assets.svg";
import assetsActive from "../../../images/MenuButton/assets-active.svg";
import lead from "../../../images/MenuButton/lead.svg";
import leadActive from "../../../images/MenuButton/lead-active.svg";
import more from "../../../images/MenuButton/more_new.svg";
import moreActive from "../../../images/MenuButton/more-active.svg";
import addIcon from "../../../images/MenuButton/add-icon.svg";
import useWindowResize from "../../../hooks/useWindowResize";
import splashAssets from "../../../images/splashScreen/splash_assets.svg";
import assetsWhite from "../../../images/MenuButton/assets_white.svg";
import {
  getTutorialCompleted,
  updateTutorialCompleted,
} from "../../constants/api";
import { getUserDetailsInfo } from "../../utils/userDetailsInfo";

const ButtonMenu = ({ newBp, setNewBp }) => {
  const url = new URL(window.location.href);
  let isPathName = url?.pathname;
  const [active, setActive] = useState(isPathName || "/app/dashboard/");
  const [icon, setIcon] = useState();
  let hideBottomMenu = [
    "/app/addOpportunity",
    "/app/opportunity/details",
    "/app/chooseProject",
    "/app/create-new-rule",
  ];
  let urlTo =
    url.pathname.match("/app/opportunity/details") != null
      ? url.pathname.substring(0, url.pathname.lastIndexOf("/"))
      : url.pathname;
  let userType = getUserType();

  const userInfo = getUserDetailsInfo() || "";

  const [width, height] = useWindowResize();

  const moreRef = useRef(null);
  const assetsRef = useRef(null);
  const os = localStorage.getItem("os");

  return (
    <div>
      {!hideBottomMenu.includes(urlTo) && (
        <div
          className="w-full flex flex-col fixed bottom-0 md:hidden block"
          style={{
            height: userType == 3 ? "110px" : "64px",
          }}
        >
          {userType == 3 ? <CommonDownloadApp /> : ""}
          <div className="z-50 md:hidden w-full h-16 shadow-md bg-white fixed bottom-0 flex flex-row block border-2 border-grey-200 justify-center flex-nowrap align-middle items-end">
            {userType != 3 && (
              <button
                className="outline-none h-12 text-xs w-2/6 font-extralignt flex flex-col flex-nowrap justify-around items-stretch"
                style={{ transform: os == "Ios" ? "translateY(-6px)" : "" }}
                onClick={() => {
                  if (userType == 1) {
                    setActive("/app/dashboard/bp/");
                    navigate("/app/dashboard/bp/");
                  } else {
                    setActive("/app/dashboard/");
                    navigate("/app/dashboard/");
                  }
                }}
              >
                <center>
                  {/* <SvgComponent
                    img={dashboard}
                    style={{
                      color: active.includes("/app/dashboard")
                        ? "#0a4549"
                        : "#FF614D",
                    }}
                  /> */}
                  <img
                    src={
                      !active.includes("/app/dashboard")
                        ? dashboard
                        : dashboardActive
                    }
                  ></img>
                </center>
                <span
                  className={
                    active.includes("/app/dashboard")
                      ? "text-red-1200 font-semibold"
                      : "text-grey-2450"
                  }
                >
                  Dashboard
                </span>{" "}
              </button>
            )}
            {userType != 3 && (
              <button
                className="outline-none h-12 text-xs w-2/5 font-extralignt flex flex-col flex-nowrap justify-around items-stretch"
                style={{ transform: os == "Ios" ? "translateY(-6px)" : "" }}
                onClick={() => {
                  setActive("/app/opportunity");
                  navigate("/app/opportunity");
                }}
              >
                <center>
                  {/* <SvgComponent
                    img={lead}
                    style={{
                      color: active.includes("/app/opportunity")
                        ? "#0a4549"
                        : "black",
                    }}
                  /> */}
                  <img
                    src={
                      !active.includes("/app/opportunity") ? lead : leadActive
                    }
                  ></img>
                </center>
                <span
                  className={
                    active.includes("/app/opportunity")
                      ? "text-red-1200 font-semibold"
                      : "text-grey-2450"
                  }
                >
                  Leads
                </span>{" "}
              </button>
            )}
            {userType != 2 && userType != 3 ? (
              <button
                className="flex justify-center outline-none h-25 w-20"
                onClick={() => {
                  setActive("/app/chooseProject");

                  navigate("/app/chooseProject");
                }}
              >
                <SvgComponent img={addIcon} style={{ color: "#E4B027" }} />
              </button>
            ) : (
              ""
            )}
            {userType != 3 && userType != 0 && (
              <button
                className="outline-none relative h-12 text-xs w-2/5 font-extralignt flex flex-col flex-nowrap justify-around items-stretch"
                onClick={() => {
                  if (!newBp) {
                    setActive("/app/content-share/");
                    navigate("/app/content-share/");
                  } else {
                    setNewBp(false);
                  }
                }}
                ref={assetsRef}
              >
                <center style={{ zIndex: 99999 }}>
                  {/* <SvgComponent
                    img={assets}
                    style={{
                      color: active.includes("/app/reports")
                        ? "#0a4549"
                        : "black",
                    }}
                  /> */}
                  <img
                    src={
                      !active.includes("/app/content-share")
                        ? newBp
                          ? assetsWhite
                          : assets
                        : assetsActive
                    }
                  ></img>
                </center>
                <span
                  className={
                    active.includes("/app/content-share")
                      ? "text-red-1200 font-semibold"
                      : !newBp
                      ? "text-grey-2450"
                      : "text-white"
                  }
                  style={{ zIndex: newBp && 9999 }}
                >
                  Assets
                </span>{" "}
                {newBp && (
                  <div
                    className="absolute"
                    style={{
                      width: width,
                      height: height,
                      right: -(moreRef?.current?.clientWidth + 2),
                      bottom: "-2px",
                      background: "rgba(0, 0, 0, 0.60)",
                      zIndex: "0",
                      transition: "opacity 1s linear",
                    }}
                    onClick={() => {
                      updateTutorialCompleted()
                        .catch(res => {})
                        .then(err => {});
                    }}
                  >
                    <div
                      className="absolute right-0"
                      style={{
                        width: "300px",
                        height: "300px",
                        backgroundColor: "#427EE4",
                        borderRadius: "300px",
                        bottom: "-7%",
                      }}
                    >
                      <div
                        className="flex flex-col items-start"
                        style={{
                          width: "70%",
                          marginTop: "20%",
                          marginLeft: "13%",
                        }}
                      >
                        <p className="text-white text-base font-bold">Assets</p>
                        <p className="text-white text-base font-medium whitespace-normal text-left">
                          Find & share all projects collaterals from here..
                        </p>
                      </div>
                      <img
                        className="absolute"
                        style={{
                          right:
                            moreRef?.current?.clientWidth +
                            assetsRef?.current?.clientWidth / 2 -
                            70,
                          bottom: "6%",
                        }}
                        src={splashAssets}
                        width="140px"
                        height="140px"
                      ></img>
                    </div>
                  </div>
                )}
              </button>
            )}
            {userType == 0 && (
              <button
                className="outline-none h-12 text-xs w-2/5 font-extralignt flex flex-col flex-nowrap justify-around items-stretch"
                style={{ transform: os == "Ios" ? "translateY(-6px)" : "" }}
                onClick={() => {
                  setActive("/app/content-share/");
                  navigate("/app/content-share/");
                }}
              >
                <center>
                  {/* <SvgComponent
                    img={assets}
                    style={{
                      color: active.includes("/app/reports")
                        ? "#0a4549"
                        : "black",
                    }}
                  /> */}
                  <img
                    src={
                      !active.includes("/app/content-share")
                        ? assets
                        : assetsActive
                    }
                  ></img>
                </center>
                <span
                  className={
                    active.includes("/app/content-share")
                      ? "text-red-1200 font-semibold"
                      : "text-grey-2450"
                  }
                >
                  Assets
                </span>{" "}
              </button>
            )}
            {userType == 3 && (
              <button
                className="outline-none h-12 text-xs w-2/5 font-extralignt flex flex-col flex-nowrap justify-around items-stretch"
                onClick={() => {
                  setActive("/app/Consumer/Properties");
                  navigate("/app/Consumer/Properties");
                }}
              >
                <center>
                  <SvgComponent
                    img={ApartmentIcon}
                    style={{
                      color: active.includes("/app/Consumer/Properties")
                        ? "#0a4549"
                        : "black",
                    }}
                  />
                </center>
                <span
                  className={
                    active.includes("/app/Consumer/Properties")
                      ? "text-red-1200 font-semibold"
                      : "text-grey-2450"
                  }
                >
                  Properties
                </span>{" "}
              </button>
            )}
            {userType == 3 && (
              <button
                className="outline-none h-12 text-xs w-2/5 font-extralignt flex flex-col flex-nowrap justify-around items-stretch"
                onClick={() => {
                  setActive("/app/Consumer/MyProperties");
                  navigate("/app/Consumer/MyProperties");
                }}
              >
                <center>
                  <SvgComponent
                    img={MyPropertyIcon}
                    style={{
                      color: active.includes("/app/Consumer/MyProperties")
                        ? "#0a4549"
                        : "black",
                    }}
                  />
                </center>
                <span
                  className={
                    active.includes("/app/Consumer/MyProperties")
                      ? "text-red-1200 font-semibold"
                      : "text-grey-2450"
                  }
                >
                  My Properties
                </span>{" "}
              </button>
            )}
            {userType == 3 && (
              <button
                className="outline-none h-12 text-xs w-2/5 font-extralignt flex flex-col flex-nowrap justify-around items-stretch"
                onClick={() => {
                  setActive("/app/Consumer/Profile");
                  navigate("/app/Consumer/Profile");
                }}
              >
                <center>
                  <SvgComponent
                    img={AccountCircle}
                    style={{
                      color: active.includes("/app/Consumer/Profile")
                        ? "#0a4549"
                        : "black",
                    }}
                  />
                </center>
                <span
                  className={
                    active.includes("/app/Consumer/Profile")
                      ? "text-red-1200 font-semibold"
                      : "text-grey-2450"
                  }
                >
                  Profile
                </span>{" "}
              </button>
            )}
            {userType == 3 && (
              <button
                className="outline-none h-12 text-xs w-2/6 font-extralignt flex flex-col flex-nowrap justify-around items-stretch"
                onClick={() => {
                  setActive("/app/more/");
                  navigate("/app/more/");
                }}
              >
                <center>
                  <SvgComponent
                    img={MoreHorizontalIcon}
                    style={{
                      color: active.includes("/app/more") ? "#0a4549" : "black",
                    }}
                  />
                </center>
                <span
                  className={
                    active.includes("/app/more")
                      ? "text-red-1200 font-semibold"
                      : "text-grey-2450"
                  }
                >
                  More
                </span>{" "}
              </button>
            )}
            {userType != 3 && (
              <button
                className="outline-none h-12 text-xs w-2/6 font-extralignt flex flex-col flex-nowrap justify-around items-stretch"
                onClick={() => {
                  setActive("/app/more/");
                  navigate("/app/more/");
                }}
                ref={moreRef}
              >
                <center>
                  {/* <SvgComponent
                    img={more}
                    style={{
                      color: active.includes("/app/more") ? "#0a4549" : "black",
                    }}
                  /> */}
                  <img
                    src={!active.includes("/app/more") ? more : moreActive}
                  ></img>
                </center>
                <span
                  className={
                    active.includes("/app/more")
                      ? "text-red-1200 font-semibold"
                      : "text-grey-2450"
                  }
                  style={{ transform: os == "Ios" ? "translateY(-6px)" : "" }}
                >
                  More
                </span>{" "}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonMenu;
