import React, { Suspense, useEffect, useState } from "react";
import { Router, Link, Location } from "@reach/router";
import { navigate } from "gatsby";
import Layout from "../components/Layout/Layout";
import Sidebar from "../common/components/Sidebar/Sidebar";
import ErrorBoundary from "../hoc/ErrorBoundary/ErrorBoundary";
import CircularLoading from "../common/components/CircularLoading/CircularLoading";
import Header from "../common/components/Header/Header";
import MobileHeader from "../common/components/Header/MobileHeader";
import MenuButton from "../common/components/MobileMenuButton/MenuButton";
import {
  isLoggedIn,
  getUserNumber,
  getIsApproved,
} from "../common/utils/cookie-utils";
import UpdateButton from "../common/components/updateButton/UpdateButton";
import { DeveloperDataContext } from "../common/utils/appContext";
import {
  enableCreateWalkIn,
  enableBP,
  enableWalkinReport,
  enableSettings,
  enableMissCallReport,
  enableFollowUpReport,
  enableDeveloperAndProject,
  enableBpLeadQualityReport,
  enableReport,
  enableOrganisationSetting,
  enableCampaignReport,
  enableContentManagementSetting,
  enableWVR,
  enablePerformaceReport,
  enableNewBPProfile,
  enableInboundCallReport,
  enableBPNewDashboard,
  enableCampaignReportComponent,
  enableCampaignSetting,
  enableBucket,
  enableBucketComponent,
  enableMarketing,
  enableMarketingComponent,
  enableMarketingMain,
  enableInventoryModule,
  enableNewFollowUpDashboard,
  enableOrganizationStructure,
  enableBookingForm,
  enableMarketingReportComponent,
  enableHordingCallReportComponent,
} from "gatsby-env-variables";
import WalkInFormResponse from "../containers/WalkInFormResponse/WalkInFormResponse";

const genericDashboard = React.lazy(() =>
  import("../LazyNamedExport/DefaultDashBoard")
);
const opportunityDashboard = React.lazy(() =>
  import("../containers/AllOpportunity/AllOpportunity")
);
const oppDetails = React.lazy(() =>
  import("../containers/AllOpportunity/OppDetails")
);
const comingSoonPage = React.lazy(() =>
  import("../LazyNamedExport/ComingSoonPage")
);

const Logout = React.lazy(() => import("../common/components/Logout/Logout"));
const PrivacyPolicy = React.lazy(() =>
  import("../common/components/Privacy Policy/PrivacyPolicy")
);
const ProfileView = React.lazy(() =>
  import("../containers/AllAccounts/ProfileView/ProfileView")
);
const EditProfile = React.lazy(() =>
  import("../components/EditProfile/EditProfile")
);
const EditProfileMb = React.lazy(() =>
  import("../components/EditProfile/MbEditProfile")
);

const AllAccounts = React.lazy(() =>
  import("../containers/AllAccounts/Dashboard/AllAccounts")
);
const AddAccount = React.lazy(() =>
  import("../components/AddAccount/MbAddAccount")
);
const WalkInDashboard = React.lazy(() =>
  import("../containers/WalkIn/Dashboard/WalkInDashboard")
);
const BPDashboard = React.lazy(() =>
  import("../containers/BP/Dashboard/BPDashboard")
);
const BPNewDashboard = React.lazy(() =>
  import("../containers/BP/NewDashboard/BPNewDashboard")
);
const IFAListDashboard = React.lazy(() =>
  import("../containers/IFA/IFAList/IFAList")
);
const PerformanceReport = React.lazy(() =>
  import("../containers/PerformanceReport/PerformanceReport")
);
const WeekendVisibilityReport = React.lazy(() =>
  import(
    "../containers/Reports/WeekendVisibilityReport/WeekendVisibilityReport"
  )
);
const CallReport = React.lazy(() =>
  import("../containers/Reports/CallReport/CallReport")
);
const LeadBucketReport = React.lazy(() =>
  import("../containers/Reports/LeadBucketReport/LeadBucketReport")
);
const BPLeadQualityReport = React.lazy(() =>
  import("../containers/Reports/BPLeadQualityReport/BPLeadQualityReport")
);

const LostReport = React.lazy(() =>
  import("../containers/Reports/LostReport/LostReport")
);

const formSteps = React.lazy(() =>
  import("../containers/WalkIn/CustomerForm/CustomerFormSteps")
);
const FollowUpDashboard = React.lazy(() =>
  import("../containers/FollowUp/FollowUpDashboard/FollowUpDashboard")
);
const BPFollowUpDashboard = React.lazy(() =>
  import("../containers/FollowUp/BPFollowUpDashboard/BPFollowUpDashboard")
);
const WalkInReport = React.lazy(() =>
  import("../containers/Reports/WalkInReport/WalkInReport")
);
const BPProfileView = React.lazy(() =>
  import("../containers/BP/BPProfileView/BPProfileView")
);
const NewBPProfileView = React.lazy(() =>
  import("../containers/BP/BPProfileView/NewBPProfileView")
);
const IFAProfile = React.lazy(() =>
  import("../containers/IFA/IFAProfile/IFAProfile")
);
const BpAttendenceFlow = React.lazy(() =>
  import("../containers/BpAttendenceFlow/BpAttendenceFlow")
);
const LeadFlowManagement = React.lazy(() =>
  import("../containers/Settings/LeadFlowManagement")
);
const DefaultRules = React.lazy(() =>
  import("../containers/Settings/DefaultRules")
);
const AddRmForProject = React.lazy(() =>
  import("../containers/Settings/AddRmForProject/AddRmForProject")
);

const marketingReport = React.lazy(() =>
  import("../containers/Reports/MarketingReport/MarketingReport")
);
const hoardingCallReport = React.lazy(() =>
  import("../containers/Reports/HoardingCallReport/HoardingCallReport")
);
const Settings = React.lazy(() => import("../containers/Settings/Settings"));
const ChangePassword = React.lazy(() => import("../LazyNamedExport/Settings"));
const More = React.lazy(() =>
  import("../common/components/MobileMenuButton/Settings/setting")
);
const ViewLeadFlowRM = React.lazy(() =>
  import("../containers/Settings/ViewLeadFlowRM")
);
const MissCallReport = React.lazy(() =>
  import("../containers/Reports/MissCallReport/MissCallReport")
);
const EmployeeAttendanceDetails = React.lazy(() =>
  import("../containers/Settings/EmployeeAttendance/EmployeeAttendanceDetails")
);
const InboundCallReport = React.lazy(() =>
  import("../containers/Reports/InboundCallReport/InboundCallReport")
);
const FollowUpReport = React.lazy(() =>
  import("../containers/Reports/FollowUpReport/FollowUpReport")
);
const DuplicateEventReport = React.lazy(() =>
  import("../containers/Reports/DuplicateEventReport/DuplicateEventReport")
);
const CampaignReport = React.lazy(() =>
  import("../containers/Reports/CampaignReport/CampaignReport")
);
const DeveloperProject = React.lazy(() =>
  import(
    "../containers/Settings/ProjectManagement/DeveloperProject/DeveloperProject"
  )
);
const ProjectOverviewDash = React.lazy(() =>
  import(
    "../containers/Settings/ProjectManagement/ProjectOverview/ProjectOverviewDash"
  )
);
const DeveloperDashboard = React.lazy(() =>
  import(
    "../containers/Settings/ProjectManagement/Developers/DeveloperDashboard"
  )
);
const PhaseDetails = React.lazy(() =>
  import("../containers/Settings/ProjectManagement/PhaseDetails/PhaseDetails")
);
const AllUnits = React.lazy(() =>
  import("../containers/Settings/ProjectManagement/UnitDetails/AllUnits")
);

const EditUnitDetailsDash = React.lazy(() =>
  import(
    "../containers/Settings/ProjectManagement/EditUnitDetails/EditUnitDetailsDash"
  )
);

const AddActivity = React.lazy(() =>
  import("../components/AddActivity/AddActivityMobile")
);

const MbAddOpportunity = React.lazy(() =>
  import("../components/AddOpportunity/MbAddOpportunity")
);

const MbEditOpportunity = React.lazy(() =>
  import("../components/AddOpportunity/MbEditOpportunity")
);
const MaintenancePage = React.lazy(() =>
  import("../components/MaintenancePage/MaintenancePage")
);
const MbAccountDetails = React.lazy(() =>
  import(
    "../containers/AllAccounts/AccountDetailsPageMobileView/MobileAccountDetailsPage"
  )
);

const OrganisationSetting = React.lazy(() =>
  import("../containers/Settings/OrganisationSetting/OrganisationSetting")
);

const ProfileDetails = React.lazy(() =>
  import("../containers/Settings/OrganisationSetting/ProfileDetails")
);

const OppGeoLocation = React.lazy(() =>
  import("../containers/Reports/OppGeoLocation/OppGeoLocation")
);

const reportHomePage = React.lazy(() => import("../containers/Reports"));

const contentManagementSetting = React.lazy(() =>
  import(
    "../containers/Settings/contentManagementSystem/contentManagementSetting"
  )
);
const ProjectContent = React.lazy(() =>
  import("../containers/Settings/contentManagementSystem/ProjectContent")
);
const CampaignSetting = React.lazy(() =>
  import("../containers/Settings/CampaignSetting/CampaignSetting")
);

const FacebookAdSetsList = React.lazy(() =>
  import(
    "../containers/Settings/CampaignSetting/FbCampaignSetting/FbAdSetsList"
  )
);

const FacebookAdsList = React.lazy(() =>
  import("../containers/Settings/CampaignSetting/FbCampaignSetting/FbAdsList")
);

const Bucket = React.lazy(() => import("../containers/Buckets/Bucket"));
const CampaignDetails = React.lazy(() =>
  import("../containers/Settings/CampaignSetting/CampaignDetails")
);

const AddBucket = React.lazy(() =>
  import("../containers/Buckets/AddBucket/AddBucket")
);

const MarketingSideBar = React.lazy(() =>
  import("../containers/Marketing/Marketing")
);
const History = React.lazy(() =>
  import("../containers/Marketing/History/History")
);
const campaignReport = React.lazy(() =>
  import("../containers/CampaignReport/CampaignReport")
);
const Marketing = React.lazy(() =>
  import("../containers/Settings/Marketing/Marketing")
);
const CampaignInsights = React.lazy(() =>
  import("../containers/Marketing/CampaignInsights/CampaignInsights")
);
const AddTemplate = React.lazy(() =>
  import("../containers/Settings/Marketing/AddTemplate")
);
const InventoryModule = React.lazy(() =>
  import("../containers/Settings/InventoryModule/ProjectList")
);

const InventoryFilter = React.lazy(() =>
  import(
    "../containers/Settings/InventoryModule/InventoryFilter/InventoryFilter"
  )
);
const InventoryFilterCommercial = React.lazy(() =>
  import(
    "../containers/Settings/InventoryModule/InventoryFilter/InventoryFilterCommercial"
  )
);
const InventoryCostSheet = React.lazy(() =>
  import("../containers/Settings/InventoryModule/CostSheet/CostSheet")
);
const InventoryCostSheetCommercial = React.lazy(() =>
  import("../containers/Settings/InventoryModule/CostSheetCommercial/CostSheet")
);
const TemplateSettings = React.lazy(() =>
  import("../containers/Settings/TemplateSettings/TemplateSettings")
);
const InventoryList = React.lazy(() =>
  import("../containers/Settings/BookingFormTemplate/ProjectList")
);
const BookingFormTemplateList = React.lazy(() =>
  import("../containers/Settings/BookingFormTemplate/BookingFormTemplateList")
);
const BookingFormTemplate = React.lazy(() =>
  import("../containers/Settings/BookingFormTemplate/BookingFormTemplate")
);
const BPTnc = React.lazy(() =>
  import("../containers/Settings/BusinessPartnerTnC/BusinessPartnerTnc")
);
const CostSheetTemplate = React.lazy(() =>
  import("../containers/Settings/CostSheetTemplate/CostSheetTemplate")
);
const BookingForm = React.lazy(() =>
  import("../containers/Settings/ProjectManagement/BookingForm/BookingForm")
);

const BookingFormCommercial = React.lazy(() =>
  import(
    "../containers/Settings/ProjectManagement/BookingFormCommercial/BookingFormCommercial"
  )
);

const ChooseProject = React.lazy(() =>
  import("../components/AddOpportunity/ChooseProject")
);

const UserProfile = React.lazy(() =>
  import("../containers/UserProfile/UserProfile")
);

const BPProfile = React.lazy(() =>
  import("../containers/UserProfile/NewBPProfile/UserProfile")
);
const TermsAndConditions = React.lazy(() =>
  import("../containers/BP/BPMbMoreMenu/TermsAndConditions/TermsAndConditions")
);

const FincityContact = React.lazy(() =>
  import("../containers/BP/BPMbMoreMenu/FincityContact/FincityContact")
);

const CheckOutFlow = React.lazy(() =>
  import("../containers/BpAttendenceFlow/CheckOutFlow/CheckOutFlow")
);

const AttendanceLog = React.lazy(() =>
  import("../containers/BpAttendenceFlow/AttendanceLog/AttendanceLog")
);

const Teammates = React.lazy(() =>
  import("../containers/BP/BPMbMoreMenu/Teammates/Teammates")
);

const PrivateRoute = React.lazy(() =>
  import("../common/PrivateRoute/PrivateRoute")
);

const NewFollowUpDashboard = React.lazy(() =>
  import("../containers/NewFolllowUpDashboard/FollowUpDashboard")
);
const LeadExpiration = React.lazy(() =>
  import("../containers/Settings/LeadExpiration/LeadExpiration")
);
const OrganizationModal = React.lazy(() =>
  import("../containers/Settings/OrganisationModal/OrganizationModal")
);
const ReleaseNotes = React.lazy(() =>
  import("../containers/Settings/ReleaseNotes/ReleaseNotes")
);
const AnnouncementList = React.lazy(() =>
  import("../containers/Settings/ReleaseNotes/Announcements")
);
const ConsumerProfile = React.lazy(() =>
  import(
    "../containers/Consumer/ConsumerProfile/NewConsumerProfile/NewConsumerProfile"
  )
);
const Properties = React.lazy(() =>
  import("../containers/Consumer/Properties/Properties")
);
const CommercialPropertyInterestedFrom = React.lazy(() =>
  import("../containers/Consumer/Properties/MbCommercialInterestedButton")
);
const ResidentialPropertyInterestedFrom = React.lazy(() =>
  import("../containers/Consumer/Properties/MbResidentialInterestedButton")
);
const MyProperties = React.lazy(() =>
  import("../containers/Consumer/MyProperties/MyProperties")
);
const MyPropertyDocsDetialPage = React.lazy(() =>
  import(
    "../containers/Consumer/MyProperties/BookedMyPropertyDetailPage/BookedMyPropertyDetailPage"
  )
);
const ResidentialPropertyDetail = React.lazy(() =>
  import(
    "../containers/Consumer/Properties/ResidentialPropertyDetail/ResidentialPropertyDetail"
  )
);
const CommercialPropertyDetails = React.lazy(() =>
  import(
    "../containers/Consumer/Properties/PropertyDetail/CommercialPropertyDetailPage"
  )
);
const ConsumerAboutUs = React.lazy(() =>
  import("../containers/Consumer/MoreOptions/AboutUs/ConsumerAboutUs")
);
const ConsumerContactUs = React.lazy(() =>
  import("../containers/Consumer/MoreOptions/ContactUs/ConsumerContactUs")
);
const LeadConflictManagement = React.lazy(() =>
  import("../containers/Settings/LeadConflictManagement/LeadConflictManagement")
);
const EmployeeAttendance = React.lazy(() =>
  import("../containers/Settings/EmployeeAttendance/EmployeeAttendanceList")
);
const EditRulesPage = React.lazy(() =>
  import("../containers/Settings/LeadConflictManagement/EditRulesPage")
);
const CheckInFlow = React.lazy(() =>
  import("../containers/BpAttendenceFlow/CheckInFlow/CheckInFLow")
);
const ProjectRulePage = React.lazy(() =>
  import("../containers/Settings/LeadConflictManagement/ProjectRulesPage")
);

import {
  webSocketConnection,
  isAliveWebSocket,
} from "../common/webSocket/WebSocket";
import { socketData } from "../common/webSocket/webSocketData";
import NotificationDataContext from "../common/Context/NotificationDataContext";
import useWindowResize from "../hooks/useWindowResize";
import { getUserDetailsInfo } from "../common/utils/userDetailsInfo";
import { getCurrentUserDetails } from "../common/constants/api";
import ComingSoonPage from "../components/ComingSoonPage/ComingSoonPage";

const socketMessageList = {};

export const setSocketMessage = data => {
  if (data?.messageType === "applicant_whatsapp_notification") {
    socketMessageList["wa_notification"] = [
      ...(socketMessageList?.wa_notification || []),
      data,
    ];
  } else if (data?.messageType === "applicant_calling_notification") {
    socketMessageList["call_notification"] = [
      ...(socketMessageList?.call_notification || []),
      data,
    ];
  } else if (data?.messageType === "unread_notification_count") {
    socketMessageList["count_notification"] = data;
  } else if (data?.messageType === "opp_created") {
    socketMessageList["lead_notification"] = [
      ...(socketMessageList?.lead_notification || []),
      data,
    ];
  }
  try {
    socketMessageList?.hooksData?.setSocketMessages({ ...socketMessageList });
  } catch {}
};

const App = ({ location }) => {
  const [isFront, setIsFront] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(isLoggedIn());
  const [globalLevelCall, setGlobalCall] = useState(true);
  const [socketMessages, setSocketMessages] = useState({});
  const [heading, setHeading] = useState("");
  const [width, height] = useWindowResize();
  const isApproved = getIsApproved();
  const [usersName, setUsername] = useState("");
  const [newBp, setNewBp] = useState(false);

  useEffect(() => {
    if (!isApproved)
      getCurrentUserDetails().then(res => {
        setUsername(`${res?.firstName} ${res?.lastName ? res?.lastName : ""}`);
      });
  }, []);

  useEffect(() => {
    async function time() {
      let res = await getUserDetailsInfo();
      if (res?.checks?.isTutorialCompleted == false) {
        setNewBp(true);
      }
    }
    time();
  }, []);

  useEffect(() => {
    socketMessageList["hooksData"] = {
      socketMessages: socketMessages,
      setSocketMessages: setSocketMessages,
    };
    return () => {
      socketMessageList.length = 0;
    };
  }, []);
  if (isAuth && globalLevelCall && !isAliveWebSocket()) {
    setGlobalCall(false);
    let socket = new webSocketConnection();
    socket.connect(socketData({ socketInstance: socket }));
  }
  useEffect(() => {
    process.nextTick(() => {
      if (window ?? false) {
        setIsFront(true);
      }
    });
    if (!isLoggedIn() && location.pathname !== `/`) {
      navigate("/");
    }
    setLoading(false);
  }, []);

  //app level data
  const [appData, setAppData] = useState({
    developerDetails: {},
    activePages: {},
    campaignDetails: {},
    updateUserProfile: false,
  });

  if (!isFront) return null;
  else if (loading) return <CircularLoading />;
  else
    return (
      <NotificationDataContext.Provider value={socketMessages}>
        <DeveloperDataContext.Provider
          value={{
            appData,
            setAppData,
          }}
        >
          <Layout>
            <UpdateButton
              message={
                isApproved
                  ? "This application has been updated. It will be reloaded soon."
                  : `Hi ${usersName}, You are not approved by fincity Admin. Please contact your Relationship Manager or support@fincity.com`
              }
              goDoLogout={!isApproved}
              className="app flex bg-navy-600 min-h-screen h-full w-full font-roboto text-sm font-normal m-0 p-0"
            >
              <div className="w-full bg-navy-600 relative font-roboto text-sm font-normal m-0 p-0 ">
                <div className=" min-h-screen  flex pt-4 md:pt-0 ">
                  <Sidebar />
                  <div
                    className={
                      " flex-1 w-full md:widhtMd max-w-full overflow-auto"
                    }
                  >
                    {width > 767 ? (
                      <Header />
                    ) : (
                      <MobileHeader mobileHeading={heading || ""} />
                    )}

                    <Location>
                      {({ location }) => (
                        <React.Fragment>
                          <ErrorBoundary>
                            <Suspense fallback={<CircularLoading />}>
                              <Router
                                location={location}
                                basepath="/app"
                                className="w-full"
                              >
                                <PrivateRoute
                                  exact
                                  path="/test"
                                  component={genericDashboard}
                                  crumbLabel="Test"
                                />
                                <PrivateRoute
                                  exact
                                  path="/settings"
                                  component={
                                    enableSettings ? Settings : comingSoonPage
                                  }
                                  crumbLabel="Settings"
                                  setTitle={setHeading}
                                  mobileHeading="Settings"
                                />
                                <PrivateRoute
                                  exact
                                  path="/logout"
                                  component={Logout}
                                />
                                <PrivateRoute
                                  exact
                                  path="/policy"
                                  component={PrivacyPolicy}
                                />
                                <PrivateRoute
                                  exact
                                  path="/opportunity/:leadBucketId/:leadBucketName"
                                  component={opportunityDashboard}
                                  crumbLabel="Opportunity"
                                  setTitle={setHeading}
                                  mobileHeading="Opportunity"
                                />
                                <PrivateRoute
                                  exact
                                  path="/opportunity"
                                  component={opportunityDashboard}
                                  crumbLabel="Opportunity"
                                  setTitle={setHeading}
                                  mobileHeading="Opportunity"
                                />
                                <PrivateRoute
                                  exact
                                  path="/attendance/check-out"
                                  component={CheckOutFlow}
                                  crumbLabel="Opportunity"
                                  setTitle={setHeading}
                                  mobileHeading="User Attendance"
                                />
                                <PrivateRoute
                                  exact
                                  path="/attendance/log"
                                  component={AttendanceLog}
                                  crumbLabel="My Attendance log"
                                  setTitle={setHeading}
                                  mobileHeading="My Attendance log"
                                />
                                <PrivateRoute
                                  exact
                                  path="/attendance/log"
                                  component={AttendanceLog}
                                  crumbLabel="My Attendance log"
                                  setTitle={setHeading}
                                  mobileHeading="My Attendance log"
                                />
                                <PrivateRoute
                                  exact
                                  path="/attendance/check-in"
                                  component={CheckInFlow}
                                  crumbLabel="Check-In"
                                  setTitle={setHeading}
                                  mobileHeading="Check-In"
                                />
                                <PrivateRoute
                                  exact
                                  path="/opportunity/details/:oppId"
                                  component={oppDetails}
                                  crumbLabel="Details"
                                  setTitle={setHeading}
                                  mobileHeading="Client Details"
                                />
                                <PrivateRoute
                                  exact
                                  path="/opportunity/details"
                                  component={oppDetails}
                                  crumbLabel="Details"
                                  setTitle={setHeading}
                                  mobileHeading="Client Details"
                                />
                                <PrivateRoute
                                  exact
                                  path="employee/:employeeId"
                                  component={EmployeeAttendanceDetails}
                                  crumbLabel="Details"
                                  setTitle={setHeading}
                                  mobileHeading="Attendance Details"
                                />
                                <PrivateRoute
                                  exact
                                  path="/accounts"
                                  component={AllAccounts}
                                  crumbLabel="Accounts"
                                  setTitle={setHeading}
                                  mobileHeading="Accounts"
                                />
                                <PrivateRoute
                                  exact
                                  path="/bp/dashboard"
                                  component={
                                    enableBP ? BPDashboard : comingSoonPage
                                  }
                                  crumbLabel="Business Partner"
                                  setTitle={setHeading}
                                  mobileHeading="Business Partner"
                                />
                                <PrivateRoute
                                  exact
                                  path="/bp"
                                  component={
                                    enableBPNewDashboard
                                      ? BPNewDashboard
                                      : comingSoonPage
                                  }
                                  setTitle={setHeading}
                                  crumbLabel="Business Partner"
                                  mobileHeading="Business Partner"
                                />
                                <PrivateRoute
                                  exact
                                  path="/ifa"
                                  component={
                                    enableBPNewDashboard
                                      ? IFAListDashboard
                                      : comingSoonPage
                                  }
                                  setTitle={setHeading}
                                  crumbLabel="Individual Financial Advisor"
                                  mobileHeading="Individual Financial Advisor"
                                />
                                <PrivateRoute
                                  exact
                                  path="/form"
                                  component={formSteps}
                                />
                                <PrivateRoute
                                  exact
                                  path="/walkin"
                                  component={
                                    enableCreateWalkIn
                                      ? WalkInDashboard
                                      : comingSoonPage
                                  }
                                  crumbLabel="Walk-In"
                                  setTitle={setHeading}
                                  mobileHeading="Walk-In"
                                />
                                <PrivateRoute
                                  exact
                                  path="/accounts/profile/:accountId"
                                  component={ProfileView}
                                  crumbLabel="Profile"
                                  setTitle={setHeading}
                                  mobileHeading="Profile"
                                />
                                <PrivateRoute
                                  exact
                                  path="/accounts/editprofile/:accountId"
                                  component={EditProfile}
                                  crumbLabel="Edit Profile"
                                />
                                <PrivateRoute
                                  exact
                                  path="/accounts/editmbprofile/:accountId"
                                  component={EditProfileMb}
                                  crumbLabel="Edit Profile"
                                  setTitle={setHeading}
                                  mobileHeading="Edit Profile"
                                />
                                <PrivateRoute
                                  exact
                                  path="/chooseProject"
                                  component={ChooseProject}
                                  crumbLabel="Choose Project"
                                  setTitle={setHeading}
                                  mobileHeading="Add Lead"
                                />
                                <PrivateRoute
                                  exact
                                  path="/accounts/mbprofile/:accountId"
                                  component={MbAccountDetails}
                                  crumbLabel="Profile Details"
                                  setTitle={setHeading}
                                  mobileHeading="Profile Deatils"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/performance-report"
                                  component={
                                    enablePerformaceReport
                                      ? PerformanceReport
                                      : MaintenancePage
                                  }
                                  crumbLabel="Performance Report"
                                  setTitle={setHeading}
                                  mobileHeading="Performance Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/weekend-visibility-report"
                                  component={
                                    enableWVR
                                      ? WeekendVisibilityReport
                                      : MaintenancePage
                                  }
                                  crumbLabel="Weekend Visibility Report"
                                  setTitle={setHeading}
                                  mobileHeading="Weekend Visibility Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/lead-bucket-report"
                                  component={LeadBucketReport}
                                  crumbLabel="Lead Bucket Report"
                                  setTitle={setHeading}
                                  mobileHeading="Lead Bucket Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/campaign-report"
                                  component={
                                    enableCampaignReport
                                      ? CampaignReport
                                      : comingSoonPage
                                  }
                                  crumbLabel="Campaign Report"
                                  setTitle={setHeading}
                                  mobileHeading="Campaign Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/bp-lead-quality-report"
                                  component={
                                    enableBpLeadQualityReport
                                      ? BPLeadQualityReport
                                      : MaintenancePage
                                  }
                                  crumbLabel="BP Lead Quality Report"
                                  setTitle={setHeading}
                                  mobileHeading="Lead Quality Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/lost-report"
                                  component={LostReport}
                                  crumbLabel="Lost  Report"
                                  setTitle={setHeading}
                                  mobileHeading="Lost Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/call-report"
                                  component={CallReport}
                                  crumbLabel="Call Report"
                                  setTitle={setHeading}
                                  mobileHeading="Call Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports"
                                  component={
                                    enableReport
                                      ? reportHomePage
                                      : MaintenancePage
                                  }
                                  crumbLabel="Report"
                                  setTitle={setHeading}
                                  mobileHeading="Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/accounts/addAccount"
                                  component={AddAccount}
                                />
                                <PrivateRoute
                                  exact
                                  path="/develops"
                                  component={comingSoonPage}
                                  breadCrumbShow={false}
                                />
                                <PrivateRoute
                                  exact
                                  path="/projects"
                                  component={comingSoonPage}
                                  breadCrumbShow={false}
                                />
                                <PrivateRoute
                                  exact
                                  path="/partners"
                                  component={comingSoonPage}
                                  breadCrumbShow={false}
                                />
                                <PrivateRoute
                                  exact
                                  path="/walkin-response"
                                  component={WalkInFormResponse}
                                  crumbLabel="Walk-In Response"
                                />
                                <PrivateRoute
                                  exact
                                  path="/dashboard"
                                  component={
                                    enableNewFollowUpDashboard
                                      ? NewFollowUpDashboard
                                      : FollowUpDashboard
                                  }
                                  crumbLabel=""
                                  breadCrumbShow={false}
                                  setTitle={setHeading}
                                  mobileHeading="Dashboard"
                                />
                                <PrivateRoute
                                  exact
                                  path="/dashboard/bp"
                                  component={BPFollowUpDashboard}
                                  crumbLabel=""
                                  breadCrumbShow={false}
                                  setTitle={setHeading}
                                  mobileHeading="Dashboard"
                                />
                                <PrivateRoute
                                  exact
                                  path="/attendance/bp/:checkInTypeId"
                                  component={BpAttendenceFlow}
                                  crumbLabel=""
                                  breadCrumbShow={false}
                                  setTitle={setHeading}
                                  mobileHeading="Mark Attendance"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/walkin-report"
                                  component={
                                    enableWalkinReport
                                      ? WalkInReport
                                      : MaintenancePage
                                  }
                                  crumbLabel="Walkin Report"
                                  mobileHeading="Walkin Report"
                                  setTitle={setHeading}
                                />
                                <PrivateRoute
                                  exact
                                  path="/bp/profile/:businessPartnerId"
                                  component={BPProfileView}
                                  crumbLabel="Profile View"
                                  setTitle={setHeading}
                                  mobileHeading="Profile View"
                                />
                                <PrivateRoute
                                  exact
                                  path="/bp/newprofile/:businessPartnerId"
                                  component={
                                    enableNewBPProfile
                                      ? NewBPProfileView
                                      : MaintenancePage
                                  }
                                  crumbLabel=""
                                  setTitle={setHeading}
                                  mobileHeading="Profile View"
                                />
                                <PrivateRoute
                                  exact
                                  path="/ifa/profile/:ifaId"
                                  component={
                                    enableNewBPProfile
                                      ? IFAProfile
                                      : MaintenancePage
                                  }
                                  crumbLabel=""
                                  setTitle={setHeading}
                                  mobileHeading="Profile View"
                                />
                                <PrivateRoute
                                  exact
                                  path="/leadFlowManagement"
                                  component={
                                    enableSettings
                                      ? LeadFlowManagement
                                      : comingSoonPage
                                  }
                                  crumbLabel="Lead Flow Management"
                                  setTitle={setHeading}
                                  mobileHeading="Lead Flow Management"
                                />
                                <PrivateRoute
                                  exact
                                  path="/defaultRules"
                                  component={DefaultRules}
                                  crumbLabel="Default Rules"
                                  setTitle={setHeading}
                                  mobileHeading="Default Rules"
                                />
                                <PrivateRoute
                                  exact
                                  path="/addRmForProject/:projectId/:projectName"
                                  component={AddRmForProject}
                                  crumbLabel="Project"
                                  setTitle={setHeading}
                                  mobileHeading="Project"
                                />
                                <PrivateRoute
                                  exact
                                  path="/RMView/:projectId/:projectName"
                                  component={ViewLeadFlowRM}
                                  crumbLabel="RMs"
                                  setTitle={setHeading}
                                  mobileHeading="RMs"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/misscall-report"
                                  component={
                                    enableMissCallReport
                                      ? MissCallReport
                                      : MaintenancePage
                                  }
                                  crumbLabel="Miss Call Report"
                                  setTitle={setHeading}
                                  mobileHeading="Miss Call Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/inbound-call-report"
                                  component={
                                    enableInboundCallReport
                                      ? InboundCallReport
                                      : MaintenancePage
                                  }
                                  crumbLabel="Inbound Call Report"
                                  setTitle={setHeading}
                                  mobileHeading="Inbound Call Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/followup-report"
                                  component={
                                    enableFollowUpReport
                                      ? FollowUpReport
                                      : MaintenancePage
                                  }
                                  crumbLabel="Follow up Report"
                                  setTitle={setHeading}
                                  mobileHeading="Follow up Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/developer"
                                  component={
                                    enableDeveloperAndProject
                                      ? DeveloperDashboard
                                      : comingSoonPage
                                  }
                                  breadCrumbShow={true}
                                  crumbLabel="project management"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/opportunity-geo-location"
                                  component={OppGeoLocation}
                                  breadCrumbShow={true}
                                  crumbLabel="Opportunity Geo Location"
                                />
                                <PrivateRoute
                                  exact
                                  path="/organisationSetting"
                                  component={
                                    enableOrganisationSetting
                                      ? OrganisationSetting
                                      : comingSoonPage
                                  }
                                  breadCrumbShow={true}
                                  crumbLabel="organisation management"
                                />
                                <PrivateRoute
                                  exact
                                  path="/organisationSetting/profileDetails/:userId/:roleId"
                                  component={ProfileDetails}
                                  breadCrumbShow={true}
                                />
                                <PrivateRoute
                                  exact
                                  path="/view-developer-projects/:id"
                                  component={DeveloperProject}
                                  crumbLabel="View Developer And Projects"
                                />
                                <PrivateRoute
                                  exact
                                  path="/projectOverview/:projectId/:projName/:projType"
                                  component={ProjectOverviewDash}
                                  crumbLabel="Project Overview"
                                />
                                <PrivateRoute
                                  exact
                                  path="/phaseDetails"
                                  component={PhaseDetails}
                                  crumbLabel="Phase Details"
                                />
                                <PrivateRoute
                                  exact
                                  path="/unitDetails"
                                  component={AllUnits}
                                  crumbLabel="Unit Details"
                                />
                                <PrivateRoute
                                  exact
                                  path="/addActivity"
                                  component={AddActivity}
                                  setTitle={setHeading}
                                  mobileHeading="Add Activity"
                                />
                                <PrivateRoute
                                  exact
                                  path="/edit-Unit-details/:projectId/:phaseId/:divisionType/:unitId/:projType/:config/:phaseName"
                                  component={EditUnitDetailsDash}
                                  crumbLabel="Edit Unit Details"
                                />
                                <PrivateRoute
                                  exact
                                  path="/edit-Unit-details/:projectId/:phaseId/:divisionType/:unitId/:projType/:phaseName"
                                  component={EditUnitDetailsDash}
                                  crumbLabel="Edit Area Unit Details"
                                />
                                <PrivateRoute
                                  exact
                                  path="/addOpportunity"
                                  component={MbAddOpportunity}
                                  setTitle={setHeading}
                                  mobileHeading="Add Opportunity"
                                />
                                <PrivateRoute
                                  exact
                                  path="/more"
                                  component={More}
                                  setTitle={setHeading}
                                  mobileHeading="Menu"
                                />
                                <PrivateRoute
                                  exact
                                  path="/changepassword"
                                  component={ChangePassword}
                                  setTitle={setHeading}
                                  mobileHeading="Password"
                                />
                                <PrivateRoute
                                  exact
                                  path="/editOportunity"
                                  component={MbEditOpportunity}
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/duplicate-lead-alert-report"
                                  component={DuplicateEventReport}
                                  crumbLabel="Lead Alert Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/content-management-setting"
                                  component={
                                    enableContentManagementSetting
                                      ? contentManagementSetting
                                      : comingSoonPage
                                  }
                                  crumbLabel="Content Management Setting"
                                  setTitle={setHeading}
                                  mobileHeading="Content Management Setting"
                                />
                                <PrivateRoute
                                  exact
                                  path="/content-management-setting/project/:projectId/:projectName"
                                  component={ProjectContent}
                                  crumbLabel="Project Content"
                                  setTitle={setHeading}
                                  mobileHeading="Project  Content"
                                />
                                <PrivateRoute
                                  exact
                                  path="/campaigns"
                                  component={
                                    enableCampaignSetting
                                      ? CampaignSetting
                                      : comingSoonPage
                                  }
                                  crumbLabel="campaigns"
                                />
                                <PrivateRoute
                                  exact
                                  path="/facebook-campaigns/:id"
                                  component={FacebookAdSetsList}
                                  crumbLabel={false}
                                />
                                <PrivateRoute
                                  exact
                                  path="/facebook-campaigns/:campaignId/:adSetId"
                                  component={FacebookAdsList}
                                  crumbLabel={false}
                                />
                                <PrivateRoute
                                  exact
                                  path="/campaigns/details/:campaignId"
                                  component={CampaignDetails}
                                  crumbLabel={false}
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/campaign-report-analysis"
                                  component={
                                    enableCampaignReportComponent
                                      ? campaignReport
                                      : comingSoonPage
                                  }
                                  crumbLabel="Campaign Report (Analysis)"
                                  setTitle={setHeading}
                                  mobileHeading="Campaign Report (Analysis)"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/marketing-report"
                                  component={
                                    enableMarketingReportComponent
                                      ? marketingReport
                                      : comingSoonPage
                                  }
                                  crumbLabel="Marketing Report"
                                  setTitle={setHeading}
                                  mobileHeading="Marketing Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/reports/project-phone-report"
                                  component={
                                    enableHordingCallReportComponent
                                      ? hoardingCallReport
                                      : comingSoonPage
                                  }
                                  crumbLabel="Hording Call Report"
                                  setTitle={setHeading}
                                  mobileHeading="Hording Call Report"
                                />
                                <PrivateRoute
                                  exact
                                  path="/bucket"
                                  component={
                                    enableBucket ? Bucket : comingSoonPage
                                  }
                                  crumbLabel="Buckets"
                                  setTitle={setHeading}
                                  mobileHeading="Buckets"
                                />
                                <PrivateRoute
                                  exact
                                  path="/addBucket"
                                  component={
                                    enableBucketComponent
                                      ? AddBucket
                                      : comingSoonPage
                                  }
                                  crumbLabel="Add Bucket"
                                  setTitle={setHeading}
                                  mobileHeading="Add Bucket"
                                />
                                <PrivateRoute
                                  exact
                                  path="/add-opportunity-list/:id"
                                  component={
                                    enableBucketComponent
                                      ? AddBucket
                                      : comingSoonPage
                                  }
                                  crumbLabel="Add Opportunity List"
                                  setTitle={setHeading}
                                  mobileHeading="Add Opportunity List"
                                />
                                <PrivateRoute
                                  exact
                                  path="/marketing-template"
                                  component={
                                    enableMarketingMain
                                      ? MarketingSideBar
                                      : comingSoonPage
                                  }
                                  crumbLabel="Marketing"
                                />
                                <PrivateRoute
                                  exact
                                  path="/history"
                                  component={
                                    enableMarketingMain
                                      ? History
                                      : comingSoonPage
                                  }
                                  crumbLabel="History"
                                  setHeading={setHeading}
                                  mobileHeading="History"
                                />
                                <PrivateRoute
                                  exact
                                  path="/marketing"
                                  component={
                                    enableMarketing ? Marketing : comingSoonPage
                                  }
                                  crumbLabel="Marketing"
                                  setTitle={setHeading}
                                  mobileHeading="Marketing"
                                />
                                <PrivateRoute
                                  exact
                                  path="/campaign/insights"
                                  component={CampaignInsights}
                                  crumbLabel="Marketing"
                                  setTitle={setHeading}
                                  mobileHeading="Campaign Insights"
                                />
                                <PrivateRoute
                                  exact
                                  path="/marketing/add-template"
                                  component={
                                    enableMarketingComponent
                                      ? AddTemplate
                                      : comingSoonPage
                                  }
                                  crumbLabel="Add Template"
                                  setTitle={setHeading}
                                  mobileHeading="Add Template"
                                />
                                <PrivateRoute
                                  exact
                                  path="/inventory-module"
                                  component={
                                    enableInventoryModule
                                      ? InventoryModule
                                      : comingSoonPage
                                  }
                                  crumbLabel={false}
                                  setTitle={setHeading}
                                  mobileHeading="Inventory & cost sheet"
                                />
                                <PrivateRoute
                                  exact
                                  path="/inventory-filter/:Id/RESIDENTIAL/:projectName"
                                  component={
                                    enableInventoryModule
                                      ? InventoryFilter
                                      : comingSoonPage
                                  }
                                  crumbLabel={false}
                                  breadCrumbShow={false}
                                />
                                <PrivateRoute
                                  exact
                                  path="/inventory-filter/:Id/COMMERCIAL/:projectName"
                                  component={
                                    enableInventoryModule
                                      ? InventoryFilterCommercial
                                      : comingSoonPage
                                  }
                                  crumbLabel={false}
                                />
                                <PrivateRoute
                                  exact
                                  path="/inventory-filter/cost-sheet/:projectId/:phaseId/:unitId/:oppId/:maxFloors"
                                  component={
                                    enableInventoryModule
                                      ? InventoryCostSheet
                                      : comingSoonPage
                                  }
                                  crumbLabel="Manage cost sheet"
                                  setTitle={setHeading}
                                  mobileHeading="Manage cost sheet"
                                />
                                <PrivateRoute
                                  exact
                                  path="/inventory-filter/cost-sheet/commercial/:projectId/:phaseId/:unitId/:oppId/:maxFloors"
                                  component={
                                    enableInventoryModule
                                      ? InventoryCostSheetCommercial
                                      : comingSoonPage
                                  }
                                  crumbLabel="Manage cost sheet"
                                  setTitle={setHeading}
                                  mobileHeading="Manage cost sheet"
                                />
                                <PrivateRoute
                                  exact
                                  path="/inventory-filter/booking-form/residential/:projectId/:phaseId/:unitId/:oppId/:maxFloors"
                                  component={
                                    enableBookingForm
                                      ? BookingForm
                                      : comingSoonPage
                                  }
                                  crumbLabel="Booking Form"
                                  setTitle={setHeading}
                                  mobileHeading="Booking Form"
                                />
                                <PrivateRoute
                                  exact
                                  path="/inventory-filter/booking-form/commercial/:projectId/:phaseId/:unitId/:oppId"
                                  component={
                                    enableBookingForm
                                      ? BookingFormCommercial
                                      : comingSoonPage
                                  }
                                  crumbLabel="Booking Form"
                                  setTitle={setHeading}
                                  mobileHeading="Booking Form"
                                />
                                <PrivateRoute
                                  exact
                                  path="/template-settings"
                                  component={TemplateSettings}
                                  crumbLabel="Templates"
                                  setTitle={setHeading}
                                  mobileHeading="Templates"
                                />
                                <PrivateRoute
                                  exact
                                  path="/inventory-list"
                                  component={InventoryList}
                                  crumbLabel="Booking Form Template"
                                  setTitle={setHeading}
                                  mobileHeading="Booking Form Template"
                                />
                                <PrivateRoute
                                  exact
                                  path="/inventory-list/booking-form-template-list/:projectId/:projectCode"
                                  component={BookingFormTemplateList}
                                  crumbLabel="Booking Form Template List"
                                  setTitle={setHeading}
                                  mobileHeading="Booking Form Template List"
                                />
                                <PrivateRoute
                                  exact
                                  path="/inventory-list/booking-form-template/:projectId/:projectCode/:projectType/:templateId/:isActive"
                                  component={BookingFormTemplate}
                                  crumbLabel="Booking Form Template"
                                  setTitle={setHeading}
                                  mobileHeading="Booking Form Template"
                                />
                                <PrivateRoute
                                  exact
                                  path="/template-settings/bp-tnc"
                                  component={BPTnc}
                                  crumbLabel="BP Terms & Conditions"
                                  setTitle={setHeading}
                                  mobileHeading="BP Terms & Conditions"
                                />
                                <PrivateRoute
                                  exact
                                  path="/tnc-settings/cost-sheet"
                                  component={
                                    false ? CostSheetTemplate : ComingSoonPage
                                  }
                                  crumbLabel="Cost Sheet Template"
                                  setTitle={setHeading}
                                  mobileHeading="Cost Sheet Template"
                                />
                                <PrivateRoute
                                  exact
                                  path="/user-profile"
                                  component={UserProfile}
                                  crumbLabel="Profile"
                                  setTitle={setHeading}
                                  mobileHeading="My Profile"
                                />
                                <PrivateRoute
                                  exact
                                  path="/bp/user-profile"
                                  component={BPProfile}
                                  crumbLabel=""
                                  breadCrumbShow={false}
                                  setTitle={setHeading}
                                  mobileHeading="My Profile"
                                />
                                <PrivateRoute
                                  exact
                                  path="/terms-and-conditions"
                                  component={TermsAndConditions}
                                  setTitle={setHeading}
                                  mobileHeading="Terms & Conditions"
                                />
                                <PrivateRoute
                                  exact
                                  path="/fincity-contact"
                                  component={FincityContact}
                                  setTitle={setHeading}
                                  mobileHeading="Fincity Contacts"
                                />
                                <PrivateRoute
                                  exact
                                  path="/bp-teammates"
                                  component={Teammates}
                                  setTitle={setHeading}
                                  mobileHeading="Teammates"
                                />
                                <PrivateRoute
                                  exact
                                  path="/content-share"
                                  component={
                                    enableContentManagementSetting
                                      ? contentManagementSetting
                                      : comingSoonPage
                                  }
                                  crumbLabel="Content Share"
                                  setTitle={setHeading}
                                  mobileHeading="Content Share"
                                />
                                <PrivateRoute
                                  exact
                                  path="/lead-expiration"
                                  component={LeadExpiration}
                                  crumbLabel="lead expiration"
                                />
                                <PrivateRoute
                                  exact
                                  path="/organization-structure"
                                  component={
                                    enableOrganizationStructure
                                      ? OrganizationModal
                                      : comingSoonPage
                                  }
                                  crumbLabel={false}
                                />
                                <PrivateRoute
                                  exact
                                  path="/dashboard/announcements"
                                  component={AnnouncementList}
                                  crumbLabel="Announcements"
                                />
                                <PrivateRoute
                                  exact
                                  path="/add-announcement"
                                  component={ReleaseNotes}
                                  crumbLabel="Add Announcement"
                                />
                                <PrivateRoute
                                  exact
                                  path="/Consumer/Profile"
                                  component={ConsumerProfile}
                                  breadCrumbShow={false}
                                  setTitle={setHeading}
                                  mobileHeading="Profile"
                                />
                                <PrivateRoute
                                  exact
                                  path="/Consumer/Properties"
                                  component={Properties}
                                  crumbLabel="Properties"
                                  setTitle={setHeading}
                                  mobileHeading="Dashboard"
                                />
                                <PrivateRoute
                                  exact
                                  path="/Consumer/Properties/CommercialInterestedForm"
                                  component={CommercialPropertyInterestedFrom}
                                  breadCrumbShow={false}
                                  setTitle={setHeading}
                                  mobileHeading="Enquiry Form"
                                />
                                <PrivateRoute
                                  exact
                                  path="/Consumer/Properties/ResidentialInterestedForm"
                                  component={ResidentialPropertyInterestedFrom}
                                  breadCrumbShow={false}
                                  setTitle={setHeading}
                                  mobileHeading="Enquiry Form"
                                />
                                <PrivateRoute
                                  exact
                                  path="/Consumer/MyProperties"
                                  component={MyProperties}
                                  crumbLabel="My Properties"
                                  setTitle={setHeading}
                                  mobileHeading="My Properties"
                                />
                                <PrivateRoute
                                  exact
                                  path="/Consumer/MyProperties/:ProjectId/:projectName"
                                  component={MyPropertyDocsDetialPage}
                                  crumbLabel={location.pathname
                                    .split("/")
                                    [
                                      location.pathname.split("/").length - 1
                                    ].split("%20")
                                    .join(" ")}
                                  setTitle={setHeading}
                                  mobileHeading={location.pathname
                                    .split("/")
                                    [
                                      location.pathname.split("/").length - 1
                                    ].split("%20")
                                    .join(" ")}
                                />
                                <PrivateRoute
                                  exact
                                  path="/Consumer/CommercialProperties/:ProjectId/:ProjectName"
                                  component={CommercialPropertyDetails}
                                  setTitle={setHeading}
                                  mobileHeading={location.pathname
                                    .split("/")
                                    [
                                      location.pathname.split("/").length - 1
                                    ].split("%20")
                                    .join(" ")}
                                  crumbLabel={location.pathname
                                    .split("/")
                                    [
                                      location.pathname.split("/").length - 1
                                    ].split("%20")
                                    .join(" ")}
                                />
                                <PrivateRoute
                                  exact
                                  path="/Consumer/Properties/Residential/:projectId/:projectName"
                                  component={ResidentialPropertyDetail}
                                  setTitle={setHeading}
                                  mobileHeading={location.pathname
                                    .split("/")
                                    [
                                      location.pathname.split("/").length - 1
                                    ].split("%20")
                                    .join(" ")}
                                  crumbLabel={location.pathname
                                    .split("/")
                                    [
                                      location.pathname.split("/").length - 1
                                    ].split("%20")
                                    .join(" ")}
                                />
                                <PrivateRoute
                                  exact
                                  path="/Consumer/AboutUs"
                                  component={ConsumerAboutUs}
                                  setTitle={setHeading}
                                  mobileHeading="About Us"
                                  crumbLabel="About Us"
                                />
                                <PrivateRoute
                                  exact
                                  path="/Consumer/ContactUs"
                                  component={ConsumerContactUs}
                                  setTitle={setHeading}
                                  mobileHeading="Contact Us"
                                  crumbLabel="Contact Us"
                                />
                                <PrivateRoute
                                  exact
                                  path="/lead-conflict-management"
                                  component={LeadConflictManagement}
                                  crumbLabel="Lead Conflict Management"
                                  setTitle={setHeading}
                                  mobileHeading="Lead Conflict Management"
                                />
                                <PrivateRoute
                                  exact
                                  path="/employee-attendance"
                                  component={EmployeeAttendance}
                                  crumbLabel="Employee Attendance"
                                  setTitle={setHeading}
                                  mobileHeading="Employee Attendance"
                                />
                                <PrivateRoute
                                  exact
                                  path="/edit-rules/:leadConflictBucketId"
                                  component={EditRulesPage}
                                  crumbLabel="Edit Rules Page"
                                  setTitle={setHeading}
                                  mobileHeading="Edit Rules Page"
                                />
                                <PrivateRoute
                                  exact
                                  path="/project-rules/:leadConflictBucketId"
                                  component={ProjectRulePage}
                                  crumbLabel="Project Rule Page"
                                  setTitle={setHeading}
                                  mobileHeading="Project Rule Page"
                                />
                                <NotFound default />
                              </Router>
                            </Suspense>
                          </ErrorBoundary>
                        </React.Fragment>
                      )}
                    </Location>
                    <MenuButton newBp={newBp} setNewBp={setNewBp} />
                  </div>
                </div>
              </div>
            </UpdateButton>
          </Layout>
        </DeveloperDataContext.Provider>
      </NotificationDataContext.Provider>
    );
};
export default App;

const NotFound = () => <p>Page doesn&#39;t exist... go to dashboard</p>;
