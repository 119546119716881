import React, { useState, useEffect } from "react";
import moment from "moment";
import { navigate } from "gatsby";
import {
  setApplicantionInfo,
  getApplicantionInfo,
} from "../../common/utils/applicantionInfo";

let selectedOppId;
let givenFeed;

export const getSelectedOppId = () => {
  return selectedOppId;
};
export const getSelectedgivenFeed = () => {
  return givenFeed;
};

const GetColumn = (setOppID, setOpen, setFeed) => {
  const commonStyle =
    "font-roboto font-normal  mx-auto table_font break-words  mx-3";
  const commonHeaderStyle = `font-normal mx-2`;

  function setValues(row) {
    if (row?.opportunityId) {
      setOppID(row?.opportunityId);
      setOpen(true);
      selectedOppId = row?.opportunityId ? row?.opportunityId : "";
      givenFeed = row?.feedbackGiven;
      setFeed(row?.feedbackGiven);
    }
  }

  const onOppClick = row => {
    navigate(`/app/opportunity/details/${row?.opportunityId}`);
    setApplicantionInfo({
      ...getApplicantionInfo(),
    });
  };
  const onClientClick = row => {
    navigate(`/app/accounts/profile/${row?.accountId}`);
  };

  const columns = [
    {
      name: (
        <p
          className={`${commonHeaderStyle} text-left ml-4`}
          style={{ textAlign: "left !important" }}
        >
          S.No
        </p>
      ),
      selector: "index",
      sortable: false,
      cell: row => (
        <div className="w-full mx-auto  max-w-full">
          <p
            className={` ${commonStyle} text-left ml-4 `}
            style={{ textAlign: "left !important" }}
          >
            {row?.serial_number || "--"}
          </p>
        </div>
      ),
      minWidth: "50px !important",
      maxWidth: "150px !important",
    },
    {
      name: (
        <p
          className={`${commonHeaderStyle} text-left`}
          style={{ textAlign: "left !important" }}
        >
          Opportunity Id
        </p>
      ),
      selector: "index",
      sortable: false,
      cell: row => (
        <div
          className="w-full mx-auto  max-w-full"
          onClick={() => onOppClick(row)}
        >
          <p
            className={` ${commonStyle} text-left ml-4 text-green-900 cursor-pointer `}
            style={{ textAlign: "left !important" }}
          >
            {row?.opportunityId || "--"}
          </p>
        </div>
      ),
      minWidth: "50px !important",
      maxWidth: "150px !important",
    },
    {
      name: (
        <p
          className={`${commonHeaderStyle} text-left ml-7 `}
          style={{ textAlign: "left !important" }}
        >
          Client Name
        </p>
      ),
      selector: "consumerName",
      sortable: true,
      cell: row => (
        <div
          className="w-full mx-auto max-w-full"
          onClick={() => onClientClick(row)}
        >
          <p
            className={` ${commonStyle} text-left ml-4 text-green-900`}
            style={{ textAlign: "left !important" }}
          >
            {row?.consumerName || "--"}
          </p>
        </div>
      ),
      minWidth: "150px !important",
      maxWidth: "200px !important",
    },
    {
      name: <p className={`${commonHeaderStyle} text-center`}>Response Date</p>,
      selector: "responseDate",
      sortable: true,
      cell: row => (
        <div className={`w-full mx-auto text-center ${commonStyle}`}>
          <p className="text-center ">
            {row?.responseDate ? moment(row?.responseDate).format("lll") : "--"}
          </p>
        </div>
      ),
      minWidth: "150px !important",
      maxWidth: "180px !important",
    },
    {
      name: <p className={`${commonHeaderStyle}`}>No. of Visit</p>,
      selector: "noOfVisit",
      sortable: false,
      cell: row => (
        <div className="w-full mx-auto text-center">
          <p
            data-tag="allowRowEvents"
            className={`text-center  cursor-pointer  ${commonStyle}`}
          >
            {row?.noOfVisit || "--"}
          </p>
        </div>
      ),
      minWidth: "150px !important",
    },
    {
      name: (
        <p
          className={`${commonHeaderStyle} text-left `}
          style={{ textAlign: "left !important" }}
        >
          RM Assigned
        </p>
      ),
      selector: "currentOwnerName",
      sortable: true,
      cell: row => (
        <div className={`w-full mx-auto text-left ${commonStyle}`}>
          <p className="text-left ">{row?.currentOwnerName || "--"}</p>
        </div>
      ),
      minWidth: "150px !important",
      maxWidth: "200px !important",
    },
    {
      name: <p className={`${commonHeaderStyle} text-center`}>Action</p>,
      selector: "opportunityId",
      sortable: false,
      cell: row => (
        <div className="w-full mx-auto text-center">
          <p
            className={`text-center text-black-400 cursor-pointer  ${commonStyle}`}
          >
            <button
              className="border border-tealCol-100 hover:bg-tealCol-100 hover:text-white mx-auto rounded py-2 px-5 text-xs text-tealCol-100 text-center curson-pointer"
              onClick={() => {
                setValues(row);
              }}
            >
              {row?.feedbackGiven ? "View Feedback" : "Add Feedback"}
            </button>
          </p>
        </div>
      ),
      minWidth: "150px !important",
      maxWidth: "200px !important",
    },
  ];
  return columns;
};
export default GetColumn;
