import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import styles from "./NewGlobalFilter.module.css";
import MbFilterModal from "./components/MbFilterModal";
import Modal from "../../../common/components/Modal/modal";
import {
  setFiltersInfo,
  getFiltersInfo,
} from "../../../common/utils/FiltersInfo";
import { getUserType, getRole } from "../../../common/utils/cookie-utils";
import { getAcl } from "../../../common/utils/acl-utils";
import activityImg from "../../../images/globalFilter/globalActivity.svg";
import dateImg from "../../../images/globalFilter/newGlobalDate.svg";
import rmImg from "../../../images/globalFilter/globalRealEstate.svg";
import statusImg from "../../../images/globalFilter/globalStatus.svg";
import tagImg from "../../../images/globalFilter/globalTag.svg";
import sourceImg from "../../../images/globalFilter/globalSource.svg";
import qualifyLeadImg from "../../../images/globalFilter/leadType.svg";
import CommonMultiSelectFilter from "../Filter/CommonMultiSelectFilter";
import {
  getActiveRm,
  getBPSMList,
  getBPMList,
  getSource,
  getMasterSubStatusList,
  getMasterStatusList,
  getTagList,
  getProjectList,
  getCampaignList,
  getCPLists,
  getOpportunityLostReasonList,
  getQualityLeadStatusList,
  getSubSource,
  getWalkInFormTypes,
  getOpportunityNonContactableReasonList,
} from "../../../common/constants/api";
import { DeveloperDataContext } from "../../../common/utils/appContext";
import DateTypeFilter from "./components/DateTypeFilter";
import NewDateFilter from "./components/NewDateFilter";
import searchIconSolid from "../../../images/icons/searchIconSolid.svg";
import CommonRadioButtonFilter from "../Filter/CommonRadioButtonFilter";

const getRMFilterAccess = () => {
  return getRole() != undefined
    ? [
        "Admin",
        "Inside Sales Vertical Head",
        "CP Vertical Head",
        "Sales Vertical Head",
        "Inside Sales Cluster Head",
        "CP Cluster Head",
        "Sales Cluster Head",
        "Inside Sales Team Lead",
        "CP Team Lead",
        "Sales Team Lead",
      ].includes(JSON.parse(getRole())?.name)
    : false;
};

const NewGlobalFilter = ({
  nullProject,
  updateAppliedFilter,
  setUpdateAppliedFilter,
  show,
  setShow,
  applyFilter,
  setApplyFilter,
  globalFilterFor,
  showLifeTimeData,
  dateTypeFor,
  defaultStartDate,
  defaultEndDate,
  defaultDateType,
  defaultAction,
  statusList,
  setStatusList,
  subStatusList,
  setSubStatusList,
  source,
  setSource,
  subSource,
  setSubSource,
  tag,
  setTag,
  rm,
  setRm,
  sm,
  setSm,
  bpm,
  setBpm,
  propertyName,
  setPropertyName,
  campaign,
  setCampaign,
  channelPartner,
  setChannelPartner,
  qualifyLead,
  setQualifyLead,
  lostReason,
  setLostReason,
  nonContactableReason,
  setNonContactableReason,
  formType,
  setFormType,
  dateTypeList,
  setDateTypeList,
  showFilters = [],
  showSelectedFilter,
  setShowSelectedFilter,
  showSearch,
  defaultSourceId,
  sourceBlock,
  noDate,
  defaultProjectId = [],
  minStartDate,
  maxEndDate,
}) => {
  const { selectedFilter, scrollBar, showScrollBar } = styles;
  const [activeDiv, setActiveDiv] = useState(showFilters[0]);
  const Context = useContext(DeveloperDataContext);
  const [apiCallCount, setApiCallCount] = useState(0);
  useEffect(() => {
    if (showSelectedFilter != undefined) setActiveDiv(showSelectedFilter);
  }, [showSelectedFilter]);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (
      defaultSourceId ||
      Context?.appData[`appliedGlobalFilter${globalFilterFor}`]?.Source
    ) {
      let subSourceData =
        defaultSourceId ||
        Context?.appData[`appliedGlobalFilter${globalFilterFor}`]?.Source;

      if (Array.isArray(subSourceData) && subSourceData.length === 0) {
        Context?.setAppData({
          ...Context?.appData,
          [`appliedGlobalFilter${globalFilterFor}`]: {
            ...Context?.appData[`appliedGlobalFilter${globalFilterFor}`],
            SubSource: [],
          },
        });
        setSubSource([]);
        setApiCallCount(0);
      } else {
        if (Array.isArray(subSourceData) && subSourceData.length > 0) {
          getSubSource(subSourceData)
            .then(res => {
              let count = apiCallCount;
              let tempArr1 = [];
              res?.map((el, i) => {
                tempArr1.push({
                  id:
                    el["userId"] !== null && el["userId"] !== undefined
                      ? el["userId"]
                      : el["id"] !== null && el["id"] !== undefined
                      ? el["id"]
                      : el["name"],
                });
                tempArr1[i]["name"] = el["name"];
              });
              setSubSource([...tempArr1]);
              setApiCallCount(count + 1);
            })
            .catch(err => {});
        }
      }
    }
  }, [
    Context?.appData[`appliedGlobalFilter${globalFilterFor}`]?.Source,
    defaultSourceId,
  ]);

  useEffect(() => {
    if (
      Context?.appData[`appliedGlobalFilter${globalFilterFor}`]?.PropertyName
        ?.length > 0 ||
      defaultProjectId?.length <= 1 ||
      apiCallCount > 0
    ) {
      let projectList =
        Context?.appData[`appliedGlobalFilter${globalFilterFor}`]?.PropertyName
          ?.length > 0
          ? Context?.appData[`appliedGlobalFilter${globalFilterFor}`]
              ?.PropertyName
          : defaultProjectId;

      if (showFilters.filter(obj => obj == "Campaign").length > 0) {
        getCampaignList(projectList)
          .then(res => {
            setCampaign([...res]);
          })
          .catch(err => {});
      }
    }
  }, [Context?.appData[`appliedGlobalFilter${globalFilterFor}`]?.PropertyName]);

  const filterOptions = [
    {
      icons: dateImg,
      label: "Date Type",
      shouldShow: showFilters.includes("Date Type"),
    },
    {
      icons: dateImg,
      label: "Date",
      shouldShow: showFilters.includes("Date"),
    },
    {
      icons: statusImg,
      label: "Status",
      shouldShow: showFilters.includes("Status"),
    },
    {
      icons: activityImg,
      label: "Activity",
      shouldShow: showFilters.includes("Activity"),
    },
    ...(Context?.appData[
      `appliedGlobalFilter${globalFilterFor}`
    ]?.Activity?.includes(8)
      ? [
          {
            icons: statusImg,
            label: "Lost Reason",
            shouldShow: showFilters.includes("Lost Reason"),
          },
        ]
      : []),
    ...(Context?.appData[
      `appliedGlobalFilter${globalFilterFor}`
    ]?.Activity?.includes(3)
      ? [
          {
            icons: statusImg,
            label: "NC Reason",
            shouldShow: showFilters.includes("Non Contactable Reason"),
          },
        ]
      : []),
    {
      icons: sourceImg,
      label: "Source",
      shouldShow: showFilters.includes("Source"),
    },
    ...(defaultSourceId ||
    (Context?.appData[`appliedGlobalFilter${globalFilterFor}`]?.Source?.length >
      0 &&
      subSource.length > 0)
      ? [
          {
            icons: statusImg,
            label: "Sub-source",
            shouldShow: showFilters.includes("Sub-source"),
          },
        ]
      : []),
    {
      icons: tagImg,
      label: "Tag",
      shouldShow: showFilters.includes("Tag"),
    },
    {
      icons: rmImg,
      label: "RM",
      shouldShow: showFilters.includes("RM") && getRMFilterAccess(),
    },
    {
      icons: rmImg,
      label: "SM",
      shouldShow: showFilters.includes("SM"),
    },
    {
      icons: rmImg,
      label: "BPM",
      shouldShow: showFilters.includes("BPM"),
    },
    {
      icons: statusImg,
      label: "Property Name",
      shouldShow: showFilters.includes("Property Name"),
    },
    {
      icons: tagImg,
      label: "Campaign",
      shouldShow:
        showFilters.includes("Campaign") && (getUserType() == 0 ? true : false),
    },
    {
      icons: rmImg,
      label: "Chanel Partner",
      shouldShow:
        showFilters.includes("Chanel Partner") &&
        getAcl()?.can_add_business_partner,
    },
    {
      icons: qualifyLeadImg,
      label: "Qualify Lead",
      shouldShow: showFilters.includes("Qualify Lead"),
    },
    {
      icons: sourceImg,
      label: "Form Type",
      shouldShow: showFilters.includes("Form Type"),
    },
    {
      icons: activityImg,
      label: "Action",
      shouldShow: showFilters.includes("Action"),
    },
  ];

  const handleApply = async () => {
    setSearchValue("");
    await setApplyFilter(!applyFilter);
    await setShow(!show);
    await setShowSelectedFilter();
    await setUpdateAppliedFilter(!updateAppliedFilter);
  };

  const handleClear = async () => {
    Context?.setAppData({
      ...Context?.appData,
      [`appliedGlobalFilter${globalFilterFor}`]: {
        ...(!noDate && {
          Date: {
            fromDate: defaultStartDate,
            toDate: defaultEndDate,
          },
        }),
        ...(!noDate && defaultDateType && { DateFilterType: defaultDateType }),
        ...(!noDate && defaultAction && { IsActive: defaultAction }),
      },
    });
    let tempFilterKey = !noDate ? getFiltersInfo() || {} : {};

    if (globalFilterFor && !noDate) {
      tempFilterKey[globalFilterFor] = {
        fromDate: defaultStartDate,
        toDate: defaultEndDate,
        ...(defaultDateType && { dateFilterType: defaultDateType }),
        ...(defaultAction ? { isActive: defaultAction } : {}),
      };
    }

    setFiltersInfo(tempFilterKey);
    await setApplyFilter(!applyFilter);
    await setShow(!show);
    await setUpdateAppliedFilter(!updateAppliedFilter);
  };

  const updateList = e => {
    setSearchValue(e.target.value);
  };

  const SelectedFilter = ({ name }) =>
    useMemo(() => {
      var functions = {
        "Date Type": (
          <DateTypeFilter
            label={"Select Date Type"}
            labelKey="name"
            options={dateTypeList}
            setOptions={setDateTypeList}
            dateTypeFor={dateTypeFor}
            globalFilterFor={globalFilterFor}
            showSearch={showSearch?.includes(name)}
          />
        ),
        Date: (
          <NewDateFilter
            label={"Select Date"}
            globalFilterFor={globalFilterFor}
            showSearch={showSearch?.includes(name)}
            showLifeTimeData={showLifeTimeData}
            minStartDate={minStartDate}
            maxEndDate={maxEndDate}
          />
        ),
        Status: (
          <CommonMultiSelectFilter
            label={"Select Status"}
            commonApi={getMasterStatusList}
            labelKey="name"
            options={statusList}
            setOptions={setStatusList}
            labelFor={"Status"}
            globalFilterFor={globalFilterFor}
            showSearch={showSearch?.includes(name)}
          />
        ),
        Activity: (
          <CommonMultiSelectFilter
            label={"Select Activity"}
            commonApi={getMasterSubStatusList}
            labelKey="name"
            options={subStatusList}
            setOptions={setSubStatusList}
            labelFor={"Activity"}
            globalFilterFor={globalFilterFor}
            showSearch={showSearch?.includes(name)}
          />
        ),
        "Lost Reason": (
          <CommonMultiSelectFilter
            label={"Select Lost Reason"}
            commonApi={getOpportunityLostReasonList}
            labelKey="name"
            options={lostReason}
            setOptions={setLostReason}
            labelFor={"LostReason"}
            globalFilterFor={globalFilterFor}
            showSearch={showSearch?.includes(name)}
          />
        ),
        "NC Reason": (
          <CommonMultiSelectFilter
            label={"Select Non-Contactable Reason"}
            commonApi={getOpportunityNonContactableReasonList}
            labelKey="name"
            options={nonContactableReason}
            setOptions={setNonContactableReason}
            labelFor={"NonContactableReason"}
            globalFilterFor={globalFilterFor}
            showSearch={showSearch?.includes(name)}
          />
        ),
        Source: (
          <CommonMultiSelectFilter
            label={"Select Source"}
            commonApi={getSource}
            labelKey="name"
            options={source}
            setOptions={setSource}
            labelFor={"Source"}
            globalFilterFor={globalFilterFor}
            showSearch={showSearch?.includes(name)}
          />
        ),
        "Sub-source": (
          <CommonMultiSelectFilter
            label={"Select Sub-source"}
            commonApi={getSubSource}
            labelKey="name"
            options={subSource}
            setOptions={setSubSource}
            labelFor={"SubSource"}
            globalFilterFor={globalFilterFor}
            noCaching
            showSearch={showSearch?.includes(name)}
          />
        ),
        Tag: (
          <CommonMultiSelectFilter
            label={"Select Tag"}
            commonApi={getTagList}
            labelKey="name"
            options={tag}
            setOptions={setTag}
            labelFor={"Tag"}
            globalFilterFor={globalFilterFor}
            showSearch={showSearch?.includes(name)}
          />
        ),
        RM: (
          <CommonMultiSelectFilter
            label={"Select RM"}
            commonApi={getActiveRm}
            labelKey="name"
            options={rm}
            setOptions={setRm}
            labelFor={"RM"}
            globalFilterFor={globalFilterFor}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showSearch={showSearch?.includes(name)}
          />
        ),
        SM: (
          <CommonMultiSelectFilter
            label={"Select SM"}
            commonApi={getBPSMList}
            labelKey="name"
            options={sm}
            setOptions={setSm}
            labelFor={"SM"}
            globalFilterFor={globalFilterFor}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showSearch={showSearch?.includes(name)}
          />
        ),
        BPM: (
          <CommonMultiSelectFilter
            label={"Select BPM"}
            commonApi={getBPMList}
            labelKey="name"
            options={bpm}
            setOptions={setBpm}
            labelFor={"BPM"}
            globalFilterFor={globalFilterFor}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showSearch={showSearch?.includes(name)}
          />
        ),
        "Property Name": (
          <CommonMultiSelectFilter
            nullProject={nullProject}
            label={"Select Property Name"}
            commonApi={getProjectList}
            labelKey="name"
            options={propertyName}
            setOptions={setPropertyName}
            labelFor={"PropertyName"}
            globalFilterFor={globalFilterFor}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showSearch={showSearch?.includes(name)}
          />
        ),
        "Form Type": (
          <CommonRadioButtonFilter
            label={"Select Form Type"}
            labelKey="name"
            commonApi={getWalkInFormTypes}
            options={formType}
            setOptions={setFormType}
            labelFor={"FormType"}
            globalFilterFor={globalFilterFor}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showSearch={showSearch?.includes(name)}
          />
        ),
        Action: (
          <CommonRadioButtonFilter
            label={"Select Action"}
            labelKey="name"
            options={[
              { id: 1, name: "Active" },
              { id: 0, name: "In Active" },
            ]}
            labelFor={"IsActive"}
            globalFilterFor={globalFilterFor}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showSearch={showSearch?.includes(name)}
          />
        ),
        Campaign: (
          <CommonMultiSelectFilter
            label={"Select Campaign"}
            commonApi={getCampaignList}
            labelKey="value"
            options={campaign}
            setOptions={setCampaign}
            labelFor={"Campaign"}
            globalFilterFor={globalFilterFor}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showSearch={showSearch?.includes(name)}
            isCampaign={true}
            noCaching
          />
        ),
        "Chanel Partner": (
          <CommonMultiSelectFilter
            label={"Select Chanel Partner"}
            commonApi={getCPLists}
            labelKey="value"
            options={channelPartner}
            setOptions={setChannelPartner}
            labelFor={"ChannelPartner"}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            globalFilterFor={globalFilterFor}
            showSearch={showSearch?.includes(name)}
          />
        ),
        "Qualify Lead": (
          <CommonMultiSelectFilter
            label={"Select Qualify Lead"}
            commonApi={getQualityLeadStatusList}
            labelKey="name"
            options={qualifyLead}
            setOptions={setQualifyLead}
            labelFor={"QualifyLead"}
            globalFilterFor={globalFilterFor}
            showSearch={showSearch?.includes(name)}
          />
        ),
      };
      return functions[name] || null;
    }, []);

  return (
    <Fragment>
      <MbFilterModal
        show={show}
        setShow={setShow}
        setShowSelectedFilter={setShowSelectedFilter}
        globalFilterFor={globalFilterFor}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      >
        <div className="w-full flex flex-row">
          <div
            className={`w-37 h-80v py-2 flex flex-col overflow-y-auto ${scrollBar}`}
            style={{
              background:
                "linear-gradient(180deg, rgba(206, 233, 234, 0.18) 0%, rgba(0, 60, 62, 0.08) 100%)",
            }}
          >
            {filterOptions.map(
              (el, i) =>
                el?.shouldShow && (
                  <div
                    key={i}
                    onClick={() => {
                      setActiveDiv(el?.label);
                      setSearchValue("");
                    }}
                    className={`w-full h-10 pl-4 flex-shrink-0 flex justify-start items-center cursor-pointer space-x-2 ${
                      activeDiv === el?.label ? "bg-green-1900" : ""
                    }`}
                  >
                    <img src={el?.icons} alt="oops!" />
                    <span className="font-sans font-normal text-sm text-black-0 break-words">
                      {el.label}
                    </span>
                  </div>
                )
            )}
          </div>
          <div className="w-7/12 relative">
            {showSearch?.includes(activeDiv) && (
              <div
                className="mt-2 mx-2 flex px-2 mb-2 items-center rounded border-grey-700 hover:border-2 border-2"
                style={{ width: "96%" }}
              >
                <div>
                  <img
                    src={searchIconSolid}
                    alt="search rm "
                    className="w-4 mr-auto "
                  />
                </div>
                <input
                  className="w-10/12 placeholder-grey-1001 ml-3 text-sm font-roboto font-normal h-10"
                  placeholder={`Search ${activeDiv}`}
                  onChange={updateList}
                  value={searchValue}
                />
              </div>
            )}
            <div
              className={`w-full pl-2 pr-2 overflow-y-auto ${scrollBar} ${
                showSearch?.includes(activeDiv) ? "mt-8" : "mt-7"
              }`}
              style={{
                height: showSearch?.includes(activeDiv) ? "70vh" : "78vh",
              }}
            >
              <div className="w-full">
                <SelectedFilter name={activeDiv} />
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full bg-white absolute h-16 flex justify-between items-center px-6 mb-6"
          style={{
            boxShadow:
              "inset 0px -1px 1px rgba(0, 0, 0, 0.15), inset 0px 1px 1px rgba(0, 0, 0, 0.15)",
          }}
        >
          <div className="h-full flex items-end pb-4" onClick={handleClear}>
            <span className="font-sans font-normal text-base text-red-1000">
              Clear all
            </span>
          </div>
          <div
            className="w-37 h-10 flex justify-center items-center bg-green-1500 rounded shadow-md"
            onClick={handleApply}
          >
            <span className="font-sans font-normal text-base text-white">
              Apply
            </span>
          </div>
        </div>
      </MbFilterModal>
      <Modal
        outerClass="hidden md:flex"
        show={show}
        setShow={setShow}
        setShowSelectedFilter={setShowSelectedFilter}
        globalFilterFor={globalFilterFor}
        setSearchValue={setSearchValue}
        styleContainer={{
          maxWidth: "700px",
        }}
        noCloseBtn={false}
      >
        <div className="w-full flex flex-row">
          <div
            className={`w-44 h-70v py-2 flex flex-col overflow-y-auto ${scrollBar}`}
            style={{
              background:
                "linear-gradient(180deg, rgba(206, 233, 234, 0.18) 0%, rgba(0, 60, 62, 0.08) 100%)",
            }}
          >
            {filterOptions.map(
              (el, i) =>
                el?.shouldShow && (
                  <div
                    key={i}
                    onClick={() => {
                      setActiveDiv(el?.label);
                    }}
                    className={`w-full h-10 pl-4 flex-shrink-0 flex items-center cursor-pointer space-x-2 ${
                      activeDiv === el?.label ? "bg-green-1900" : ""
                    }`}
                  >
                    <img src={el?.icons} alt="oops!" />
                    <span className="font-sans font-normal text-sm text-black-0 break-words">
                      {el.label}
                    </span>
                  </div>
                )
            )}
          </div>
          <div className={`h-70v py-6 px-6 relative ${selectedFilter}`}>
            {showSearch?.includes(activeDiv) && (
              <div className="mt-2 w-full flex px-2 mb-2 items-center rounded border-grey-700 hover:border-2 border-2">
                <div>
                  <img
                    src={searchIconSolid}
                    alt="search rm "
                    className="w-4 mr-auto "
                  />
                </div>
                <input
                  className="w-10/12 placeholder-grey-1001 ml-3 text-sm font-roboto font-normal h-10"
                  placeholder={`Search ${activeDiv}`}
                  onChange={updateList}
                  value={searchValue}
                />
              </div>
            )}
            <div
              className={`w-full overflow-y-auto ${showScrollBar} ${
                showSearch?.includes(activeDiv) ? "mt-8" : "pt-5"
              }`}
              style={{
                height: showSearch?.includes(activeDiv) ? "46vh" : "54vh",
              }}
            >
              <SelectedFilter name={activeDiv} />
            </div>
            <div
              className="absolute flex space-x-4 items-center px-6 bottom-6 right-3 justify-end bg-white pt-2 mb-2"
              style={{ width: "96%" }}
            >
              <div
                className="md:w-28 md:h-10 h-full flex items-end pb-4 md:pb-0 md:justify-center md:items-center md:border-red-1000 md:border md:rounded md:shadow cursor-pointer"
                onClick={handleClear}
              >
                <span className="font-sans font-normal text-base text-red-1000">
                  Reset
                </span>
              </div>
              <div
                className="w-37 md:w-28 h-10 flex justify-center items-center bg-green-1500 rounded shadow-md cursor-pointer"
                onClick={handleApply}
              >
                <span className="font-sans font-normal text-base text-white">
                  Apply
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
export default NewGlobalFilter;
