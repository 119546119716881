import React, { useContext, useEffect } from "react";
import { useState } from "react";
import styles from "../NewGlobalFilter.module.css";
import ArrowLeftIcon from "@material-ui/icons/ArrowBack";
import { DeveloperDataContext } from "../../../../common/utils/appContext";
import { presistantFilter } from "../../Filter/components/PresistantFilter";

function MbFilterModal({
  show,
  setShow,
  children,
  setShowSelectedFilter,
  globalFilterFor,
  searchValue,
  setSearchValue,
}) {
  const [isSilder, setIsSlider] = useState(false);
  const Context = useContext(DeveloperDataContext);
  const {
    modal,
    filterContainer,
    mobile_modal,
    animation,
    background_transparent,
  } = styles;

  //   if (!show) return null;

  const removeUnApplied = () => {
    const data = presistantFilter("Test");
    Context?.setAppData({
      ...Context?.appData,
      [`appliedGlobalFilter${globalFilterFor}`]: {
        ...Context?.appData[`appliedGlobalFilter${globalFilterFor}`],
        ...data,
      },
    });
  };

  return (
    <div
      className={`${filterContainer} bg-white md:hidden`}
      style={{
        width: show ? "" : "0px",
      }}
    >
      <div
        className="max-w-full h-12 px-4 pb-1 flex justify-between items-end border-b"
        style={{ borderColor: "#CECECE" }}
      >
        <span className="font-sans font-normal text-xs text-black-0">
          Filters
        </span>
        <div
          onClick={() => {
            setSearchValue("");
            setShow(false);
            setShowSelectedFilter();
            removeUnApplied();
          }}
        >
          <ArrowLeftIcon style={{ color: "black", fontSize: "20px" }} />
        </div>
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
}

export default MbFilterModal;
