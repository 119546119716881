import React, { Fragment, useEffect, useState } from "react";
import FincityAppLogo from "./../../images/consumer/FincityAppLogo.png";
import closeIcon from "./../../images/consumer/close_button.svg";
import { getAllDeviceType } from "../../common/constants/api";
import { UserDeviceType } from "../../common/constants/UserDeviceDetails";

const CommonDownloadApp = () => {
  let url = "";
  if (typeof window != "undefined") {
    url = new URL(window.location.href);
  }

  let id = url?.searchParams?.get("deviceId")
    ? url.searchParams.get("deviceId")
    : localStorage.getItem("deviceId");

  const [showAppDownload, setShowAppDownlaod] = useState(true);
  const [deviceId, setDeviceId] = useState(id);
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    setDeviceType(UserDeviceType());
  }, []);

  return (
    <Fragment>
      {deviceId !=1 && deviceId != 0 && showAppDownload && (
        <div className="w-full h-12 flex flex-row justify-between z-50 md:hidden bg-white block border-2 border-grey-200">
          <div className="flex flex-row justify-items-start my-2">
            <div className="my-auto h-3 ml-3"
            onClick={() => {
               setShowAppDownlaod(false);
            }}
            >
              <img src={closeIcon} alt="close icon" />
            </div>
            <div className="my-auto ml-2 h-7">
              <img src={FincityAppLogo} alt="fincity logo" />
            </div>
            <div className="flex flex-col justify-items-start my-auto ml-2">
              <div className="font-sans text-xs font-bold">
                Fincity Consumer App
              </div>
              <div
                className="font-sans -mt-1"
                style={{
                  color: "#76859E",
                  fontSize: "10px",
                }}
              >
                Download Now
              </div>
            </div>
          </div>
          <div className="my-2 mr-4 w-17">
            <button
              className="w-full h-7 flex-shrink font-sans font-normal text-xs text-white flex justify-center items-center hover:opacity-75"
              style={{
                backgroundColor: "#1F3C3D",
                borderRadius: "4px"
              }}
              onClick={() => {
                if (
                    (deviceType == "iPhone" ||
                      deviceType == "iPad" ||
                      deviceType == "iPod")
                  ) {
                    window.open("https://apps.apple.com/in/app/fincity/id6446762407");
                  } else {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.b2c.fincity"
                    );
                  } 
              }}
            >
              Get App
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CommonDownloadApp;