import React, { Component, Fragment } from 'react';
import PopUp from '../../common/components/PopUp/PopUp';

class ErrorBoundary extends Component {
  state = {
    hasError: false
  };
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }
  clearError = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <PopUp
          type="fail"
          text={'Oops!Something went wrong..'}
          setShow={this.clearError}
          show={this.hasError}
        />
      );
    } else return this.props.children;
  }
}

export default ErrorBoundary;
