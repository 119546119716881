import React, { useState, useEffect, Fragment, useContext } from "react";
import { DeveloperDataContext } from "../../../common/utils/appContext";
import CommonCheckbox from "../CommonCheckBox/CommonCheckbox";
import NewSpinner from "../NewSpinner/NewSpinner";
import styles from "./styles/checkBox.module.css";
import useWindowResize from "../../../hooks/useWindowResize";

const CommonMultiSelectFilter = ({
  nullProject,
  label,
  commonApi,
  labelKey,
  options,
  setOptions,
  labelFor,
  noCaching,
  globalFilterFor,
  searchValue,
  showSearch,
}) => {
  const Context = useContext(DeveloperDataContext);
  const [optionList, setOptionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [windowWidth] = useWindowResize();

  const handleChange = each => {
    let updateList =
      Context?.appData[`appliedGlobalFilter${globalFilterFor}`] &&
      Context?.appData[`appliedGlobalFilter${globalFilterFor}`]?.hasOwnProperty(
        labelFor
      )
        ? [
            ...Context?.appData[`appliedGlobalFilter${globalFilterFor}`][
              labelFor
            ],
          ]
        : [];

    if (!updateList.includes(each?.id)) {
      updateList =
        Context?.appData[`appliedGlobalFilter${globalFilterFor}`] &&
        Context?.appData[
          `appliedGlobalFilter${globalFilterFor}`
        ]?.hasOwnProperty(labelFor)
          ? [
              ...Context?.appData[`appliedGlobalFilter${globalFilterFor}`][
                labelFor
              ],
              each?.id,
            ]
          : [each?.id];
    } else {
      updateList.splice(updateList?.indexOf(each?.id), 1);
    }
    var data = {};
    data[labelFor] = updateList;
    Context?.setAppData({
      ...Context.appData,
      [`appliedGlobalFilter${globalFilterFor}`]: {
        ...Context?.appData[`appliedGlobalFilter${globalFilterFor}`],
        ...data,
      },
    });
  };

  const isChecked = name => {
    if (
      Context?.appData[`appliedGlobalFilter${globalFilterFor}`] &&
      Context?.appData[`appliedGlobalFilter${globalFilterFor}`]?.hasOwnProperty(
        labelFor
      )
    ) {
      return Context?.appData[`appliedGlobalFilter${globalFilterFor}`][
        labelFor
      ].includes(name);
    }
    return false;
  };

  useEffect(() => {
    if (!noCaching && typeof setOptions == "function") {
      setLoading(true);
      let active = true;
      const callMasterApi = async callingApi => {
        const response = await callingApi(searchValue);
        if (active && response) {
          let tempArr1 = [];
          if (label) {
            response?.map((el, i) => {
              tempArr1.push({
                id:
                  el["userId"] !== null && el["userId"] !== undefined
                    ? el["userId"]
                    : el["id"] !== null && el["id"] !== undefined
                    ? el["id"]
                    : el[labelKey],
              });
              tempArr1[i][labelKey] = el[labelKey];
            });
          } else {
            response?.map((el, i) => {
              tempArr1.push({
                id: el.id,
              });
              tempArr1[i][labelKey] = el.name + " - " + el.type;
            });
          }
          setLoading(false);
          if (nullProject) {
            setOptions([{ id: -1, name: "No Projects" }, ...tempArr1]);
          } else {
            setOptions(tempArr1);
          }
        }
      };

      if (Array.isArray(options) && options?.length === 0) {
        callMasterApi(commonApi);
      }
      return () => {
        active = false;
      };
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    setOptionList(options);
    setLoading(false);
  }, [options]);

  useEffect(() => {
    if (searchValue != undefined && searchValue !== "") {
      let arr = [];
      options.map(each => {
        if (
          !(each?.name ?? each?.value ?? each)
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
        )
          return;
        arr.push(each);
      });
      setOptionList(arr);
    }
  }, [searchValue]);

  return (
    <Fragment>
      <div
        className={`font-sans font-normal text-sm z-10 bg-white pb-2 text-black-400`}
        style={{
          position: "absolute",
          left: windowWidth > 767 ? "24px" : "8px",
          top:
            windowWidth > 767
              ? showSearch
                ? "84px"
                : "24px"
              : showSearch
              ? "60px"
              : "8px",
        }}
      >
        {optionList.length > 0 && label}
      </div>
      {!loading && (
        <div className="w-full min-h-1/2 md:flex md:flex-wrap">
          {optionList?.length > 0 ? (
            optionList?.map((each, index) => (
              <div
                className="md:w-1/2 flex mt-3 cursor-pointer"
                key={index}
                style={{ height: "fit-content" }}
              >
                <CommonCheckbox
                  exportedStyles={styles}
                  name={`${each?.name ?? each?.value}${index}`}
                  checked={isChecked(each.id)}
                  label={each?.name ?? each?.value ?? each}
                  labelStyle="font-sans font-normal text-sm text-black-400 break-words"
                  onChange={() => handleChange(each)}
                />
              </div>
            ))
          ) : (
            <div className="w-full h-48 flex items-center">
              <div className="w-full text-center">No data available.</div>
            </div>
          )}
        </div>
      )}
      {loading && (
        <div className="w-full flex justify-center my-20 ml-3">
          <NewSpinner />
        </div>
      )}
    </Fragment>
  );
};

export default CommonMultiSelectFilter;
