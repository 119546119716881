import React, { Fragment } from "react";
import styles from "./Ratings.module.css";
const Ratings = ({
  containerStyle = "w-44 h-8  my-1 px-1",
  onSetValue,
  label,
  checked,
  disabled,
}) => {
  const { outer_box_unassigned, outer_box_assigned } = styles;
  return (
    <Fragment>
      <div
        className={`${containerStyle} flex relative ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        }
         ${
           checked
             ? `bg-green-1200  ${outer_box_assigned}`
             : `${outer_box_unassigned}`
         }`}
        onClick={!disabled ? onSetValue : undefined}
      >
        <div className="w-full items-center">
          <p
            className={`${"font-roboto font-normal text-xs py-2  text-center"}`}
          >
            {label}
          </p>
        </div>
      </div>
    </Fragment>
  );
};
export default Ratings;
